import { Track } from 'livekit-client'
import { isLocal } from '@livekit/components-core'
import * as React from 'react'
import { useTracks } from '@livekit/components-react'
import { StereoAudioTrack } from './StereoAudioTrack'
import { StereoChannel } from './StereoChannel'

interface Props {
  channel: StereoChannel
  volume?: number
}

export const StereoRoomAudioRenderer = ({ channel, volume }: Props) => {
  const tracks = useTracks([Track.Source.Microphone, Track.Source.ScreenShareAudio], {
    updateOnlyOn: [],
  }).filter((ref) => !isLocal(ref.participant))
  return (
    <div style={{ display: 'none' }}>
      {tracks.map((trackRef) => (
        <StereoAudioTrack key={trackRef.publication.trackSid} channel={channel} volume={volume} {...trackRef} />
      ))}
    </div>
  )
}
