import resources from '../resources'
import { deleteSession, getSessionToken } from './user-session'

async function getResponse(resource: string, method = 'GET', data: any = undefined, useAuth = true) {
  const baseUrl = resources.apiBaseUrl || ''

  try {
    const response = await fetch(`${baseUrl}/${resource}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: useAuth ? `Bearer ${getSessionToken()}` : '',
      },
      body: data ? JSON.stringify(data) : undefined,
    })

    if (!response.ok && response.status === 401) {
      deleteSession()
    }

    return response
  } catch (e) {
    throw e
  }
}

export async function get<R>(resource: string) {
  const response = await getResponse(resource)

  if (response.ok && response.status !== 204) {
    return (await response.json()) as Promise<R>
  } else if (response.ok) {
    return Promise.resolve(undefined) as Promise<R>
  } else {
    return Promise.reject<R>(response.status)
  }
}

export async function post<R>(resource: string, data: any, useAuth = true) {
  const response = await getResponse(resource, 'POST', data, useAuth)

  if (response.ok && response.status !== 204) {
    return (await response.json()) as Promise<R>
  } else if (response.ok) {
    return Promise.resolve(undefined)
  } else {
    return Promise.reject<R>(response.status)
  }
}

export async function put<R>(resource: string, data: any, useAuth = true) {
  const response = await getResponse(resource, 'PUT', data, useAuth)

  if (response.ok && response.status !== 204) {
    return (await response.json()) as Promise<R>
  } else if (response.ok) {
    return Promise.resolve(undefined)
  } else {
    return Promise.reject<R>(response.status)
  }
}

export async function deleteResource(resource: string) {
  const response = await getResponse(resource, 'DELETE')

  if (response.ok) {
    return Promise.resolve()
  } else {
    return Promise.reject<void>(response.status)
  }
}
