import { User } from '../users/User'

let accessToken: string | null = localStorage.getItem('escape-modernization.accessToken')
let refreshToken: string | null = localStorage.getItem('escape-modernization.refreshToken')

export function updateAccessToken(token: string) {
  accessToken = token
  localStorage.setItem('escape-modernization.accessToken', token)
}

export function updateRefreshToken(token: string) {
  refreshToken = token
  localStorage.setItem('escape-modernization.refreshToken', token)
}

export function getSessionToken() {
  return accessToken
}

export function saveSession(user: User, accessToken: string, refreshToken: string | undefined) {
  updateAccessToken(accessToken)
  localStorage.setItem('escape-modernization.user', JSON.stringify(user))

  if (refreshToken) {
    updateRefreshToken(refreshToken)
  } else {
    localStorage.removeItem('escape-modernization.refreshToken')
  }

  window.dispatchEvent(new CustomEvent('user-logged-in', { detail: user }))
}

export function deleteSession() {
  localStorage.removeItem('escape-modernization.user')
  localStorage.removeItem('escape-modernization.accessToken')

  window.dispatchEvent(new CustomEvent('user-logged-out'))
}
