import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Slide,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'
import { TransitionProps } from '@mui/material/transitions'
import { AnyRoomContext } from '../rooms/AnyRoomContext'

interface Props {
  onClose: () => void
}

interface PttKeyData {
  code: number
  label: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const DevicesDialog = ({ onClose }: Props) => {
  const anyRoom = useContext(AnyRoomContext)

  const { t } = useTranslation()

  const [savedInputDevice, setSavedInputDevice] = useLocalStorage('escape-modernization.input-device', '')
  const [savedOutputDevice, setSavedOutputDevice] = useLocalStorage('escape-modernization.output-device', '')
  const [savedPttKey, setSavedPttKey] = useLocalStorage('escape-modernization.ptt-key', 'space')
  const [savedCustomPttKeyData, setSavedCustomPttKeyData] = useLocalStorage<PttKeyData | undefined>(
    'escape-modernization.custom-ptt-key-data',
    undefined,
  )

  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([])
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([])

  const [audioOutputSelectable] = useState<boolean>(() => {
    return Boolean((AudioContext.prototype as any).setSinkId)
  })

  const [customInputEnabled, setCustomInputEnabled] = useState(() => {
    return savedPttKey === 'custom'
  })
  const [currentCustomPttKeyData, setCurrentCustomPttKeyData] = useState<PttKeyData>()
  const [customKeyInputActive, setCustomKeyInputActive] = useState(false)

  const [devicesLoaded, setDevicesLoaded] = useState(false)

  const initialPublicRoomsLoadingRef = useRef(false)

  // Setting custom key if needed
  useEffect(() => {
    if (!savedCustomPttKeyData) return

    setCurrentCustomPttKeyData(savedCustomPttKeyData)
  }, [savedCustomPttKeyData])

  // Loading media devices
  useEffect(() => {
    if (initialPublicRoomsLoadingRef.current) return

    initialPublicRoomsLoadingRef.current = true

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(() => {
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
          setInputDevices(devices.filter((it) => it.kind === 'audioinput'))
          setOutputDevices(devices.filter((it) => it.kind === 'audiooutput'))
        })
      })
      .catch(console.error)
      .finally(() => {
        setDevicesLoaded(true)
        initialPublicRoomsLoadingRef.current = true
      })
  })

  // Listening to window events
  useEffect(() => {
    if (!customKeyInputActive) return

    const fXKeysPattern = /F[0-9]{1,2}/

    const keyDownListener = (event: KeyboardEvent) => {
      if (
        'Shift' === event.key ||
        'Control' === event.key ||
        'Alt' === event.key ||
        'AltGraph' === event.key ||
        ' ' === event.key
      ) {
        event.preventDefault()

        return
      }

      if (fXKeysPattern.test(event.key)) {
        event.preventDefault()
      } else if (event.ctrlKey) {
        event.preventDefault()
      }

      setCurrentCustomPttKeyData({
        code: event.keyCode,
        label: event.key,
      })
      setCustomKeyInputActive(false)
    }

    window.addEventListener('keydown', keyDownListener)

    return () => {
      window.removeEventListener('keydown', keyDownListener)
    }
  }, [customKeyInputActive])

  const handleClearDevices = () => {
    onClose()
    if (!anyRoom) {
      setSavedInputDevice('')
      setSavedOutputDevice('')
    }
    setSavedPttKey('')
    setSavedCustomPttKeyData(undefined)
  }

  const handleDevicesSelectionSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    if (!anyRoom) {
      const inputDevice = data.get('inputDevice')
      if (inputDevice && inputDevice !== '-') {
        // if (inputDevice && inputDevice !== '-'  && inputDevice !== 'default') {
        setSavedInputDevice(inputDevice! as any)
      } else {
        setSavedInputDevice('')
      }

      if (audioOutputSelectable) {
        const outputDevice = data.get('outputDevice')
        if (outputDevice && outputDevice !== '-') {
          // if (outputDevice && outputDevice !== '-' && outputDevice !== 'default') {
          setSavedOutputDevice(outputDevice! as any)
        } else {
          setSavedOutputDevice('')
        }
      }
    }

    const pttKey = data.get('pttKey')
    if ('custom' === pttKey) {
      if (currentCustomPttKeyData) {
        setSavedPttKey(pttKey! as any)
        setSavedCustomPttKeyData(currentCustomPttKeyData)
      } else {
        setSavedPttKey('')
      }
    } else {
      setSavedPttKey(pttKey! as any)
      setSavedCustomPttKeyData(undefined)
      setCurrentCustomPttKeyData(undefined)
    }

    onClose()
  }

  return (
    <>
      <Dialog open={devicesLoaded} onClose={onClose} fullWidth={true} TransitionComponent={Transition} maxWidth="sm">
        <Box component="form" onSubmit={handleDevicesSelectionSubmission}>
          <DialogTitle>{t('devices.title')}</DialogTitle>
          <DialogContent>
            {anyRoom && <Alert severity="info">{t('devices.activeRoomsMessage')}</Alert>}
            {!anyRoom && !audioOutputSelectable && (
              <Alert severity="warning">{t('devices.outputSelectionDisabledMessage')}</Alert>
            )}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <label style={{ width: 150, minWidth: 150, marginBottom: 8 }} htmlFor="output-device">
                {t('devices.output')}
              </label>
              <TextField
                id="output-device"
                name="outputDevice"
                label={''}
                sx={{ mt: 2 }}
                defaultValue={savedOutputDevice || outputDevices[0]?.deviceId || '-'}
                disabled={anyRoom || !audioOutputSelectable}
                size="small"
                variant="outlined"
                fullWidth
                autoFocus
                select
              >
                {(!audioOutputSelectable || outputDevices.length === 0) && (
                  <MenuItem value={'-'}>{t('devices.defaultDevice')}</MenuItem>
                )}
                {outputDevices.map((it) => (
                  <MenuItem key={it.deviceId} value={it.deviceId}>
                    {it.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <label style={{ width: 150, minWidth: 150, marginBottom: 8 }} htmlFor="input-device">
                {t('devices.input')}
              </label>
              <TextField
                id="input-device"
                name="inputDevice"
                label={''}
                sx={{ mt: 2 }}
                defaultValue={savedInputDevice || inputDevices[0]?.deviceId || '-'}
                inputProps={{ id: 'input-device' }}
                disabled={anyRoom}
                size="small"
                variant="outlined"
                fullWidth
                select
              >
                {inputDevices.length === 0 && <MenuItem value={'-'}>{t('devices.defaultDevice')}</MenuItem>}
                {inputDevices.map((it) => (
                  <MenuItem key={it.deviceId} value={it.deviceId}>
                    {it.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <label style={{ width: 150, minWidth: 150, marginBottom: 8 }} htmlFor="ptt-key">
                {t('devices.pttKey')}
              </label>
              <TextField
                id="ptt-key"
                name="pttKey"
                label={''}
                sx={{ mt: 2 }}
                defaultValue={savedPttKey || 'space'}
                inputProps={{ id: 'ptt-key' }}
                onChange={(event) => {
                  setCustomInputEnabled(event.target.value === 'custom')
                }}
                size="small"
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value={'space'}>{t('devices.pttKeySpace')}</MenuItem>
                <MenuItem value={'ctrl'}>{t('devices.pttKeyCtrl')}</MenuItem>
                <MenuItem value={'alt'}>{t('devices.pttKeyAlt')}</MenuItem>
                {/*<MenuItem value={'custom'}>{t('devices.pttKeyCustom')}</MenuItem>*/}
              </TextField>
            </Box>
            {customInputEnabled && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                {!customKeyInputActive && (
                  <>
                    {!currentCustomPttKeyData && (
                      <Alert severity="info" sx={{ flex: '1 1 auto', marginLeft: '150px', marginRight: 2 }}>
                        {t('devices.pttNoKeyLabel')}
                      </Alert>
                    )}
                    {currentCustomPttKeyData && (
                      <Alert severity="info" sx={{ flex: '1 1 auto', marginLeft: '150px', marginRight: 2 }}>
                        {t('devices.pttKeyLabel', { key: currentCustomPttKeyData.label })}
                      </Alert>
                    )}
                    <Button onClick={() => setCustomKeyInputActive(true)}>{t('common.set')}</Button>
                  </>
                )}
                {customKeyInputActive && (
                  <>
                    <Alert severity="info" sx={{ flex: '1 1 auto', marginLeft: '150px', marginRight: 2 }}>
                      {t('devices.pttInputKeyLabel')}
                    </Alert>
                    <Button onClick={() => setCustomKeyInputActive(false)}>{t('common.apply')}</Button>
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button onClick={handleClearDevices}>{t('common.clear')}</Button>
            <Button type="submit">{t('common.save')}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
