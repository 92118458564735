import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Paper, Popover } from '@mui/material'
import { validate } from './services'
import { SimulationPosition } from './SimulationPosition'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { SimulationExecutionStatus } from './SimulationExecutionStatus'
import { ReactComponent as RecordingIcon } from './recording-icon.svg'
import { ReactComponent as RecordIcon } from './record-icon.svg'
import './RecordingIcon.css'
import { SimulationRecordingStatus } from './SimulationRecordingStatus'

interface Props {
  simulationPosition: SimulationPosition
  simulationExecutionStatus: SimulationExecutionStatus
  simulationRecordingStatus: SimulationRecordingStatus
  isAdmin: boolean
  novalidate: boolean
  onClick?: () => void
}

const popoverColumnWidth = 172

export const SimulationPositionTile = memo(
  ({
    simulationPosition,
    simulationExecutionStatus,
    isAdmin,
    simulationRecordingStatus,
    novalidate,
    onClick,
  }: Props) => {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [anchorConnectedUsersEl, setAnchorConnectedUsersEl] = useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const handleConnectedUsersPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorConnectedUsersEl(event.currentTarget)
    }

    const handleConnectedUsersPopoverClose = () => {
      setAnchorConnectedUsersEl(null)
    }

    const open = Boolean(anchorEl)
    const connectedUsersOpen = Boolean(anchorConnectedUsersEl)
    const validationResult = novalidate ? undefined : validate(simulationPosition)

    return (
      <>
        <Paper
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: validationResult !== undefined ? '#da2222' : '#dadada',
              cursor: onClick ? 'pointer' : 'inherit',
            },
            {
              '&:hover': {
                backgroundColor: validationResult !== undefined ? '#da5555' : '#e5e5e5',
              },
            },
          ]}
          elevation={0}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={onClick}
          aria-owns={open ? 'mouse-over-popover' : undefined}
        >
          {simulationPosition.recordable && simulationRecordingStatus === 'recording' && (
            <RecordingIcon
              style={{
                width: '41px',
                height: '20px',
                position: 'relative',
                alignSelf: 'flex-start',
                top: '40px',
                left: '8px',
                marginTop: '-20px',
              }}
              className="blinking-simulation-record"
            />
          )}
          <Box>{simulationPosition.name}</Box>
          {simulationPosition.frequencies.length > 0 && <Box>{simulationPosition.frequencies[0].name}</Box>}
          {(isAdmin || simulationExecutionStatus === 'running') && (
            <Box
              sx={{
                display: 'inline-flex',
                maxWidth: '100%',
              }}
            >
              {simulationPosition.connectedUsers != null && simulationPosition.connectedUsers.length > 0 && (
                <Box
                  sx={{
                    color: '#34259c',
                    marginLeft: 1,
                    marginRight: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {simulationPosition.connectedUsers[0]}
                </Box>
              )}
              {simulationPosition.connectedUsers != null && simulationPosition.connectedUsers.length > 1 && (
                <>
                  <Box
                    onMouseEnter={handleConnectedUsersPopoverOpen}
                    onMouseLeave={handleConnectedUsersPopoverClose}
                    sx={{ height: 24, display: 'flex', alignItems: 'center' }}
                  >
                    <AddCircleOutlineIcon
                      sx={{ color: '#34259c', marginRight: '8px' }}
                      fontSize="small"
                    ></AddCircleOutlineIcon>
                  </Box>
                  <Popover
                    anchorEl={anchorConnectedUsersEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={connectedUsersOpen}
                    sx={{
                      pointerEvents: 'none',
                    }}
                    disableRestoreFocus
                  >
                    <Box
                      sx={{
                        padding: 1,
                        color: '#34259c',
                      }}
                    >
                      <>
                        {simulationPosition.connectedUsers.map((it) => (
                          <React.Fragment key={it}>
                            {it}
                            <br />
                          </React.Fragment>
                        ))}
                      </>
                    </Box>
                  </Popover>
                </>
              )}
              {(simulationPosition.connectedUsers === null || simulationPosition.connectedUsers.length === 0) && (
                <Box
                  sx={{
                    height: 24,
                  }}
                ></Box>
              )}
            </Box>
          )}
        </Paper>
        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{ top: 10000, left: 10000 }}
          open={open}
          sx={{
            pointerEvents: 'none',
          }}
          disableRestoreFocus
        >
          <Box
            sx={{
              padding: 2,
              backgroundColor: '#dadada',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  width: popoverColumnWidth,
                  fontWeight: 600,
                  color: validationResult?.nameValidationResult ? '#da2222' : 'inherit',
                }}
              >
                {t('simulations.form.positions.nameTitle')}
              </Box>
              <Box sx={{ width: popoverColumnWidth }}>{simulationPosition.name}</Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
              <Box
                sx={{
                  width: popoverColumnWidth,
                  fontWeight: 600,
                  color: validationResult?.additionalNamesValidationResult ? '#da2222' : 'inherit',
                }}
              >
                {t('simulations.form.positions.additionalNamesTitle')}
              </Box>
              {simulationPosition.additionalNames.length > 0 && (
                <Box sx={{ width: popoverColumnWidth }}>{simulationPosition.additionalNames.join(';')}</Box>
              )}
              {simulationPosition.additionalNames.length === 0 && (
                <Box sx={{ fontStyle: 'italic' }}>{t('common.notAvailable')}</Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
              <Box
                sx={{
                  width: popoverColumnWidth,
                  fontWeight: 600,
                  color:
                    validationResult?.sectorValidationResult || validationResult?.allowedRolesValidationResult
                      ? '#da2222'
                      : 'inherit',
                }}
              >
                {t('simulations.form.positions.sectorAndTypeTitle')}
              </Box>
              <Box sx={{ width: popoverColumnWidth }}>
                {simulationPosition.sector || t('simulations.form.positions.otherSector')}
                {simulationPosition.allowedRoles.length > 0 && `/${simulationPosition.allowedRoles.join(';')}`}
                {simulationPosition.allowedRoles.length === 0 && `/${t('common.notAvailable')}`}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
              <Box
                sx={{
                  width: popoverColumnWidth,
                  fontWeight: 600,
                  color: validationResult?.callableSectorsValidationResult ? '#da2222' : 'inherit',
                }}
              >
                {t('simulations.form.positions.callableSectorsTitle')}
              </Box>
              {simulationPosition.callableSectors.length > 0 && (
                <Box sx={{ width: popoverColumnWidth }}>{simulationPosition.callableSectors.join(';')}</Box>
              )}
              {simulationPosition.callableSectors.length === 0 && (
                <Box sx={{ fontStyle: 'italic' }}>{t('common.notAvailable')}</Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
              <Box
                sx={{
                  width: popoverColumnWidth,
                  fontWeight: 600,
                  color: validationResult?.callablePositionsValidationResult ? '#da2222' : 'inherit',
                }}
              >
                {t('simulations.form.positions.callablePositionsTitle')}
              </Box>
              {simulationPosition.callablePositions.length > 0 && (
                <Box sx={{ width: popoverColumnWidth }}>{simulationPosition.callablePositions.join(';')}</Box>
              )}
              {simulationPosition.callablePositions.length === 0 && (
                <Box sx={{ fontStyle: 'italic' }}>{t('common.notAvailable')}</Box>
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
              <Box sx={{ width: popoverColumnWidth, display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{ fontWeight: 600, color: validationResult?.frequenciesValidationResult ? '#da2222' : 'inherit' }}
                >
                  {t('simulations.form.positions.frequenciesColumnHeader')}
                </Box>
                {simulationPosition.frequencies.map((it, index) => (
                  <Box key={`${it.name}-${index}`} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ flex: '1 0 auto' }}>
                      {it.name}
                      {it.audioRecording && simulationRecordingStatus !== 'recording' && (
                        <RecordIcon
                          style={{
                            width: '20px',
                            height: '20px',
                            verticalAlign: 'middle',
                            marginLeft: '8px',
                          }}
                        />
                      )}
                      {it.audioRecording && simulationRecordingStatus === 'recording' && (
                        <RecordingIcon
                          style={{
                            width: '39px',
                            height: '18px',
                            marginLeft: '8px',
                            verticalAlign: 'middle',
                          }}
                          className="blinking-simulation-record"
                        />
                      )}
                    </span>
                    <span
                      style={{
                        fontWeight:
                          it.defaultConnectionType === 'RX' || it.defaultConnectionType === 'TX' ? 600 : 'inherit',
                      }}
                    >
                      RX
                    </span>
                    <span
                      style={{
                        marginLeft: '6px',
                        marginRight: '12px',
                        fontWeight: it.defaultConnectionType === 'TX' ? 600 : 'inherit',
                      }}
                    >
                      TX
                    </span>
                  </Box>
                ))}
                {simulationPosition.frequencies.length === 0 && (
                  <Box sx={{ fontStyle: 'italic' }}>{t('common.notAvailable')}</Box>
                )}
              </Box>

              <Box sx={{ width: popoverColumnWidth, display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{ fontWeight: 600, color: validationResult?.intercomsValidationResult ? '#da2222' : 'inherit' }}
                >
                  {t('simulations.form.positions.intercomsColumnHeader')}
                </Box>
                {simulationPosition.intercoms.map((it, index) => (
                  <Box key={`${it.name}-${index}`} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ flex: '1 0 auto' }}>
                      {it.name}
                      {it.audioRecording && simulationRecordingStatus !== 'recording' && (
                        <RecordIcon
                          style={{
                            width: '20px',
                            height: '20px',
                            verticalAlign: 'middle',
                            marginLeft: '8px',
                          }}
                        />
                      )}
                      {it.audioRecording && simulationRecordingStatus === 'recording' && (
                        <RecordingIcon
                          style={{
                            width: '39px',
                            height: '18px',
                            marginLeft: '8px',
                            verticalAlign: 'middle',
                          }}
                          className="blinking-simulation-record"
                        />
                      )}
                    </span>
                    <span
                      style={{
                        fontWeight:
                          it.defaultConnectionType === 'RX' || it.defaultConnectionType === 'TX' ? 600 : 'inherit',
                      }}
                    >
                      RX
                    </span>
                    <span
                      style={{
                        marginLeft: '6px',
                        marginRight: '12px',
                        fontWeight: it.defaultConnectionType === 'TX' ? 600 : 'inherit',
                      }}
                    >
                      TX
                    </span>
                  </Box>
                ))}
                {simulationPosition.intercoms.length === 0 && (
                  <Box sx={{ fontStyle: 'italic' }}>{t('common.notAvailable')}</Box>
                )}
              </Box>
            </Box>

            {simulationPosition.connection && (
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
                <Box
                  sx={{
                    width: popoverColumnWidth / 3,
                    fontWeight: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color:
                      validationResult?.connectionProtocolValidationResult ||
                      validationResult?.connectionHostValidationResult ||
                      validationResult?.connectionPortValidationResult
                        ? '#da2222'
                        : 'inherit',
                  }}
                >
                  {`${simulationPosition.connection.protocol || t('common.notAvailable')}${t(
                    'common.semicolonSeparator',
                  )}`}
                </Box>
                <Box sx={{ flex: '1 0 auto', fontWeight: 600 }}>
                  <Box
                    sx={{
                      fontWeight: 600,
                      color: validationResult?.connectionHostValidationResult ? '#da2222' : 'inherit',
                    }}
                  >
                    {t('simulations.form.positions.remoteConnectionHostTitle')}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      marginTop: 1,
                      color: validationResult?.connectionPortValidationResult ? '#da2222' : 'inherit',
                    }}
                  >
                    {t('simulations.form.positions.remoteConnectionPortTitle')}
                  </Box>
                  <Box sx={{ fontWeight: 600, marginTop: 1 }}>
                    {t('simulations.form.positions.remoteConnectionPasswordTitle')}
                  </Box>
                  <Box sx={{ fontWeight: 600, marginTop: 1 }}>
                    {t('simulations.form.positions.remoteConnectionRemoteControlTitle')}
                  </Box>
                </Box>
                <Box sx={{ width: popoverColumnWidth }}>
                  {simulationPosition.connection.host && <Box>{simulationPosition.connection.host}</Box>}
                  {!simulationPosition.connection.host && <Box>&nbsp;</Box>}
                  <Box sx={{ marginTop: 1 }}>{simulationPosition.connection.port}</Box>
                  {simulationPosition.connection.password && <Box sx={{ marginTop: 1 }}>{'**********'}</Box>}
                  {!simulationPosition.connection.password && <Box sx={{ marginTop: 1 }}>&nbsp;</Box>}
                  <Box sx={{ marginTop: 1 }}>
                    {simulationPosition.connection.remoteControl
                      ? t('simulations.form.positions.remoteConnectionRemoteControlTrue')
                      : t('simulations.form.positions.remoteConnectionRemoteControlFalse')}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Popover>
      </>
    )
  },
)
