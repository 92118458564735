import { get } from './rest'
import { WsAccessToken } from './WsAccessToken'

export async function getSimulationsWsAccessToken(target: string): Promise<WsAccessToken> {
  return get(`ws-auth/simulations/${target}`)
}

export async function getLivekitAccessToken(simulationKey: string, roomName: string): Promise<WsAccessToken> {
  return get(`ws-auth/livekit/${simulationKey}/${roomName}`)
}

export async function getGuacdWsAccessToken(simulationKey: string, roomName: string): Promise<WsAccessToken> {
  return get(`ws-auth/guacd/${simulationKey}/${roomName}`)
}
