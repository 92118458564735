// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    delete: 'Delete',
    save: 'Save',
    add: 'Add',
    set: 'Set',
    apply: 'Apply',
    create: 'Create',
    edit: 'Edit',
    clear: 'Clear',
    next: 'Next',
    refresh: 'Refresh',
    reload: 'Reload',
    previous: 'Previous',
    back: 'Back',
    update: 'Update',
    import: 'Import',
    export: 'Export',
    join: 'Join',
    notAvailable: 'N/A',
    semicolonSeparator: ':',
    badRequestError: 'Validation error, please check your input',
    unexpectedError: 'An unexpected error occurred, please contact support',
    livekitError: 'Error while connecting to Livekit, please retry later',
    wsError: 'Connectivity with the server has been lost, please reload the web page',
  },
  app: {
    title: 'ESCAPE Modernization',
    logoutButton: 'Logout',
    homeButton: 'Home',
    disconnectedTooltip: 'Disconnected from server',
    menu: {
      logout: 'Logout',
    },
  },
  loading: {
    loadingMessage: 'Loading...',
  },
  sideBar: {
    simulations: 'Simulations',
    help: 'Help',
    devices: 'Devices',
  },
  login: {
    login: 'Login',
    password: 'Password',
    rememberMe: 'Remember me',
    submit: 'Login',
    credentialsError: 'Invalid login/password',
    loginRequired: 'Login is mandatory',
    passwordRequired: 'Password is mandatory',
    genericError: 'Error while contacting server, please contact support',
  },
  call: {
    directCall: 'Direct call',
    callFromPositionLabel: 'Incoming call from {{position}}',
    callingPositionLabel: 'Calling {{position}}',
    connectedToPositionLabel: 'Communicating with {{position}}',
    callTooltip: 'Call',
    acceptTooltip: 'Pick up',
    rejectTooltip: 'Hang up',
    micDisabledTooltip: 'Mic disabled',
    connectingLabel: 'Connecting',
  },
  simulations: {
    title: 'Simulations list',
    newSimulationButton: 'New simulation',
    listingError: 'Error while listing simulations, please contact support',
    loadingError: 'Error while loading simulation, please contact support',
    briefingRoomJoiningError: 'Error while connecting to briefing audio channel, please contact support',
    noSimulationsMessage: 'No simulations configured for your profile',
    noSimulationsAsAdminMessage: 'No simulations setup',
    deleteConfirmationMessage: 'Delete this simulation',
    recordConfirmationMessage: 'Record this simulation',
    stopRecordingConfirmationMessage: 'Stop recording this simulation',
    recordingTooltip: 'Ongoing audio recording',
    recordButton: 'Record',
    remotelyDeletedMessage: 'The simulation has been deleted',
    remotelyUpdatedByAdminMessage: 'The admin user {{user}} has just modified this simulation',
    remotelyUpdatedBySelfMessage: 'Your user has just modified this simulation in a different window',
    unmuteInBriefingDisabledMessage: 'Speaking in briefing is not allowed in a running simulation',
    joinBriefing: 'Join briefing',
    leaveBriefing: 'Leave briefing',
    briefingRoomLabel: 'Briefing',
    statuses: {
      stopped: 'Stopped',
      briefing: 'Briefing',
      running: 'Running',
    },
    form: {
      duplicateErrorMessage: 'Simulation with same title already exists',
      lockedErrorMessage: 'Running simulation cannot be updated',
      validationErrorMessage: 'Simulation contains invalid data, please fix it and retry',
      importErrorMessage: 'Import file format is invalid, please fix it and retry',
      editTitle: 'Simulation details',
      createTitle: 'Declare new simulation',
      statusTitle: 'Status:',
      titleTitle: 'Title:',
      titlePlaceholder: '[Simulation name]',
      titleEmptyErrorMessage: 'Simulation name is mandatory',
      titleTooShortErrorMessage: 'Simulation name is too short',
      titleAlreadyExistsErrorMessage: 'Simulation name already exists',
      descriptionTitle: 'Description:',
      descriptionPlaceholder: '[Simulation description]',
      visibleToGroupsTitle: 'Visible to:',
      positionsTitle: 'Positions:',
      otherSectorsTitle: 'Others',
      noPositionsAsAdminMessage: 'No configured position',
      noPositionsAsNonAdminMessage: 'No positions configured for your profile',
      noPositionsAfterSearchMessage: 'No position matching your search',
      positions: {
        nameTitle: 'Position name:',
        additionalNamesTitle: 'Other names:',
        sectorAndTypeTitle: 'Sector/Type:',
        callableSectorsTitle: 'Calls to sectors:',
        callablePositionsTitle: 'Calls to positions:',
        frequenciesColumnHeader: 'Frequencies',
        intercomsColumnHeader: 'Intercoms',
        otherSector: 'Other',
        remoteConnectionVncTitle: 'VNC:',
        remoteConnectionHostTitle: 'Host:',
        remoteConnectionPortTitle: 'Port:',
        remoteConnectionPasswordTitle: 'Password:',
        remoteConnectionRemoteControlTitle: 'Remote ctrl:',
        remoteConnectionRemoteControlTrue: 'true',
        remoteConnectionRemoteControlFalse: 'false',
      },
    },
  },
  remoteConnection: {
    connectionError: 'Error while connecting to server, please contact support',
    loadingError: 'Error while loading position, please contact support',
    disconnectionError: 'Disconnected from server, please retry later',
    positionUnavailableMessage: 'This position is no longer available',
    positionStatusMessage: 'Simulation status updated, audio no longer available',
    positionClosingMessage: 'Simulation status updated, this position will close',
    noAudioMessage: 'Audio not available yet for this position',
    noFrequenciesMessage: 'No frequencies',
    alreadyConnectedMessage:
      'You are already connected to this running simulation, please close existing connection(s) and retry',
    connecting: 'Connecting to position',
    supervisionRoomLabel: 'Supervision Intercom',
    disconnect: 'Leave position',
    connectionLabel: '{{name}}',
    pinOverlayMessage: 'Pin control pane',
    unpinOverlayMessage: 'Unpin control pane',
    unknownDirectCallDestinationLabel: 'Other',
  },
  room: {
    reconnect: 'Reconnect',
    audioBlockedMessage: 'Audio blocked',
    roomUnavailableMessage: 'This room is no longer available',
    enableAudio: 'Enable',
    connectedLabel: 'Connected',
    notConnectedLabel: 'Not connected',
    microphoneActiveLabel: 'Mic active',
    microphoneInactiveLabel: 'Mic inactive',
    transmissionLabel: 'Tx',
    receptionLabel: 'Rx',
    muteAllLabel: 'Mute all',
    unmuteLabel: 'Unmute',
    mutedInRoomMessage: 'You have been muted in {{room}} by {{muter}}',
    speakingLabel: 'Speaking:',
    noActiveSpeakersMessage: 'No active speakers',
  },
  devices: {
    title: 'Devices',
    activeRoomsMessage: 'Not possible to modify audio devices when already connected to an audio channel',
    defaultDevice: 'Default system device',
    outputSelectionDisabledMessage:
      'This browser do not yet support speakers change while having stereo sound, please use system settings temporarily',
    input: 'Microphone',
    output: 'Speaker',
    selectOutput: 'Select speaker',
    pttKey: 'PTT key',
    pttKeySpace: 'Space',
    pttKeyCtrl: 'CTRL',
    pttKeyAlt: 'ALT',
    pttKeyCustom: 'Custom',
    pttNoKeyLabel: 'No key configured',
    pttKeyLabel: 'Configured key: {{key}}',
    pttInputKeyLabel: 'Press a single key',
  },
  errors: {
    error403: 'You do not have permission to access this page, please contact support',
    error404: 'The page you requested does not exist',
    error500: 'The application encountered an error, please retry later',
  },
}
