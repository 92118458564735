import Keycloak from 'keycloak-js'
import resources from '../resources'
import { deleteSession, saveSession, updateAccessToken } from '../utils/user-session'
import { Role } from '../users/Role'
import { User } from '../users/User'

const keycloak = new Keycloak({
  url: resources.keycloakBaseUrl,
  realm: resources.keycloakRealm || '',
  clientId: resources.keycloakClientId || '',
})
let globallyAuthenticated = false

keycloak.onTokenExpired = () => {
  keycloak
    .updateToken(5)
    .then((result) => {
      if (result) {
        globallyAuthenticated = true
        updateAccessToken(keycloak.token || '')
      } else {
        globallyAuthenticated = false
        deleteSession()
      }
    })
    .catch((e) => {
      console.error('Error while refreshing token', e)

      globallyAuthenticated = false
      deleteSession()
    })
}
keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframe: false
  })
  .then((authenticated) => {
    if (authenticated) {
      globallyAuthenticated = true
      const roles: Role[] = []
      keycloak.loadUserProfile().then((p) => {
        if (keycloak.hasRealmRole('supervisor')) {
          roles.push('supervisor')
        }
        if (keycloak.hasRealmRole('supervisionleader')) {
          roles.push('supervisionleader')
        }
        if (keycloak.hasRealmRole('pilot')) {
          roles.push('pilot')
        }
        if (keycloak.hasRealmRole('atco')) {
          roles.push('atco')
        }
        if (keycloak.hasRealmRole('admin')) {
          roles.push('admin')
        }
        const user: User = {
          login: p.username || '???',
          roles: roles,
        }

        saveSession(user, keycloak.token || '', keycloak.refreshToken)
      })
    }
  })
  .catch((e) => {
    console.error('Error while authenticating', e)

    globallyAuthenticated = false
    deleteSession()
  })

export function isGloballyAuthenticated() {
  return globallyAuthenticated
}

export function logout() {
  keycloak.logout().then().catch(console.error)
}
