import { deleteResource, get, post, put } from '../utils/rest'
import { SimulationForListing } from './SimulationForListing'
import { Simulation } from './Simulation'
import { Position } from './Position'
import { RemoteConnection } from './RemoteConnection'
import { SimulationCreationResponse } from './SimulationCreationResponse'
import { AudioRoom } from './AudioRoom'
import { AtcRole, atcRoles } from '../users/AtcRole'
import { SimulationUpdateResponse } from './SimulationUpdateResponse'
import { SimulationExecutionStatus } from './SimulationExecutionStatus'
import { RemoteConnectionProtocol, remoteConnectionsProtocols } from '../remote-connections/RemoteConnectionProtocol'
import { getLivekitAccessToken } from '../utils/ws-access-token'
import { loadRoomAndConnect } from '../rooms/services'
import { Room } from '../rooms/Room'
import { SimulationRecordingStatus } from './SimulationRecordingStatus'
import { SimulationPosition } from './SimulationPosition'
import { Sector } from './Sector'
import { PositionAudioRoom } from './PositionAudioRoom'

interface RawSimulationForListing {
  key: string
  title: string
  description?: string
  status: RawSimulationStatus
}

interface RawSimulation {
  title: string
  description?: string
  allowed_groups: string[]
  status: RawSimulationStatus
  sectors: RawSimulationSector[]
  other_positions: RawSimulationPosition[]
  adhoc_intercoms: RawSimulationAudioRoom[]
}

interface RawSimulationStatus {
  execution_status: SimulationExecutionStatus
  recording_status: SimulationRecordingStatus
}

interface RawSimulationSector {
  name: string
  audio_rooms: RawSimulationSectorAudioRooms
  positions: RawSimulationPosition[]
}

interface RawSimulationSectorAudioRooms {
  main_frequency: RawSimulationAudioRoom
  intercom: RawSimulationAudioRoom | null
  other_frequencies: RawSimulationAudioRoom[]
}

interface RawSimulationPosition {
  name: string
  additional_names: string[]
  callable_sectors: string[]
  extra_callable_positions: string[]
  adhoc_intercoms: string[]
  allowed_roles: AtcRole[]
  connected_users?: string[]
  connection?: RawSimulationRemoteConnection
}

interface RawSimulationRemoteConnection {
  protocol: RemoteConnectionProtocol
  host: string
  port: number
  password: string | null
  remote_control: boolean
}

interface RawSimulationAudioRoom {
  name: string
  audio_recording: boolean
}

interface SimulationImportResult {
  adhocIntercoms: AudioRoom[]
  sectors: Sector[]
  otherPositions: Position[]
}

export function toSimulationForListing(rawSimulation: RawSimulationForListing): SimulationForListing {
  return {
    key: rawSimulation.key,
    title: rawSimulation.title,
    description: rawSimulation.description,
    status: {
      executionStatus: rawSimulation.status.execution_status,
      recordingStatus: rawSimulation.status.recording_status,
    },
  }
}

export async function getSimulations(): Promise<SimulationForListing[]> {
  return new Promise<SimulationForListing[]>((resolve, reject) => {
    get<RawSimulationForListing[]>('simulations')
      .then((rawSimulations) => {
        resolve(rawSimulations.map(toSimulationForListing))
      })
      .catch((e) => reject(e))
  })
}

function sortPositions(simulationPositions: Position[]) {
  simulationPositions.sort((position1, position2) => {
    return position1.name.localeCompare(position2.name)
  })

  simulationPositions.forEach((position) => {
    position.allowedRoles.sort()
    position.callableSectors.sort()
    position.callablePositions.sort()
    position.additionalNames.sort()
    position.adhocIntercoms.sort()
    position.connectedUsers?.sort()
  })
}

function sortAudioRooms(audioRooms: AudioRoom[]) {
  audioRooms.sort((audioRoom1, audioRoom2) => {
    return audioRoom1.name.localeCompare(audioRoom2.name)
  })
}

function sortSectors(sectors: Sector[]) {
  sectors.sort((sector1, sector2) => {
    return sector1.name.localeCompare(sector2.name)
  })

  sectors.forEach((sector) => {
    sortPositions(sector.positions)
    if (sector.audioRooms.otherFrequencies) {
      sortAudioRooms(sector.audioRooms.otherFrequencies)
    }
  })
}

function toPosition(rawSimulationPosition: RawSimulationPosition): Position {
  return {
    name: rawSimulationPosition.name,
    additionalNames: rawSimulationPosition.additional_names,
    allowedRoles: rawSimulationPosition.allowed_roles,
    callableSectors: rawSimulationPosition.callable_sectors,
    callablePositions: rawSimulationPosition.extra_callable_positions,
    adhocIntercoms: rawSimulationPosition.adhoc_intercoms,
    connectedUsers: rawSimulationPosition.connected_users || [],
    connection: rawSimulationPosition.connection
      ? {
          protocol: rawSimulationPosition.connection.protocol,
          host: rawSimulationPosition.connection.host,
          port: rawSimulationPosition.connection.port,
          password: rawSimulationPosition.connection.password,
          remoteControl: rawSimulationPosition.connection.remote_control,
        }
      : undefined,
  }
}

function toAudioRoom(rawAudioRoom: RawSimulationAudioRoom): AudioRoom {
  return {
    name: rawAudioRoom.name,
    audioRecording: rawAudioRoom.audio_recording,
  }
}

export function toSimulation(rawSimulation: RawSimulation): Simulation {
  const simulation = {
    title: rawSimulation.title,
    description: rawSimulation.description,
    status: {
      executionStatus: rawSimulation.status.execution_status,
      recordingStatus: rawSimulation.status.recording_status,
    },
    sectors: rawSimulation.sectors.map((sector) => ({
      name: sector.name,
      audioRooms: {
        mainFrequency: toAudioRoom(sector.audio_rooms.main_frequency),
        otherFrequencies: sector.audio_rooms.other_frequencies.map((it) => toAudioRoom(it)),
        intercom: sector.audio_rooms.intercom ? toAudioRoom(sector.audio_rooms.intercom) : undefined,
      },
      positions: sector.positions.map((it) => toPosition(it)),
    })),
    adhocIntercoms: rawSimulation.adhoc_intercoms.map((it) => toAudioRoom(it)),
    otherPositions: rawSimulation.other_positions.map((it) => toPosition(it)),
    allowedGroups: rawSimulation.allowed_groups,
  }

  simulation.allowedGroups.sort()
  sortAudioRooms(simulation.adhocIntercoms)
  sortSectors(simulation.sectors)
  sortPositions(simulation.otherPositions)

  return simulation
}

export async function getSimulation(key: string): Promise<Simulation> {
  return new Promise<Simulation>((resolve, reject) => {
    get<RawSimulation>(`simulations/${key}`)
      .then((rawSimulation) => {
        resolve(toSimulation(rawSimulation))
      })
      .catch((e) => reject(e))
  })
}

function toRawSimulation(simulation: Simulation) {
  const rawSimulation: Omit<RawSimulation, 'status'> = {
    title: simulation.title.trim(),
    description: simulation.description?.trim(),
    other_positions: simulation.otherPositions.map((it) => toRawSimulationPosition(it)),
    sectors: simulation.sectors.map((it) => toRawSector(it)),
    adhoc_intercoms: simulation.adhocIntercoms.map((it) => toRawAudioRoom(it)),
    allowed_groups: simulation.allowedGroups,
  }

  return rawSimulation
}

export async function createSimulation(simulation: Simulation): Promise<SimulationCreationResponse | undefined> {
  const rawSimulation = toRawSimulation(simulation)

  return post('simulations', rawSimulation)
}

export async function updateSimulation(
  key: string,
  simulation: Simulation,
): Promise<SimulationUpdateResponse | undefined> {
  const rawSimulation = toRawSimulation(simulation)

  return put(`simulations/${key}`, rawSimulation)
}

export async function updateSimulationStatus(key: string, status: SimulationExecutionStatus): Promise<void> {
  return post(`simulations/${key}/${status}`, '')
}

export async function deleteSimulation(key: string): Promise<void> {
  return deleteResource(`simulations/${key}`)
}

export async function startSimulationRecording(key: string): Promise<void> {
  return post(`simulations/${key}/recording`, '')
}

export async function stopSimulationRecording(key: string): Promise<void> {
  return post(`simulations/${key}/notRecording`, '')
}

function extractArrayOfStrings(maybeRawArray: any | undefined | null): string[] {
  return maybeRawArray && maybeRawArray.constructor === Array
    ? maybeRawArray.filter((it: any) => it && it.constructor === String).map((it: string) => it.trim())
    : []
}

function extractArrayOfRooms(maybeRawArray: any | undefined | null): AudioRoom[] {
  return maybeRawArray && maybeRawArray.constructor === Array
    ? maybeRawArray.filter((it) => it.constructor === Object).map((it: any) => toAudioRoomFromImport(it))
    : []
}

function toRawAudioRoom(audioRoom: AudioRoom) {
  return {
    name: audioRoom.name,
    audio_recording: audioRoom.audioRecording,
  }
}

function toRawSector(sector: Sector) {
  return {
    name: sector.name,
    audio_rooms: {
      main_frequency: toRawAudioRoom(sector.audioRooms.mainFrequency),
      intercom: sector.audioRooms.intercom ? toRawAudioRoom(sector.audioRooms.intercom) : null,
      other_frequencies: sector.audioRooms.otherFrequencies.map((it) => toRawAudioRoom(it)),
    },
    positions: sector.positions.map((it) => toRawSimulationPosition(it)),
  }
}

function toRawSimulationPosition(position: Position) {
  return {
    name: position.name,
    additional_names: position.additionalNames,
    allowed_roles: position.allowedRoles,
    callable_sectors: position.callableSectors,
    extra_callable_positions: position.callablePositions,
    adhoc_intercoms: position.adhocIntercoms,
    connection: position.connection
      ? {
          protocol: position.connection.protocol,
          host: position.connection.host,
          port: position.connection.port,
          password: position.connection.password,
          remote_control: position.connection.remoteControl,
        }
      : undefined,
  }
}

function toAudioRoomFromImport(rawAudioRoom: any): AudioRoom {
  const roomName = rawAudioRoom.name && rawAudioRoom.name.toString ? rawAudioRoom.name.toString().trim() : ''
  const audioRecording = Object.hasOwn(rawAudioRoom, 'audio_recording') ? Boolean(rawAudioRoom.audio_recording) : false

  return {
    name: roomName,
    audioRecording: audioRecording,
  }
}

function toSectorFromImport(rawSector: any): Sector {
  const name = rawSector.name && rawSector.name.toString ? rawSector.name.toString().trim() : ''
  const positions =
    rawSector.positions && rawSector.positions.constructor === Array
      ? rawSector.positions.map((it: any) => toSimulationPositionFromImport(it))
      : []

  return {
    name: name,
    audioRooms: {
      mainFrequency:
        rawSector.audio_rooms &&
        rawSector.audio_rooms.constructor === Object &&
        rawSector.audio_rooms.main_frequency &&
        rawSector.audio_rooms.main_frequency.constructor === Object
          ? toAudioRoomFromImport(rawSector.audio_rooms.main_frequency)
          : {
              name: '',
              audioRecording: false,
            },
      intercom:
        rawSector.audio_rooms.intercom && rawSector.audio_rooms.intercom.constructor === Object
          ? toAudioRoomFromImport(rawSector.audio_rooms.intercom)
          : undefined,
      otherFrequencies: rawSector.audio_rooms ? extractArrayOfRooms(rawSector.audio_rooms.other_frequencies) : [],
    },
    positions: positions,
  }
}

function toSimulationPositionFromImport(rawPosition: any): Position {
  const name = rawPosition.name && rawPosition.name.toString ? rawPosition.name.toString().trim() : ''
  const additionalNames = extractArrayOfStrings(rawPosition.additional_names)
  const allowedRoles = extractArrayOfStrings(rawPosition.allowed_roles)
  const callableSectors = extractArrayOfStrings(rawPosition.callable_sectors)
  const adhocIntercoms = extractArrayOfStrings(rawPosition.adhoc_intercoms)
  const callablePositions = extractArrayOfStrings(rawPosition.extra_callable_positions)
  const connectionProtocol: string =
    rawPosition.connection && rawPosition.connection.protocol && rawPosition.connection.protocol.toString
      ? rawPosition.connection.protocol.toString().trim()
      : 'VNC'
  const connectionHost: string =
    rawPosition.connection && rawPosition.connection.host && rawPosition.connection.host.toString
      ? rawPosition.connection.host.toString().trim()
      : ''
  const connectionPort: number =
    rawPosition.connection && rawPosition.connection.port && rawPosition.connection.port.constructor === Number
      ? rawPosition.connection.port
      : 5900
  const connectionPassword: string =
    rawPosition.connection && rawPosition.connection.password && rawPosition.connection.password.toString
      ? rawPosition.connection.password.toString().trim()
      : null
  const connectionRemoteControl: boolean = rawPosition.connection
    ? Object.hasOwn(rawPosition.connection, 'remote_control')
      ? Boolean(rawPosition.connection.remote_control)
      : true
    : true

  return {
    name: name,
    additionalNames: additionalNames,
    allowedRoles: allowedRoles,
    connectedUsers: [],
    callableSectors: callableSectors,
    callablePositions: callablePositions,
    adhocIntercoms: adhocIntercoms,
    connection: {
      protocol: connectionProtocol,
      host: connectionHost,
      port: connectionPort,
      password: connectionPassword,
      remoteControl: connectionRemoteControl,
    },
  }
}

export function importSimulationPositions(rawData: any): SimulationImportResult {
  const adhocIntercoms = extractArrayOfRooms(rawData.adhoc_intercoms)
  const sectors =
    rawData.sectors && rawData.sectors.constructor === Array
      ? rawData.sectors.map((it: any) => toSectorFromImport(it))
      : []
  const otherPositions =
    rawData.other_positions && rawData.other_positions.constructor === Array
      ? rawData.other_positions.map((it: any) => toSimulationPositionFromImport(it))
      : []

  sortSectors(sectors)
  sortPositions(otherPositions)
  sortAudioRooms(adhocIntercoms)

  return {
    adhocIntercoms: adhocIntercoms,
    sectors: sectors,
    otherPositions: otherPositions,
  }
}

export function exportSimulationPositions(simulation: Simulation): any {
  return {
    sectors: simulation.sectors.map((it) => toRawSector(it)),
    adhoc_intercoms: simulation.adhocIntercoms.map((it) => toRawAudioRoom(it)),
    other_positions: simulation.otherPositions.map((it) => toRawSimulationPosition(it)),
  }
}

type FieldValidation = 'empty' | 'malformed' | 'short' | 'long'
type CollectionValidation = 'empty' | 'short' | 'long' | 'duplicates' | 'malformed-element'

interface SimulationPositionValidationResult {
  nameValidationResult?: FieldValidation
  sectorValidationResult?: FieldValidation
  additionalNamesValidationResult?: CollectionValidation
  allowedRolesValidationResult?: CollectionValidation
  frequenciesValidationResult?: CollectionValidation
  intercomsValidationResult?: CollectionValidation
  callableSectorsValidationResult?: CollectionValidation
  callablePositionsValidationResult?: CollectionValidation
  connectionProtocolValidationResult?: FieldValidation
  connectionHostValidationResult?: FieldValidation
  connectionPortValidationResult?: FieldValidation
}

const spacesFreePattern = /^\S+$/

export function validate(simulationPosition: SimulationPosition): SimulationPositionValidationResult | undefined {
  // First simple draft of validation, to be refined later

  let anyError = false
  let nameValidationResult: FieldValidation | undefined = undefined
  if (simulationPosition.name.trim().length === 0) {
    nameValidationResult = 'empty'
    anyError = true
  } else if (simulationPosition.name.trim().length < 5) {
    nameValidationResult = 'short'
    anyError = true
  } else if (simulationPosition.name.trim().length > 30) {
    nameValidationResult = 'long'
    anyError = true
  }

  let sectorValidationResult: FieldValidation | undefined = undefined
  if (simulationPosition.sector !== null) {
    if (simulationPosition.sector.trim().length === 0) {
      sectorValidationResult = 'empty'
      anyError = true
    } else if (simulationPosition.sector.trim().length < 1) {
      sectorValidationResult = 'short'
      anyError = true
    } else if (simulationPosition.sector.trim().length > 5) {
      sectorValidationResult = 'long'
      anyError = true
    } else if (!spacesFreePattern.test(simulationPosition.sector.trim())) {
      sectorValidationResult = 'malformed'
      anyError = true
    }
  }

  let additionalNamesValidationResult: CollectionValidation | undefined = undefined
  if (
    simulationPosition.additionalNames.find((it) => {
      return it.trim().length === 0 || it.trim().length < 5 || it.trim().length > 30
    })
  ) {
    additionalNamesValidationResult = 'malformed-element'
    anyError = true
  } else if (new Set(simulationPosition.additionalNames).size !== simulationPosition.additionalNames.length) {
    additionalNamesValidationResult = 'duplicates'
    anyError = true
  }

  let allowedRolesValidationResult: CollectionValidation | undefined = undefined
  if (simulationPosition.allowedRoles.findIndex((it) => atcRoles.indexOf(it) === -1) !== -1) {
    allowedRolesValidationResult = 'malformed-element'
    anyError = true
  } else if (new Set(simulationPosition.allowedRoles).size !== simulationPosition.allowedRoles.length) {
    allowedRolesValidationResult = 'duplicates'
    anyError = true
  }

  let callableSectorsValidationResult: CollectionValidation | undefined = undefined
  if (
    simulationPosition.callableSectors.find((it) => {
      return (
        it.trim().length === 0 || it.trim().length < 1 || it.trim().length > 5 || !spacesFreePattern.test(it.trim())
      )
    })
  ) {
    callableSectorsValidationResult = 'malformed-element'
    anyError = true
  } else if (new Set(simulationPosition.callableSectors).size !== simulationPosition.callableSectors.length) {
    callableSectorsValidationResult = 'duplicates'
    anyError = true
  }

  let callablePositionsValidationResult: CollectionValidation | undefined = undefined
  if (
    simulationPosition.callablePositions.find((it) => {
      return it.trim().length === 0 || it.trim().length < 5 || it.trim().length > 30
    })
  ) {
    callablePositionsValidationResult = 'malformed-element'
    anyError = true
  } else if (new Set(simulationPosition.callablePositions).size !== simulationPosition.callablePositions.length) {
    callablePositionsValidationResult = 'duplicates'
    anyError = true
  }

  let frequenciesValidationResult: CollectionValidation | undefined = undefined
  if (
    simulationPosition.frequencies.find((it) => {
      return it.name.trim().length < 2 || it.name.trim().length > 20 || !spacesFreePattern.test(it.name.trim())
    })
  ) {
    frequenciesValidationResult = 'malformed-element'
    anyError = true
  } else if (
    new Set(simulationPosition.frequencies.map((it) => it.name)).size !== simulationPosition.frequencies.length
  ) {
    frequenciesValidationResult = 'duplicates'
    anyError = true
  }

  let intercomsValidationResult: CollectionValidation | undefined = undefined
  if (
    simulationPosition.intercoms.find((it) => {
      return it.name.trim().length < 2 || it.name.trim().length > 20 || !spacesFreePattern.test(it.name.trim())
    })
  ) {
    intercomsValidationResult = 'malformed-element'
    anyError = true
  } else if (new Set(simulationPosition.intercoms.map((it) => it.name)).size !== simulationPosition.intercoms.length) {
    intercomsValidationResult = 'duplicates'
    anyError = true
  }

  let connectionHostValidationResult: FieldValidation | undefined = undefined
  if (!simulationPosition.connection) {
    connectionHostValidationResult = 'empty'
    anyError = true
  } else if (simulationPosition.connection.host.trim().length === 0) {
    connectionHostValidationResult = 'empty'
    anyError = true
  } else if (simulationPosition.connection.host.trim().length > 20) {
    connectionHostValidationResult = 'long'
    anyError = true
  } else if (!spacesFreePattern.test(simulationPosition.connection.host.trim())) {
    connectionHostValidationResult = 'malformed'
    anyError = true
  }

  let connectionPortValidationResult: FieldValidation | undefined = undefined
  if (
    simulationPosition.connection &&
    (simulationPosition.connection.port < 0 || simulationPosition.connection.port > 65535)
  ) {
    connectionPortValidationResult = 'malformed'
    anyError = true
  }

  let connectionProtocolValidationResult: FieldValidation | undefined = undefined
  if (
    simulationPosition.connection &&
    remoteConnectionsProtocols.indexOf(simulationPosition.connection.protocol || '-') === -1
  ) {
    connectionProtocolValidationResult = 'malformed'
    anyError = true
  }

  if (!anyError) return undefined

  return {
    nameValidationResult,
    sectorValidationResult,
    additionalNamesValidationResult,
    allowedRolesValidationResult,
    callableSectorsValidationResult,
    callablePositionsValidationResult,
    intercomsValidationResult,
    frequenciesValidationResult,
    connectionProtocolValidationResult,
    connectionHostValidationResult,
    connectionPortValidationResult,
  }
}

export function areSimulationsIdentical(
  simulation1: Simulation,
  simulation2: Simulation,
  checkConnectedUsers = false,
): boolean {
  // For efficiency simulations nested arrays must be sorted, and they are when we map simulations
  if (simulation1.title.trim() !== simulation2.title.trim()) return false
  if (simulation1.description?.trim() !== simulation2.description?.trim()) return false
  if (simulation1.status.executionStatus !== simulation2.status.executionStatus) return false
  if (simulation1.status.recordingStatus !== simulation2.status.recordingStatus) return false
  if (!areSortedStringArraysIdentical(simulation1.allowedGroups, simulation2.allowedGroups)) return false
  if (!areSortedPositionsIdentical(simulation1.otherPositions, simulation2.otherPositions, checkConnectedUsers))
    return false
  if (!areSortedSectorsIdentical(simulation1.sectors, simulation2.sectors, checkConnectedUsers)) return false

  return true
}

function areSortedSectorsIdentical(array1: Sector[], array2: Sector[], checkConnectedUsers = false): boolean {
  if (array1.length !== array2.length) return false

  for (let index = 0; index < array1.length; index++) {
    if (!areSectorsIdentical(array1[index], array2[index], checkConnectedUsers)) return false
  }

  return true
}

function areSectorsIdentical(sector1: Sector, sector2: Sector, checkConnectedUsers = false): boolean {
  if (sector1.name !== sector2.name) return false
  if (!areAudioRoomsIdentical(sector1.audioRooms.mainFrequency, sector2.audioRooms.mainFrequency)) return false
  if (!areSortedAudioRoomArraysIdentical(sector1.audioRooms.otherFrequencies, sector2.audioRooms.otherFrequencies))
    return false
  if (
    (sector1.audioRooms.intercom && !sector2.audioRooms.intercom) ||
    (sector2.audioRooms.intercom && !sector1.audioRooms.intercom) ||
    (sector1.audioRooms.intercom &&
      sector2.audioRooms.intercom &&
      !areAudioRoomsIdentical(sector1.audioRooms.intercom!, sector2.audioRooms.intercom!))
  )
    return false
  if (!areSortedPositionsIdentical(sector1.positions, sector2.positions, checkConnectedUsers)) return false

  return true
}

function areSortedPositionsIdentical(array1: Position[], array2: Position[], checkConnectedUsers = false): boolean {
  if (array1.length !== array2.length) return false

  for (let index = 0; index < array1.length; index++) {
    if (!arePositionsIdentical(array1[index], array2[index], checkConnectedUsers)) return false
  }

  return true
}

function arePositionsIdentical(position1: Position, position2: Position, checkConnectedUsers = false): boolean {
  if (position1.name !== position2.name) return false
  if (!areSortedStringArraysIdentical(position1.additionalNames, position2.additionalNames)) return false
  if (!areSortedStringArraysIdentical(position1.allowedRoles, position2.allowedRoles)) return false
  if (!areSortedStringArraysIdentical(position1.callableSectors, position2.callableSectors)) return false
  if (!areSortedStringArraysIdentical(position1.callablePositions, position2.callablePositions)) return false
  if (
    (position1.connection && !position2.connection) ||
    (position2.connection && !position1.connection) ||
    (position1.connection &&
      position2.connection &&
      !areRemoteConnectionsIdentical(position1.connection!, position2.connection!))
  )
    return false
  if (
    checkConnectedUsers &&
    !areSortedStringArraysIdentical(position1.connectedUsers || [], position2.connectedUsers || [])
  )
    return false

  return true
}

function areRemoteConnectionsIdentical(connection1: RemoteConnection, connection2: RemoteConnection): boolean {
  if (connection1.host !== connection2.host) return false
  if (connection1.port !== connection2.port) return false
  if (connection1.remoteControl !== connection2.remoteControl) return false
  if (connection1.protocol !== connection2.protocol) return false
  if (
    (connection1.password && !connection2.password) ||
    (connection2.password && !connection1.password) ||
    connection1.password !== connection2.password
  )
    return false

  return true
}

function areSortedStringArraysIdentical(array1: string[], array2: string[]): boolean {
  if (array1.length !== array2.length) return false

  for (let index = 0; index < array1.length; index++) {
    if (array1[index] !== array2[index]) return false
  }

  return true
}

function areAudioRoomsIdentical(audioRoom1: AudioRoom, audioRoom2: AudioRoom): boolean {
  if (audioRoom1.name !== audioRoom2.name) return false
  if (audioRoom1.audioRecording !== audioRoom2.audioRecording) return false

  return true
}

function areSortedAudioRoomArraysIdentical(array1: AudioRoom[], array2: AudioRoom[]): boolean {
  if (array1.length !== array2.length) return false

  for (let index = 0; index < array1.length; index++) {
    if (!areAudioRoomsIdentical(array1[index], array2[index])) return false
  }

  return true
}

export function joinBriefingRoom(key: string, enableMicrophone = true): Promise<Room> {
  return getLivekitAccessToken(key, 'briefing').then((wsAccessToken) =>
    loadRoomAndConnect(
      {
        name: `simulation-${key}-briefing`,
        label: 'Briefing',
        accessToken: wsAccessToken.token,
        muteAllSupported: true,
      },
      'GENERAL',
      true,
      enableMicrophone,
    ),
  )
}

export function getDenormalizedForm(
  position: Position,
  sector: Sector | undefined,
  adhocIntercoms: AudioRoom[],
): SimulationPosition {
  const frequencies: PositionAudioRoom[] = []
  const intercoms: PositionAudioRoom[] = []

  if (sector) {
    frequencies.push(
      {
        name: sector.audioRooms.mainFrequency.name,
        audioRecording: sector.audioRooms.mainFrequency.audioRecording,
        defaultConnectionType: 'TX',
      },
      ...[
        ...sector.audioRooms.otherFrequencies.map((it) => ({
          name: it.name,
          audioRecording: it.audioRecording,
          defaultConnectionType: 'RX',
        })),
      ],
    )

    if (
      sector.audioRooms.intercom &&
      (position.allowedRoles.length > 1 || (position.allowedRoles.length === 1 && position.allowedRoles[0] !== 'pilot'))
    ) {
      intercoms.push({
        name: sector.audioRooms.intercom.name,
        audioRecording: sector.audioRooms.intercom.audioRecording,
        defaultConnectionType: position.allowedRoles.indexOf('atco') !== -1 ? 'TX' : 'RX',
      })
    }
  }

  intercoms.push(
    ...position.adhocIntercoms
      .map((it) => adhocIntercoms.find((intercom) => intercom.name === it))
      .map((it) => ({
        name: (it && it.name) || '',
        audioRecording: (it && it.audioRecording) || false,
        defaultConnectionType: 'RX',
      })),
  )

  return {
    name: position.name,
    sector: sector?.name || null,
    additionalNames: position.additionalNames,
    allowedRoles: position.allowedRoles,
    callablePositions: position.callablePositions,
    callableSectors: position.callableSectors,
    intercoms: intercoms,
    frequencies: frequencies,
    recordable: [...intercoms, ...frequencies].find((it) => it.audioRecording) !== undefined,
    connectedUsers: position.connectedUsers || [],
    connection: position.connection,
  }
}
