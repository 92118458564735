import React, { useEffect, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Room } from './Room'
import { RoomContext } from '@livekit/components-react'
import { Mic as MicOffIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

interface Props {
  room: Room
  microphoneToggleVetoer?: () => boolean
}

export const RoomMicrophone = ({ room, microphoneToggleVetoer }: Props) => {
  const { t } = useTranslation()

  const [microphoneEnabled, setMicrophoneEnabled] = useState(room.livekitRoom.localParticipant.isMicrophoneEnabled)

  const handleToggleMicrophone = () => {
    if (microphoneToggleVetoer && !microphoneToggleVetoer()) return

    room.livekitRoom.localParticipant
      .setMicrophoneEnabled(!room.livekitRoom.localParticipant.isMicrophoneEnabled)
      .then()
      .catch(() => {})
  }

  useEffect(() => {
    room.livekitRoom.localParticipant.on('localTrackPublished', () => {
      setMicrophoneEnabled(room.livekitRoom.localParticipant.isMicrophoneEnabled)
    })
    room.livekitRoom.localParticipant.on('trackMuted', () => {
      setMicrophoneEnabled(false)
    })
    room.livekitRoom.localParticipant.on('trackUnmuted', () => {
      setMicrophoneEnabled(true)
    })

    return () => {
      room.livekitRoom.localParticipant.removeAllListeners('trackMuted')
      room.livekitRoom.localParticipant.removeAllListeners('trackUnmuted')
      room.livekitRoom.localParticipant.removeAllListeners('localTrackPublished')
    }
  }, [])

  return (
    <RoomContext.Provider value={room.livekitRoom}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={microphoneEnabled ? t('room.microphoneActiveLabel') : t('room.microphoneInactiveLabel')}>
          <IconButton color="inherit" onClick={handleToggleMicrophone}>
            <MicOffIcon fontSize="small" color={microphoneEnabled ? 'success' : 'error'} />
          </IconButton>
        </Tooltip>
      </Box>
    </RoomContext.Provider>
  )
}
