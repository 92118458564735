import React from 'react'
import { Badge, BadgeProps, keyframes, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useConnectionState } from '@livekit/components-react'
import { ConnectionState } from 'livekit-client'

export const RoomConnectionIndicator = () => {
  const { t } = useTranslation()
  const connectionState = useConnectionState()
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      top: 13,
      width: 16,
      height: 16,
      borderRadius: 8,
    },
  }))
  const blinkAnimation = keyframes`
    0% {
      opacity: 1;
      scale: 1;
    }
    40% {
      opacity: 1;
      scale: 1;
    }
    50% {
      opacity: 0.6;
      scale: 1.14;
    }
    60% {
      opacity: 1;
      scale: 1;
    }
    100% {
      opacity: 1;
    }
  `

  return (
    <>
      <Tooltip
        title={connectionState === ConnectionState.Connected ? t('room.connectedLabel') : t('room.notConnectedLabel')}
      >
        <StyledBadge
          variant="dot"
          color={
            connectionState === ConnectionState.Connected
              ? 'success'
              : connectionState === ConnectionState.Disconnected
              ? 'error'
              : 'warning'
          }
          sx={{ marginX: 2, animation: `${blinkAnimation} 5s ease-in-out infinite` }}
        >
          &nbsp;
        </StyledBadge>
      </Tooltip>
    </>
  )
}
