import React, { useEffect, useState } from 'react'
import { useEventListener, useReadLocalStorage } from 'usehooks-ts'
import { UserContext } from './users/UserContext'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { SecuredRoute } from './SecuredRoute'
import { styled } from '@mui/material'
import { Error404Page, Error500Page } from './error-pages'
import { Room } from './rooms/Room'
import { User } from './users/User'
import { AppLoader } from './AppLoader'
import { RemoteConnectionRoute } from './remote-connections/RemoteConnectionRoute'
import { SimulationsRoute } from './simulations/SimulationsRoute'
import { SimulationRoute } from './simulations/SimulationRoute'
import { NewSimulationRoute } from './simulations/NewSimulationRoute'
import { isGloballyAuthenticated, logout } from './init/init-auth'

const AppContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<SecuredRoute />}>
        <Route index element={<SimulationsRoute />} />
        <Route path="/simulations/new-simulation" element={<NewSimulationRoute />} />
        <Route path="/simulations/:key" element={<SimulationRoute />} />
        <Route path="/simulations/:key/:positionName" element={<RemoteConnectionRoute />} />
      </Route>
      <Route path="/404" element={<Error404Page />} />
      <Route path="/500" element={<Error500Page />} />
      <Route path="/*" element={<Error404Page />} />
    </>,
  ),
  { basename:process.env.REACT_APP_ROUTER_BASENAME },
)

export const App = () => {
  const [user, setUser] = useState<User | undefined | null>()
  const [briefingRoom, setBriefingRoom] = useState<Room | undefined>()
  const [directCallRoom, setDirectCallRoom] = useState<Room | undefined>()
  const savedUser = useReadLocalStorage<User | undefined>('escape-modernization.user')

  useEventListener('user-logged-in', (event) => {
    setUser(event.detail)
  })

  useEventListener('user-logged-out', () => {
    briefingRoom?.livekitRoom.disconnect()
    directCallRoom?.livekitRoom.disconnect()
    setBriefingRoom(undefined)
    setDirectCallRoom(undefined)
    setUser(null)

    logout()
  })

  useEffect(() => {
    if (!savedUser) {
      setUser(null)
    } else {
      setUser((prevState) => {
        if (!prevState) return savedUser

        savedUser.roles.sort()
        prevState.roles.sort()

        return JSON.stringify(savedUser) === JSON.stringify(prevState) ? prevState : savedUser
      })
    }
  }, [savedUser])

  if (user === undefined || !isGloballyAuthenticated()) return <AppLoader />

  return (
    <UserContext.Provider value={user}>
      <AppContainer>
        <RouterProvider router={router} />
      </AppContainer>
    </UserContext.Provider>
  )
}
