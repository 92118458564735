import React, { useState } from 'react'
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Slide, Toolbar } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HeadsetMic, MailOutlined as HelpMailIcon } from '@mui/icons-material'
import resources from './resources'
import { DevicesDialog } from './utils/DevicesDialog'

const expandedDrawerWidth = 200

export const SideBar = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const [devicesSelectionOpen, setDevicesSelectionOpen] = useState(false)

  const handleCloseDevicesSelection = () => {
    setDevicesSelectionOpen(false)
  }

  const handleOpenDevicesSelection = () => {
    setDevicesSelectionOpen(true)
  }

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: expandedDrawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: expandedDrawerWidth,
            boxSizing: 'border-box',
          },
          [`& .MuiListItem-root`]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{ overflowY: 'auto', overflowX: 'hidden', display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}
        >
          <List>
            <ListItem>
              <ListItemButton
                component={Link}
                to={'/'}
                selected={location.pathname === '/' || location.pathname.startsWith('/simulations')}
                sx={{ minHeight: 48 }}
              >
                <ListItemText sx={{ whiteSpace: 'nowrap' }} primary={t('sideBar.simulations')} />
              </ListItemButton>
            </ListItem>
          </List>
          <Box sx={{ flex: '1 1 auto' }}></Box>
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginLeft: 5,
            }}
            onClick={handleOpenDevicesSelection}
          >
            <HeadsetMic sx={{ marginRight: 1 }} fontSize="large" /> {t('sideBar.devices')}
          </Box>
          <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginTop: 1, marginLeft: 5 }}>
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              href={`mailto:${resources.supportEmail}`}
            >
              <HelpMailIcon sx={{ marginRight: 1 }} fontSize="large" /> {t('sideBar.help')}
            </a>
          </Box>
          <Box sx={{ textAlign: 'center', marginBottom: 2, marginTop: 1 }}>v{process.env['REACT_APP_VERSION']}</Box>
        </Box>
      </Drawer>

      {devicesSelectionOpen && <DevicesDialog onClose={handleCloseDevicesSelection} />}
    </>
  )
}
