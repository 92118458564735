import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimulationForListing } from './SimulationForListing'
import { Alert, Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as RecordingIcon } from './recording-icon.svg'
import './RecordingIcon.css'

interface Props {
  simulations: SimulationForListing[]
  canCreateSimulations: boolean
  onNewSimulation: () => void
}

export const SimulationsPage = ({ simulations, canCreateSimulations, onNewSimulation }: Props) => {
  const { t } = useTranslation()

  const nonStoppedSimulations = simulations.filter((it) => it.status.executionStatus !== 'stopped')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', paddingX: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
          <Typography variant="h5">{t('simulations.title')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {canCreateSimulations && (
            <Button variant="contained" onClick={onNewSimulation}>
              {t('simulations.newSimulationButton')}
            </Button>
          )}
        </Box>
      </Box>
      {simulations.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0 auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert severity="info" sx={{ width: 400 }}>
            {canCreateSimulations
              ? t('simulations.noSimulationsAsAdminMessage')
              : t('simulations.noSimulationsMessage')}
          </Alert>
        </Box>
      )}
      {simulations.length !== 0 &&
        simulations.map((it, index) => (
          <Box
            key={it.key}
            sx={{
              marginTop: index === 0 ? 5 : 1,
              marginBottom: 1,
              borderTop: '1px solid #dfdfdf',
              paddingTop: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ flex: '1 0 auto' }}>
              <Box sx={{ marginLeft: 2 }}>
                <Typography
                  variant="h6"
                  component={Link}
                  to={`simulations/${it.key}`}
                  sx={{ display: 'inline-block', color: 'inherit', textDecoration: 'none' }}
                >
                  {it.title}
                </Typography>
              </Box>
              <Box
                component={Link}
                to={`simulations/${it.key}`}
                sx={{
                  marginLeft: 4,
                  color: 'inherit',
                  textDecoration: 'none',
                  display: 'inline-block',
                  maxWidth: '68vw',
                  whiteSpace: 'pre-line',
                }}
              >
                {it.description || '-'}
              </Box>
            </Box>
            {it.status.recordingStatus === 'recording' && (
              <RecordingIcon
                style={{
                  width: '75px',
                  height: '36px',
                  marginRight: '24px',
                }}
                className="blinking-simulation-record"
              />
            )}
            {it.status.executionStatus !== 'stopped' && (
              <Button
                sx={{ marginRight: 2 }}
                variant={!canCreateSimulations && nonStoppedSimulations.length === 1 ? 'contained' : 'outlined'}
                component={Link}
                to={`simulations/${it.key}`}
              >
                {t('common.join')}
              </Button>
            )}
          </Box>
        ))}
    </Box>
  )
}
