import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Badge, Box, Button, Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Room } from '../rooms/Room'
import { useLocalStorage } from 'usehooks-ts'
import { StereoRoomAudioRenderer } from '../rooms/StereoRoomAudioRenderer'
import { RoomContext } from '@livekit/components-react'
import { WebAudioSettings } from 'livekit-client'
import { RoomConnectionIndicator } from '../rooms/RoomConnectionIndicator'

interface Props {
  room: Room
}

export const SimulationBriefingRoom = ({ room }: Props) => {
  const { t } = useTranslation()

  const [savedInputDevice] = useLocalStorage('escape-modernization.input-device', '')
  const [audioEnabled, setAudioEnabled] = useState(
    (room.livekitRoom.options.expWebAudioMix as WebAudioSettings).audioContext.state === 'running',
  )
  const [activeSpeakers, setActiveSpeakers] = useState<string[]>(
    room.livekitRoom.activeSpeakers.map((it) => it.name || '???'),
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleEnableAudio = useCallback(() => {
    room.livekitRoom
      .startAudio()
      .then(() => {
        setAudioEnabled((room.livekitRoom.options.expWebAudioMix as WebAudioSettings).audioContext.state === 'running')
      })
      .catch(() => {})
  }, [room])

  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    room.livekitRoom.on('activeSpeakersChanged', (speakers) => {
      setActiveSpeakers(speakers.map((it) => it.name || '???'))
    })

    return () => {
      room.livekitRoom.removeAllListeners('activeSpeakersChanged')
    }
  }, [])

  useEffect(() => {
    if (!room.livekitRoom) return
    if (!savedInputDevice) return

    room.livekitRoom
      .switchActiveDevice('audioinput', savedInputDevice)
      .then()
      .catch(() => {})
  }, [room, savedInputDevice])

  return (
    <RoomContext.Provider value={room.livekitRoom}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!audioEnabled && (
          <>
            <Alert
              severity="warning"
              sx={{ paddingY: 0 }}
              action={
                <Button color="success" size="small" onClick={handleEnableAudio}>
                  {t('room.enableAudio')}
                </Button>
              }
            >
              {t('room.audioBlockedMessage')}
            </Alert>
          </>
        )}
        <RoomConnectionIndicator />
        <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          {t('simulations.briefingRoomLabel')}
        </Box>
      </Box>
      <StereoRoomAudioRenderer channel={'DUAL'} />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        sx={{
          pointerEvents: 'none',
        }}
        disableRestoreFocus
      >
        {activeSpeakers.length === 0 && <Box sx={{ padding: 2, width: 250 }}>{t('room.noActiveSpeakersMessage')}</Box>}
        {activeSpeakers.length > 0 && (
          <Box sx={{ padding: 2, width: 250 }}>
            <Box>{t('room.speakingLabel')}</Box>
            {activeSpeakers.map((it) => (
              <Box key={it}>
                <Badge variant="dot" color="primary" sx={{ marginX: 2 }} /> {it}
              </Box>
            ))}
          </Box>
        )}
      </Popover>
    </RoomContext.Provider>
  )
}
