import { RoomAccess } from './RoomAccess'
import { ConnectionState, Room as LivekitRoom } from 'livekit-client'
import { Room } from './Room'
import resources from '../resources'
import { RoomType } from './RoomType'

export async function loadRoomAndConnect(
  roomAccess: RoomAccess,
  type: RoomType,
  muteAllSupported: boolean,
  enableMicrophone: boolean = false,
  recordable: boolean = false,
): Promise<Room> {
  return new Promise<Room>((resolve, reject) => {
    const audioContext = new AudioContext()
    const livekitRoom = new LivekitRoom({ expWebAudioMix: { audioContext } })
    const room: Room = {
      name: roomAccess.name,
      label: roomAccess.label,
      muteAllSupported: muteAllSupported,
      accessToken: roomAccess.accessToken,
      livekitRoom: livekitRoom,
      type: type,
      description: '',
      uniqueSpeaker: false,
      recordable: recordable,
    }
    console.log(resources.livekitUrl!, roomAccess.accessToken)
    livekitRoom
      .connect(resources.livekitUrl!, roomAccess.accessToken)
      .then(
        () => {
          livekitRoom.startAudio().then(() => {
            if (enableMicrophone && livekitRoom.state === ConnectionState.Connected) {
              livekitRoom.localParticipant
                .setMicrophoneEnabled(true)
                .then()
                .catch(() => {})
            }
          })
          resolve(room)
        },
        (e) => {
          console.error('Error while connecting to livekit room', e)
          resolve(room)
        },
      )
      .catch((e) => {
        console.error('Error while connecting to livekit room', e)

        audioContext.close()
        reject(e)
      })
  })
}
