import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppStructure } from '../AppStructure'
import { RemoteConnectionPage } from './RemoteConnectionPage'
import { UserContext } from '../users/UserContext'
import { Error404Page, Error500 } from '../error-pages'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Simulation } from '../simulations/Simulation'
import { WsAccessToken } from '../utils/WsAccessToken'
import { getDenormalizedForm, getSimulation } from '../simulations/services'
import { AppLoader } from '../AppLoader'
import { getSimulationsWsAccessToken } from '../utils/ws-access-token'
import { Alert, Box, Button, Snackbar, styled, Typography } from '@mui/material'
import { openRemoteConnection } from './services'
import { RemoteConnection } from './RemoteConnection'
import reactUseWebSocket, { ReadyState } from 'react-use-websocket'
import resources from '../resources'
import { SimulationExecutionStatus, simulationsExecutionStatuses } from '../simulations/SimulationExecutionStatus'
import { DirectCall } from './DirectCall'
import { CenteredContent } from '../layout-components'
import { useTimeout } from 'usehooks-ts'
import { AnyRoomContext } from '../rooms/AnyRoomContext'
import { DirectCallDestination } from './DirectCallDestination'
import { Sector } from '../simulations/Sector'
import { SimulationPosition } from '../simulations/SimulationPosition'
import { SimulationRecordingStatus, simulationsRecordingStatuses } from '../simulations/SimulationRecordingStatus'

const BlackContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#101010',
  color: '#fff',
})

export const RemoteConnectionRoute = () => {
  const user = useContext(UserContext)

  const { t } = useTranslation()

  const { key, positionName } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [notFoundError, setNotFoundError] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [connectionError, setConnectionError] = useState(false)
  const [simulationDeleted, setSimulationDeleted] = useState(false)
  const [alreadyConnected, setAlreadyConnected] = useState(false)
  const [simulation, setSimulation] = useState<Simulation>()
  const [simulationExecutionStatus, setSimulationExecutionStatus] = useState<SimulationExecutionStatus>()
  const [simulationRecordingStatus, setSimulationRecordingStatus] = useState<SimulationRecordingStatus>()
  const [simulationPosition, setSimulationPosition] = useState<SimulationPosition>()
  const [wsAccessToken, setWsAccessToken] = useState<WsAccessToken>()
  const [incomingDirectCall, setIncomingDirectCall] = useState<DirectCall>()
  const [latestTerminatedDirectCallRoomName, setLatestTerminatedDirectCallRoomName] = useState<string>()
  const [anyRoom, setAnyRoom] = useState(false)
  const [sortedDirectCallDestinations, setSortedDirectCallDestinations] = useState<DirectCallDestination[]>([])

  const [disconnected, setDisconnected] = useState<boolean>()
  const [remoteConnection, setRemoteConnection] = useState<RemoteConnection>()

  const initialSimulationLoadingRef = useRef(false)
  const simulationRecordingRef = useRef(false)

  const isAdmin = useMemo(() => user?.roles?.find((it) => it === 'admin') !== undefined, [user])

  const { lastMessage, lastJsonMessage, readyState, sendJsonMessage, getWebSocket } = reactUseWebSocket(
    `${resources.wsBaseUrl || ''}/ws-positions/${key}/${positionName}?token=${wsAccessToken?.token}`,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 5000,
      heartbeat: {
        message: 'PONG',
        returnMessage: 'PING',
        timeout: 60000,
        interval: 30000,
      },
    },
    Boolean(wsAccessToken) && !simulationDeleted && !alreadyConnected,
  )

  const webSocket = getWebSocket()

  useEffect(() => {
    if (!remoteConnection) return

    return () => {
      remoteConnection.guacamoleClient.disconnect()
    }
  }, [remoteConnection])

  useEffect(() => {
    if (!lastMessage) return

    if (simulationsExecutionStatuses.indexOf(lastMessage.data) !== -1) {
      setSimulationExecutionStatus(lastMessage.data)
    } else if (simulationsRecordingStatuses.indexOf(lastMessage.data) !== -1) {
      simulationRecordingRef.current = lastMessage.data === 'recording'
      setSimulationRecordingStatus(lastMessage.data)
    }
  }, [lastMessage])

  useEffect(() => {
    if (!lastJsonMessage) return

    const rawLastJsonMessage = lastJsonMessage as any
    if (rawLastJsonMessage.notificationType && rawLastJsonMessage.notificationType === 'CREATION') {
      setIncomingDirectCall({
        initiator: false,
        roomName: rawLastJsonMessage.creationNotification.roomName,
        caller: rawLastJsonMessage.creationNotification.caller,
        recording: simulationRecordingRef.current,
        destination:
          rawLastJsonMessage.creationNotification.destination.sectorName ||
          rawLastJsonMessage.creationNotification.destination.positionName,
      })
    } else if (rawLastJsonMessage.notificationType && rawLastJsonMessage.notificationType === 'DELETION') {
      setLatestTerminatedDirectCallRoomName(rawLastJsonMessage.deletionNotification.roomName)
    }
  }, [lastJsonMessage])

  // Getting simulation and the proper position
  useEffect(() => {
    if (initialSimulationLoadingRef.current) return
    if (loadingError) return
    if (notFoundError) return
    if (!key) return
    if (simulation) return
    if (loading) return

    initialSimulationLoadingRef.current = true
    setLoading(true)

    getSimulation(key)
      .then((simulation) => {
        if (simulation.status.executionStatus !== 'running' && !isAdmin) {
          setNotFoundError(true)
        } else {
          let sector: Sector | undefined = undefined
          let position = simulation.otherPositions.find((it) => it.name === positionName)
          if (!position) {
            sector = simulation.sectors.find((it) => it.positions.find((position) => position.name === positionName))
            if (sector) {
              position = sector.positions.find((it) => it.name === positionName)
            }
          }

          if (position) {
            setSimulation(simulation)
            setSimulationPosition(getDenormalizedForm(position, sector, simulation.adhocIntercoms))
            setSimulationExecutionStatus(simulation.status.executionStatus)
            setSimulationRecordingStatus(simulation.status.recordingStatus)
          } else {
            setNotFoundError(true)
          }
        }
      })
      .catch((e) => {
        if (e === 404) {
          setNotFoundError(true)
        } else {
          setLoadingError(true)
        }
      })
      .finally(() => {
        setLoading(false)
        initialSimulationLoadingRef.current = false
      })
  }, [loading, loadingError, simulation, key, notFoundError, positionName, isAdmin])

  // Connecting to position WS
  useEffect(() => {
    if (!simulationPosition) return

    getSimulationsWsAccessToken(`simulation-position-${key}-${simulationPosition.name}`)
      .then(setWsAccessToken)
      .catch(() => {})
  }, [simulationPosition, key])

  // Handling position websocket closure codes
  useEffect(() => {
    if (!webSocket) return

    const closeEventListener = (event: any) => {
      if (event.code === 3045) {
        setSimulationDeleted(true)
      } else if (event.code === 3040) {
        setAlreadyConnected(true)
      }
    }
    webSocket.addEventListener('close', closeEventListener)

    return () => {
      webSocket.removeEventListener('close', closeEventListener)
    }
  }, [webSocket])

  // Connecting to remote connection (position) and guacd WS
  useEffect(() => {
    if (!simulationPosition) return

    openRemoteConnection(key!, simulationPosition, () => {
      setDisconnected(true)
    })
      .then(setRemoteConnection)
      .catch(() => {
        setConnectionError(true)
        setDisconnected(true)
      })
  }, [simulationPosition, key, user])

  // Disconnecting from guacd if already connected elsewhere
  useEffect(() => {
    if (!alreadyConnected) return
    if (!remoteConnection) return

    remoteConnection.guacamoleClient.disconnect()
  }, [remoteConnection, alreadyConnected])

  // Disconnecting from guacd if position deleted
  useEffect(() => {
    if (!simulationDeleted) return
    if (!remoteConnection) return

    remoteConnection.guacamoleClient.disconnect()
  }, [remoteConnection, simulationDeleted])

  // Handling hierarchic sorted direct call destinations
  useEffect(() => {
    if (!simulationPosition) return
    if (!simulation) return

    const callableDestinations: DirectCallDestination[] = simulationPosition.callableSectors.map((it) => ({
      callable: true,
      name: it,
      type: 'SECTOR',
    }))
    const positions = [
      ...simulation.sectors.flatMap((it) =>
        it.positions.map((position) => getDenormalizedForm(position, it, simulation.adhocIntercoms)),
      ),
      ...simulation.otherPositions.map((it) => getDenormalizedForm(it, undefined, simulation.adhocIntercoms)),
    ]
    // Adding additional sectors from positions
    const workableCallablePositions: SimulationPosition[] = []
    simulationPosition.callablePositions
      .map((it) => positions.find((position) => position.name === it))
      .filter((it) => it)
      .forEach((it) => {
        if (!it?.sector) {
          if (!callableDestinations.find((sector) => sector.name === '')) {
            callableDestinations.push({
              callable: false,
              name: '',
              type: 'SECTOR',
            })
          }
        } else {
          if (!callableDestinations.find((sector) => sector.name === it!.sector)) {
            callableDestinations.push({
              callable: false,
              name: it!.sector!,
              type: 'SECTOR',
            })
          }
        }

        workableCallablePositions.push(it!)
      })
    callableDestinations.sort((a, b) => (a.name || 'ZZZZ').localeCompare(b.name || 'ZZZZ'))
    workableCallablePositions.sort((a, b) => a.name.localeCompare(b.name))
    workableCallablePositions.forEach((it) => {
      const sectorIndex = callableDestinations.findIndex(
        (destination) => destination.type === 'SECTOR' && (it.sector || '') === destination.name,
      )
      callableDestinations.splice(sectorIndex + 1, 0, {
        callable: true,
        name: it.name,
        type: 'POSITION',
      })
    })

    setSortedDirectCallDestinations(callableDestinations)
  }, [simulationPosition, simulation])

  const handleClose = () => {
    try {
      window.close()
    } catch (e) {
      navigate('/')
    }
  }

  const handleAcceptDirectCall = (directCall: DirectCall) => {
    sendJsonMessage({
      actionType: 'ACCEPTANCE',
      acceptanceAction: {
        roomName: directCall.roomName,
      },
    })
  }

  const handleRejectDirectCall = (directCall: DirectCall) => {
    sendJsonMessage({
      actionType: 'REJECTION',
      rejectionAction: {
        roomName: directCall.roomName,
      },
    })
  }

  useTimeout(
    handleClose,
    !isAdmin && simulationExecutionStatus !== undefined && simulationExecutionStatus !== 'running' ? 10000 : null,
  )

  if (loadingError)
    return (
      <AppStructure>
        <Error500 message={t('remoteConnection.loadingError') || ''} />
      </AppStructure>
    )
  if (connectionError)
    return (
      <AppStructure>
        <Error500 message={t('remoteConnection.connectionError') || ''} />
      </AppStructure>
    )
  if (notFoundError) return <Error404Page />
  if (loading || !simulation || !simulationPosition)
    return (
      <AppStructure>
        <AppLoader />
      </AppStructure>
    )
  if (!remoteConnection)
    return (
      <BlackContainer>
        <AppLoader customMessageKey={'remoteConnection.connecting'} />
      </BlackContainer>
    )
  if (simulationDeleted)
    return (
      <Box>
        <CenteredContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6">{t('remoteConnection.positionUnavailableMessage')}</Typography>
          </Box>
        </CenteredContent>
      </Box>
    )
  if (!isAdmin && simulationExecutionStatus !== undefined && simulationExecutionStatus !== 'running')
    return (
      <BlackContainer>
        <CenteredContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6">{t('remoteConnection.positionUnavailableMessage')}</Typography>
          </Box>
        </CenteredContent>
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert severity="warning" sx={{ width: 400 }}>
            {t('remoteConnection.positionClosingMessage')}
          </Alert>
        </Snackbar>
      </BlackContainer>
    )
  if (alreadyConnected)
    return (
      <BlackContainer>
        <CenteredContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6">{t('remoteConnection.alreadyConnectedMessage')}</Typography>
          </Box>
        </CenteredContent>
      </BlackContainer>
    )
  if (disconnected || readyState === ReadyState.CLOSED)
    return (
      <BlackContainer>
        <CenteredContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h6">{t('remoteConnection.positionUnavailableMessage')}</Typography>
          </Box>
        </CenteredContent>
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert
            severity="warning"
            sx={{ width: 400 }}
            action={
              <Button
                color="success"
                onClick={() => {
                  window.location.reload()
                }}
              >
                {t('common.refresh')}
              </Button>
            }
          >
            {t('common.wsError')}
          </Alert>
        </Snackbar>
      </BlackContainer>
    )

  return (
    <AnyRoomContext.Provider value={anyRoom}>
      <RemoteConnectionPage
        simulationKey={key!}
        simulationExecutionStatus={simulationExecutionStatus!}
        simulationRecordingStatus={simulationRecordingStatus!}
        remoteConnection={remoteConnection}
        simulationPosition={simulationPosition}
        incomingDirectCall={incomingDirectCall}
        latestTerminatedDirectCallRoomName={latestTerminatedDirectCallRoomName}
        directCallsDestinations={sortedDirectCallDestinations}
        connectAsAdmin={isAdmin}
        canInitiateCalls={true}
        onGoBack={handleClose}
        onAnyRoom={setAnyRoom}
        onAcceptDirectCall={handleAcceptDirectCall}
        onRejectDirectCall={handleRejectDirectCall}
      />
    </AnyRoomContext.Provider>
  )
}
