import React from 'react'
import { createTheme } from '@mui/material'
import { LinkProps } from '@mui/material/Link'
import { frFR } from '@mui/material/locale'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'
import i18n from 'i18next'

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    return <Link ref={ref} to={href} {...other} />
  },
)
LinkBehavior.displayName = 'LinkBehavior'

const language = i18n.language

const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#456595',
        main: '#003366',
        dark: '#00234f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#90c6f5',
        main: '#2990ea',
        dark: '#1c60b7',
        contrastText: '#00234f',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },
  },
  language === 'fr' ? frFR : [],
)

export default theme
