// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    yes: 'Oui',
    no: 'Non',
    cancel: 'Annuler',
    delete: 'Supprimer',
    save: 'Enregistrer',
    add: 'Ajouter',
    set: 'Définir',
    apply: 'Appliquer',
    create: 'Créer',
    edit: 'Modifier',
    clear: 'Effacer',
    next: 'Suivant',
    refresh: 'Rafraîchir',
    reload: 'Recharger',
    previous: 'Précédent',
    back: 'Retour',
    update: 'Mettre à jour',
    import: 'Importer',
    export: 'Exporter',
    join: 'Rejoindre',
    notAvailable: 'N/A',
    semicolonSeparator: ' :',
    badRequestError: 'Erreur de validation, veuillez vérifier votre saisie',
    unexpectedError: 'Une erreur inattendue s’est produite, veuillez contacter le support',
    livekitError: 'Erreur de connexion à Livekit, veuillez réessayer ultérieurement',
    wsError: 'La connexion au serveur a été perdue, veuillez rafraîchir la page web',
  },
  app: {
    title: 'ESCAPE Modernization',
    logoutButton: 'Deconnexion',
    homeButton: 'Accueil',
    disconnectedTooltip: 'Déconnecté du serveur',
    menu: {
      logout: 'Deconnexion',
    },
  },
  loading: {
    loadingMessage: 'Chargement...',
  },
  sideBar: {
    simulations: 'Simulations',
    help: 'Aide',
    devices: 'Périphériques',
  },
  login: {
    login: 'Identifiant',
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    submit: 'Connexion',
    credentialsError: 'Identifiant/mot de passe incorrects',
    loginRequired: 'L’identifiant est obligatoire',
    passwordRequired: 'Le mot de passe est obligatoire',
    genericError: 'Erreur de communication avec le serveur, veuillez contacter le support',
  },
  call: {
    directCall: 'Appel direct',
    callFromPositionLabel: 'Appel entrant de {{position}}',
    callingPositionLabel: 'Appel en cours {{position}}',
    connectedToPositionLabel: 'En communication avec {{position}}',
    callTooltip: 'Appeler',
    acceptTooltip: 'Décrocher',
    rejectTooltip: 'Raccrocher',
    micDisabledTooltip: 'Micro coupé',
    connectingLabel: 'Connexion',
  },
  simulations: {
    title: 'Liste des simulations',
    newSimulationButton: 'Nouvelle simulation',
    listingError: 'Erreur lors de la récupération des simulations, veuillez contacter le support',
    loadingError: 'Erreur lors de la récupération de la simulation, veuillez contacter le support',
    briefingRoomJoiningError: 'Erreur lors de la connexion au canal de briefing, veuillez contacter le support',
    noSimulationsMessage: 'Aucune simulation configurée pour votre profil',
    noSimulationsAsAdminMessage: 'Aucune simulation enregistrée',
    deleteConfirmationMessage: 'Supprimer cette simulation',
    recordConfirmationMessage: 'Enregistrer cette simulation',
    stopRecordingConfirmationMessage: 'Arrêter l’enregistrement de cette simulation',
    recordingTooltip: 'Enregistrement audio en cours',
    recordButton: 'Enregistrer',
    remotelyDeletedMessage: 'La simulation a été supprimée',
    remotelyUpdatedByAdminMessage: 'L’administrateur {{user}} vient de modifier cette simulation',
    remotelyUpdatedBySelfMessage: 'Votre utilisateur vient de modifier cette simulation dans une autre fenêtre',
    unmuteInBriefingDisabledMessage: 'Parler pendant le briefing n’est pas possible pendant une simulation active',
    joinBriefing: 'Rejoindre briefing',
    leaveBriefing: 'Quitter briefing',
    briefingRoomLabel: 'Briefing',
    statuses: {
      stopped: 'Arrêtée',
      briefing: 'En préparation',
      running: 'En cours',
    },
    form: {
      duplicateErrorMessage: 'Une simulation avec le même titre existe déjà',
      lockedErrorMessage: 'Une simulation en cours ne peut pas être modifiée',
      validationErrorMessage: 'La simulation contient des données incorrectes, veuillez la corriger et réessayer',
      importErrorMessage: 'Le format du fichier importé est incorrect, veuillez le corriger et réessayer',
      editTitle: 'Détails de la simulation',
      createTitle: 'Création d’une nouvelle simulation',
      statusTitle: 'Statut :',
      titleTitle: 'Titre :',
      titlePlaceholder: '[Nom de la simulation]',
      titleEmptyErrorMessage: 'Le nom de la simulation est obligatoire',
      titleTooShortErrorMessage: 'Le nom de la simulation est trop court',
      titleAlreadyExistsErrorMessage: 'Le nom de la simulation existe déjà',
      descriptionTitle: 'Description :',
      descriptionPlaceholder: '[Description de la simulation]',
      positionsTitle: 'Positions :',
      visibleToGroupsTitle: 'Visible pour :',
      otherSectorsTitle: 'Autres',
      noPositionsAsAdminMessage: 'Aucune position définie',
      noPositionsAsNonAdminMessage: 'Aucune position configurée pour votre profil',
      noPositionsAfterSearchMessage: 'Aucune position correspondant a votre recherche',
      positions: {
        nameTitle: 'Nom position :',
        additionalNamesTitle: 'Autres noms :',
        sectorAndTypeTitle: 'Secteur/Type :',
        callableSectorsTitle: 'Appels secteurs :',
        callablePositionsTitle: 'Appels positions :',
        frequenciesColumnHeader: 'Fréquences',
        intercomsColumnHeader: 'Intercoms',
        otherSector: 'Autre',
        remoteConnectionVncTitle: 'VNC :',
        remoteConnectionHostTitle: 'Hôte :',
        remoteConnectionPortTitle: 'Port :',
        remoteConnectionPasswordTitle: 'Mot de passe :',
        remoteConnectionRemoteControlTitle: 'Ctrl distant :',
        remoteConnectionRemoteControlTrue: 'vrai',
        remoteConnectionRemoteControlFalse: 'faux',
      },
    },
  },
  remoteConnection: {
    connectionError: 'Erreur de connexion au serveur, veuillez contacter le support',
    loadingError: 'Erreur lors de la récupération de la position, veuillez contacter le support',
    disconnectionError: 'Déconnexion du serveur, veuillez réessayer ultérieurement',
    positionUnavailableMessage: 'Cette position n’est plus disponible',
    positionStatusMessage: 'Le statut de la simulation a changé, l’audio n’est plus disponible',
    positionClosingMessage: 'Le statut de la simulation a changé, cette position va se fermer',
    noAudioMessage: 'Audio pas encore disponible pour cette position',
    noFrequenciesMessage: 'Aucune fréquence',
    alreadyConnectedMessage:
      'Vous êtes déjà connecté à cette simulation en cours, veuillez fermer les connexions existantes et réessayez',
    connecting: 'Connexion à la position',
    supervisionRoomLabel: 'Intercom de supervision',
    disconnect: 'Quitter la position',
    connectionLabel: '{{name}}',
    pinOverlayMessage: 'Epingler le panneau de contrôle',
    unpinOverlayMessage: 'Désépingler le panneau de contrôle',
    unknownDirectCallDestinationLabel: 'Autre',
  },
  room: {
    reconnect: 'Reconnecter',
    audioBlockedMessage: 'Audio désactivée',
    roomUnavailableMessage: 'Cette room n’est plus disponible',
    enableAudio: 'Activer',
    connectedLabel: 'Connecté',
    notConnectedLabel: 'Déconnecté',
    microphoneActiveLabel: 'Micro actif',
    microphoneInactiveLabel: 'Micro inactif',
    transmissionLabel: 'Tx',
    receptionLabel: 'Rx',
    muteAllLabel: 'Tous en sourdine',
    unmuteLabel: 'Réactiver le micro',
    mutedInRoomMessage: 'Vous avez a été mis en sourdine par {{muter}} dans {{room}}',
    speakingLabel: 'En train de parler :',
    noActiveSpeakersMessage: 'Aucun orateur actif',
  },
  devices: {
    title: 'Périphériques',
    activeRoomsMessage: 'Impossible de modifier les périphériques audio en étant déjà connecté à un canal audio',
    defaultDevice: 'Périphérique système par défaut',
    outputSelectionDisabledMessage:
      'Ce navigateur ne supporte pas encore le changement d’écouteurs en gérant le son stéréo, veuillez temporairement utiliser les réglages système',
    input: 'Microphone',
    output: 'Ecouteurs',
    selectOutput: 'Choisir écouteurs',
    pttKey: 'Touche PTT',
    pttKeySpace: 'Espace',
    pttKeyCtrl: 'CTRL',
    pttKeyAlt: 'ALT',
    pttKeyCustom: 'Personnalisé',
    pttNoKeyLabel: 'Aucune touche configurée',
    pttKeyLabel: 'Touche configurée : {{key}}',
    pttInputKeyLabel: 'Appuyer sur une touche',
  },
  errors: {
    error403: 'Vous n’avez pas l’autorisation d’accéder à cette page, veuillez contacter le support',
    error404: 'La page que vous avez demandée n’existe pas',
    error500: 'L’application a rencontré une erreur, veuillez réessayer ultérieurement',
  },
}
