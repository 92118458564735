const resources = {
  keycloakBaseUrl:process.env.REACT_APP_KEYCLOAK_URL,
  keycloakRealm:process.env.REACT_APP_KEYCLOAK_REALM,
  keycloakClientId:process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  apiBaseUrl:process.env.REACT_APP_API_BASE_URL,
  wsBaseUrl:process.env.REACT_APP_WS_BASE_URL,
  livekitUrl:process.env.REACT_APP_LIVEKIT_WS_URL,
  guacamoleUrl:process.env.REACT_APP_GUACAMOLE_WS_URL,
  supportEmail:process.env.REACT_APP_SUPPORT_EMAIL,
}



export default resources
