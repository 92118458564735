import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CenteredContent } from './layout-components'

interface Props {
  customMessageKey?: string
}

export const AppLoader = ({ customMessageKey }: Props) => {
  const { t } = useTranslation()

  return (
    <CenteredContent>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CircularProgress size={32} sx={{ marginRight: 3, color: 'inherit' }} />
        <Typography variant="h6">{t(customMessageKey || 'loading.loadingMessage')}</Typography>
      </Box>
    </CenteredContent>
  )
}
