import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Simulation } from './Simulation'
import { FormChangeEvent, SimulationForm } from './SimulationForm'
import {
  areSimulationsIdentical,
  createSimulation,
  deleteSimulation,
  joinBriefingRoom,
  startSimulationRecording,
  stopSimulationRecording,
  updateSimulation,
  updateSimulationStatus,
} from './services'
import { SimulationExecutionStatus } from './SimulationExecutionStatus'
import { SimulationBriefingRoom } from './SimulationBriefingRoom'
import { RoomMicrophone } from '../rooms/RoomMicrophone'
import { Room } from '../rooms/Room'
import { useTimeout } from 'usehooks-ts'
import { ReactComponent as RecordingIcon } from './recording-icon.svg'
import './RecordingIcon.css'
import { blinkDurationInMilliseconds } from './blink-data'

interface Props {
  simulationKey?: string
  canEditSimulations: boolean
  simulationDeleted: boolean
  simulation: Simulation
  remotelyUpdatedSimulation?: Simulation
  lastRemoteUpdaterOtherThanCurrentUser?: string
  onGoBack: () => void
  onAnyRoom: (anyRoom: boolean) => void
  onOpenPosition?: (name: string) => void
}

const noUpdatedPositions: string[] = []

export const SimulationPage = ({
  simulation,
  canEditSimulations,
  onGoBack,
  onAnyRoom,
  onOpenPosition,
  simulationKey,
  remotelyUpdatedSimulation,
  lastRemoteUpdaterOtherThanCurrentUser,
  simulationDeleted,
}: Props) => {
  const { t } = useTranslation()

  const [referenceSimulation, setReferenceSimulation] = useState(simulation)
  const [editedSimulation, setEditedSimulation] = useState(simulation)
  const [previousSimulation, setPreviousSimulation] = useState(simulation)
  const [lastProcessedRemotelyUpdatedSimulation, setLastProcessedRemotelyUpdatedSimulation] = useState<Simulation>()
  const [editedStatus, setEditedStatus] = useState(simulation.status.executionStatus)
  const [ongoingRequest, setOngoingRequest] = useState(false)
  const [simulationOutdated, setSimulationOutdated] = useState(false)
  const [simulationDirty, setSimulationDirty] = useState(false)
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)
  const [recordConfirmationDialogOpen, setRecordConfirmationDialogOpen] = useState(false)
  const [stopRecordingConfirmationDialogOpen, setStopRecordingConfirmationDialogOpen] = useState(false)
  const [duplicateErrorSnackbarOpen, setDuplicateErrorSnackbarOpen] = useState(false)
  const [validationErrorSnackbarOpen, setValidationErrorSnackbarOpen] = useState(false)
  const [simulationLockedErrorSnackbarOpen, setSimulationLockedErrorSnackbarOpen] = useState(false)
  const [otherErrorSnackbarOpen, setOtherErrorSnackbarOpen] = useState(false)
  const [briefingRoomMicrophoneSnackbarOpen, setBriefingRoomMicrophoneSnackbarOpen] = useState(false)
  const [remoteUpdateSnackbarOpen, setRemoteUpdateSnackbarOpen] = useState(false)
  const [existingSimulationsTitles, setExistingSimulationsTitles] = useState<string[]>([])
  const [joiningBriefing, setJoiningBriefing] = useState(false)
  const [briefingRoom, setBriefingRoom] = useState<Room>()

  const [blinking, setBlinking] = useState(false)

  const initialBriefingRoomLoadingRef = useRef(false)

  const availableStatuses: SimulationExecutionStatus[] = useMemo(() => {
    if (editedSimulation.status.executionStatus === 'stopped') {
      return ['stopped', 'briefing']
    } else if (editedSimulation.status.executionStatus === 'briefing') {
      return ['stopped', 'briefing', 'running']
    } else {
      return ['briefing', 'running']
    }
  }, [editedSimulation.status.executionStatus])

  const allowedGroupsRemotelyUpdated = useMemo(() => {
    if (referenceSimulation.allowedGroups.length !== previousSimulation.allowedGroups.length) return true
    if (referenceSimulation.allowedGroups.find((it) => previousSimulation.allowedGroups.indexOf(it) === -1)) return true
    if (previousSimulation.allowedGroups.find((it) => referenceSimulation.allowedGroups.indexOf(it) === -1)) return true

    return false
  }, [referenceSimulation, previousSimulation])

  const remotelyUpdatedPositions: string[] = useMemo(() => {
    if (referenceSimulation === previousSimulation) return noUpdatedPositions
    // We rely on the fact that arrays are sorted
    // We use a simple approach for now, if we find a single difference everything is marked as updated
    const simulation1PositionTextRepresentation = JSON.stringify({
      otherPositions: referenceSimulation.otherPositions,
      sectors: referenceSimulation.sectors,
    })
    const simulation2PositionTextRepresentation = JSON.stringify({
      otherPositions: previousSimulation.otherPositions,
      sectors: previousSimulation.sectors,
    })
    if (simulation1PositionTextRepresentation === simulation2PositionTextRepresentation) return noUpdatedPositions

    return ['virtual-position']
  }, [referenceSimulation, previousSimulation])

  // Cleanups
  useEffect(() => {
    if (!briefingRoom) return

    return () => {
      briefingRoom.livekitRoom.disconnect().then().catch(console.error)
    }
  }, [briefingRoom])

  // Any room notification
  useEffect(() => {
    onAnyRoom(Boolean(briefingRoom))
  }, [briefingRoom])

  // Handling remote changes to this simulation by other sessions
  // !Keep in mind that when connected users are updated to a position we also go through this hook!
  useEffect(() => {
    if (!lastProcessedRemotelyUpdatedSimulation) return
    if (lastProcessedRemotelyUpdatedSimulation === referenceSimulation) return
    if (areSimulationsIdentical(referenceSimulation, lastProcessedRemotelyUpdatedSimulation, true)) return
    if (areSimulationsIdentical(referenceSimulation, lastProcessedRemotelyUpdatedSimulation, false)) {
      // Only connected users are changed
      setReferenceSimulation(lastProcessedRemotelyUpdatedSimulation)
      setLastProcessedRemotelyUpdatedSimulation(undefined)

      return
    }

    if (simulationDirty) {
      setSimulationOutdated(true)
    } else {
      setEditedSimulation(lastProcessedRemotelyUpdatedSimulation)
      setPreviousSimulation(referenceSimulation)
      setBlinking(true)
    }

    setRemoteUpdateSnackbarOpen(true)
    setEditedStatus(lastProcessedRemotelyUpdatedSimulation.status.executionStatus)
    setReferenceSimulation(lastProcessedRemotelyUpdatedSimulation)
    setLastProcessedRemotelyUpdatedSimulation(undefined)
  }, [simulationDirty, lastProcessedRemotelyUpdatedSimulation, referenceSimulation])

  useTimeout(() => setBlinking(false), blinking ? blinkDurationInMilliseconds : null)

  useEffect(() => {
    if (!remotelyUpdatedSimulation) return

    setLastProcessedRemotelyUpdatedSimulation(remotelyUpdatedSimulation)
  }, [remotelyUpdatedSimulation])

  useEffect(() => {
    if (!simulationDeleted) return
    if (!briefingRoom) return

    setBriefingRoom(undefined)
  }, [simulationDeleted, briefingRoom])

  // Disconnecting from briefing room if needed
  useEffect(() => {
    if (editedSimulation.status.executionStatus !== 'stopped') return
    if (!briefingRoom) return

    setBriefingRoom(undefined)
  }, [editedSimulation, briefingRoom])

  // Connecting to briefing room if needed
  useEffect(() => {
    if (!canEditSimulations) return
    if (initialBriefingRoomLoadingRef.current) return
    if (editedSimulation.status.executionStatus === 'stopped') return
    if (briefingRoom) return

    initialBriefingRoomLoadingRef.current = true
    setJoiningBriefing(true)

    joinBriefingRoom(simulationKey!)
      .then(setBriefingRoom)
      .catch(() => {})
      .finally(() => {
        initialBriefingRoomLoadingRef.current = false
        setJoiningBriefing(false)
      })
  }, [editedSimulation.status, canEditSimulations, briefingRoom])

  useEffect(() => {
    if (!briefingRoom) return
    if (canEditSimulations) return

    briefingRoom.livekitRoom.localParticipant
      .setMicrophoneEnabled(editedSimulation.status.executionStatus !== 'running')
      .then()
      .catch(() => {})
  }, [editedSimulation, briefingRoom, canEditSimulations])

  const handleFormChange = (event: FormChangeEvent) => {
    setSimulationDirty(!areSimulationsIdentical(event.simulation, referenceSimulation))
    setEditedSimulation(event.simulation)
  }

  const handleFormSubmission = () => {
    const backupReferenceSimulation = referenceSimulation
    const backupPreviousSimulation = previousSimulation

    setOngoingRequest(true)
    setSimulationDirty(false)
    setReferenceSimulation(editedSimulation)
    setPreviousSimulation(editedSimulation)
    setLastProcessedRemotelyUpdatedSimulation(undefined)
    ;(simulationKey ? updateSimulation(simulationKey, editedSimulation) : createSimulation(editedSimulation))
      .then(() => {
        if (!simulationKey) {
          onGoBack()
        }
      })
      .catch((e) => {
        setSimulationDirty(true)
        setReferenceSimulation(backupReferenceSimulation)
        setPreviousSimulation(backupPreviousSimulation)
        if (e === 409) {
          setDuplicateErrorSnackbarOpen(true)
          setExistingSimulationsTitles((prevState) => [...prevState, editedSimulation.title])
        } else if (e === 423) {
          setSimulationLockedErrorSnackbarOpen(true)
        } else if (e === 400) {
          setValidationErrorSnackbarOpen(true)
        } else {
          setOtherErrorSnackbarOpen(true)
        }
      })
      .finally(() => {
        setOngoingRequest(false)
      })
  }

  const handleReload = () => {
    setPreviousSimulation(editedSimulation)
    setEditedSimulation(referenceSimulation)
    setSimulationOutdated(false)
    setRemoteUpdateSnackbarOpen(false)
    setSimulationDirty(false)
    setBlinking(true)
  }

  const handleDeletePrompt = () => {
    setDeleteConfirmationDialogOpen(true)
  }

  const handleDeleteConfirmation = () => {
    if (!simulationKey) return

    setOngoingRequest(true)
    setDeleteConfirmationDialogOpen(false)

    deleteSimulation(simulationKey)
      .then(() => {
        onGoBack()
      })
      .catch((e) => {
        if (e === 404) {
          onGoBack()
        } else {
          setOtherErrorSnackbarOpen(true)
        }
      })
      .finally(() => {
        setOngoingRequest(false)
      })
  }

  const handleRecordConfirmation = () => {
    const backupReferenceSimulation = referenceSimulation
    const newSimulation = {
      ...editedSimulation,
      status: {
        executionStatus: editedSimulation.status.executionStatus,
        recordingStatus: 'recording',
      },
    }

    setOngoingRequest(true)
    setReferenceSimulation(newSimulation)
    setLastProcessedRemotelyUpdatedSimulation(undefined)

    startSimulationRecording(simulationKey!)
      .then(() => {
        setEditedSimulation(newSimulation)
        setRecordConfirmationDialogOpen(false)
      })
      .catch((e) => {
        setReferenceSimulation(backupReferenceSimulation)
        if (e === 404) {
          onGoBack()
        } else {
          setOtherErrorSnackbarOpen(true)
        }
      })
      .finally(() => {
        setOngoingRequest(false)
      })
  }

  const handleStopRecordingPrompt = () => {
    setStopRecordingConfirmationDialogOpen(true)
  }

  const handleStopRecordingConfirmation = () => {
    const backupReferenceSimulation = referenceSimulation
    const newSimulation = {
      ...editedSimulation,
      status: {
        executionStatus: editedSimulation.status.executionStatus,
        recordingStatus: 'notRecording',
      },
    }

    setOngoingRequest(true)
    setReferenceSimulation(newSimulation)
    setLastProcessedRemotelyUpdatedSimulation(undefined)

    stopSimulationRecording(simulationKey!)
      .then(() => {
        setEditedSimulation(newSimulation)
        setStopRecordingConfirmationDialogOpen(false)
      })
      .catch((e) => {
        setReferenceSimulation(backupReferenceSimulation)
        if (e === 404) {
          onGoBack()
        } else {
          setOtherErrorSnackbarOpen(true)
        }
      })
      .finally(() => {
        setOngoingRequest(false)
      })
  }

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus: SimulationExecutionStatus = event.target.value
    const backupReferenceSimulation = referenceSimulation
    const newSimulation = {
      ...editedSimulation,
      status: {
        executionStatus: newStatus,
        recordingStatus: newStatus === 'running' ? editedSimulation.status.recordingStatus : 'notRecording',
      },
    }

    setOngoingRequest(true)
    setReferenceSimulation(newSimulation)
    setLastProcessedRemotelyUpdatedSimulation(undefined)

    updateSimulationStatus(simulationKey!, newStatus)
      .then(() => {
        setEditedStatus(newStatus)
        setEditedSimulation(newSimulation)
        if (newStatus === 'running') {
          setRecordConfirmationDialogOpen(true)
        }
      })
      .catch((e) => {
        setReferenceSimulation(backupReferenceSimulation)
        if (e === 404) {
          onGoBack()
        } else {
          setOtherErrorSnackbarOpen(true)
        }
      })
      .finally(() => {
        setOngoingRequest(false)
      })
  }

  const handleJoinBriefing = () => {
    setJoiningBriefing(true)

    joinBriefingRoom(simulationKey!, canEditSimulations || editedSimulation.status.executionStatus !== 'running')
      .then(setBriefingRoom)
      .catch(() => {})
      .finally(() => {
        setJoiningBriefing(false)
      })
  }

  const handleLeaveBriefing = () => {
    setBriefingRoom(undefined)
  }

  const handleConnectToPosition = (name: string) => {
    if (!onOpenPosition) return

    if (canEditSimulations && briefingRoom) {
      briefingRoom.livekitRoom.localParticipant.setMicrophoneEnabled(false).then().catch(console.error)
    } else if (!canEditSimulations && briefingRoom) {
      setBriefingRoom(undefined)
    }

    onOpenPosition(name)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', paddingX: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto', height: 36 }}>
          {simulationKey && <Typography variant="h5">{t('simulations.form.editTitle')}</Typography>}
          {editedSimulation.status.recordingStatus === 'recording' && (
            <Tooltip title={t('simulations.recordingTooltip')}>
              <RecordingIcon
                onClick={canEditSimulations ? handleStopRecordingPrompt : undefined}
                style={{
                  width: '62px',
                  height: '30px',
                  marginLeft: '8px',
                  cursor: canEditSimulations ? 'pointer' : 'inherit',
                }}
                className="blinking-simulation-record"
              />
            </Tooltip>
          )}
          {!simulationKey && <Typography variant="h5">{t('simulations.form.createTitle')}</Typography>}
        </Box>
        {joiningBriefing && <CircularProgress sx={{ marginRight: 2, alignSelf: 'center' }} size={24} />}
        {briefingRoom && (
          <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
            <SimulationBriefingRoom room={briefingRoom} />
            <Box sx={{ marginX: 1 }}></Box>
            <RoomMicrophone
              room={briefingRoom}
              microphoneToggleVetoer={() => {
                if (!canEditSimulations && editedStatus !== 'briefing') {
                  setBriefingRoomMicrophoneSnackbarOpen(true)
                  return false
                }
                return true
              }}
            />
          </Box>
        )}
        {canEditSimulations && simulationKey && (
          <Box sx={{ marginLeft: briefingRoom ? 3 : 0, marginRight: 2, display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: 16 }} htmlFor="simulation-status">
              {t('simulations.form.statusTitle')}
            </label>
            <TextField
              id="simulation-status"
              name="status"
              label={''}
              sx={{ width: 170 }}
              inputProps={{ id: 'simulation-status' }}
              value={editedStatus}
              onChange={handleStatusChange}
              disabled={ongoingRequest || simulationOutdated || simulationDirty}
              size="small"
              variant="outlined"
              fullWidth
              select
            >
              {availableStatuses.map((it) => (
                <MenuItem key={it} value={it}>
                  {t(`simulations.statuses.${it}`)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>
      <Box sx={{ flex: '1 0 auto', paddingX: 2, marginTop: 2 }}>
        <SimulationForm
          simulation={editedSimulation}
          referenceSimulation={referenceSimulation}
          canEditSimulations={canEditSimulations}
          existingSimulationsTitles={existingSimulationsTitles}
          readonly={!canEditSimulations || ongoingRequest || simulationDeleted || editedStatus === 'running'}
          disabled={
            ongoingRequest ||
            simulationDeleted ||
            simulationOutdated ||
            (editedStatus === 'running' && canEditSimulations)
          }
          backDisabled={ongoingRequest}
          deleted={simulationDeleted}
          outdated={simulationOutdated}
          submitDisabled={!simulationDirty}
          onBack={onGoBack}
          onCreate={simulationKey === undefined && canEditSimulations ? handleFormSubmission : undefined}
          onUpdate={simulationKey !== undefined && canEditSimulations ? handleFormSubmission : undefined}
          onChange={handleFormChange}
          onDelete={simulationKey !== undefined && canEditSimulations ? handleDeletePrompt : undefined}
          onJoinBriefing={
            simulationKey !== undefined && !canEditSimulations && editedStatus !== 'stopped' && !briefingRoom
              ? handleJoinBriefing
              : undefined
          }
          joinBriefingDisabled={joiningBriefing}
          onLeaveBriefing={
            simulationKey !== undefined && !canEditSimulations && editedStatus !== 'stopped' && briefingRoom
              ? handleLeaveBriefing
              : undefined
          }
          onConnectToPosition={canEditSimulations || editedStatus === 'running' ? handleConnectToPosition : undefined}
          blinkTitle={!simulationDeleted && blinking && referenceSimulation.title !== previousSimulation.title}
          blinkDescription={
            !simulationDeleted && blinking && referenceSimulation.description !== previousSimulation.description
          }
          blinkAllowedGroups={!simulationDeleted && blinking && allowedGroupsRemotelyUpdated}
          blinkingPositions={!simulationDeleted && blinking ? remotelyUpdatedPositions : noUpdatedPositions}
        />
      </Box>

      <Dialog open={deleteConfirmationDialogOpen} onClose={() => setDeleteConfirmationDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>{t('simulations.deleteConfirmationMessage')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationDialogOpen(false)}>{t('common.cancel')}</Button>
          <Button onClick={handleDeleteConfirmation}>{t('common.delete')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={recordConfirmationDialogOpen} onClose={() => setRecordConfirmationDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>{t('simulations.recordConfirmationMessage')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRecordConfirmationDialogOpen(false)} disabled={ongoingRequest}>
            {t('common.cancel')}
          </Button>
          <Button onClick={handleRecordConfirmation} disabled={ongoingRequest}>
            {t('simulations.recordButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={stopRecordingConfirmationDialogOpen} onClose={() => setStopRecordingConfirmationDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>{t('simulations.stopRecordingConfirmationMessage')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStopRecordingConfirmation} disabled={ongoingRequest}>
            {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={duplicateErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setDuplicateErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="info"
          sx={{ width: 400 }}
          onClose={() => {
            setDuplicateErrorSnackbarOpen(false)
          }}
        >
          {t('simulations.form.duplicateErrorMessage')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={simulationLockedErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setSimulationLockedErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="error"
          sx={{ width: 400 }}
          onClose={() => {
            setSimulationLockedErrorSnackbarOpen(false)
          }}
        >
          {t('simulations.form.lockedErrorMessage')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={validationErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setValidationErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="error"
          sx={{ width: 400 }}
          onClose={() => {
            setValidationErrorSnackbarOpen(false)
          }}
        >
          {t('simulations.form.validationErrorMessage')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={otherErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setOtherErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="error"
          sx={{ width: 400 }}
          onClose={() => {
            setOtherErrorSnackbarOpen(false)
          }}
        >
          {t('common.unexpectedError')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={canEditSimulations && remoteUpdateSnackbarOpen && !simulationDeleted}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity={simulationDirty ? 'warning' : 'info'}
          sx={{ width: 400 }}
          onClose={() => {
            setRemoteUpdateSnackbarOpen(false)
          }}
          action={
            simulationDirty ? (
              <Button color="success" onClick={handleReload}>
                {t('common.reload')}
              </Button>
            ) : undefined
          }
        >
          {lastRemoteUpdaterOtherThanCurrentUser
            ? t('simulations.remotelyUpdatedByAdminMessage', { user: lastRemoteUpdaterOtherThanCurrentUser })
            : t('simulations.remotelyUpdatedBySelfMessage')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={briefingRoomMicrophoneSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setBriefingRoomMicrophoneSnackbarOpen(false)
        }}
      >
        <Alert
          severity="info"
          sx={{ width: 400 }}
          onClose={() => {
            setBriefingRoomMicrophoneSnackbarOpen(false)
          }}
        >
          {t('simulations.unmuteInBriefingDisabledMessage')}
        </Alert>
      </Snackbar>
    </Box>
  )
}
