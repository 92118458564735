import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Alert,
  AppBar,
  Box,
  Button,
  ButtonProps,
  Divider,
  IconButton,
  Slider,
  Snackbar,
  Stack,
  styled,
  ToggleButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Keyboard, Mouse } from 'guacamole-common-js'
import {
  Hearing as RemoteSpeakersIcon,
  Logout as QuitIcon,
  Mic as MicOnIcon,
  MicOff as MicOffIcon,
  PushPin as PinIcon,
  VolumeDown as VolumeOnIcon,
  VolumeOff as VolumeOffIcon,
} from '@mui/icons-material'
import { RemoteConnection } from './RemoteConnection'
import { RoomContext } from '@livekit/components-react'
import { RoomConnectionIndicator } from '../rooms/RoomConnectionIndicator'
import { useLocalStorage, useTimeout, useWindowSize } from 'usehooks-ts'
import { StereoRoomAudioRenderer } from '../rooms/StereoRoomAudioRenderer'
import Grid2 from '@mui/material/Unstable_Grid2'
import { DataPacket_Kind, Participant, RoomEvent, WebAudioSettings } from 'livekit-client'
import { RemoteConnectionDirectCallBar } from './RemoteConnectionDirectCallBar'
import { DirectCall } from './DirectCall'
import { Room } from '../rooms/Room'
import { RemoteConnectionPublicRooms } from './RemoteConnectionPublicRooms'
import { connectToDirectCall, getRemoteConnectionPublicRooms, initiateDirectCall } from './services'
import { SimulationExecutionStatus } from '../simulations/SimulationExecutionStatus'
import { DirectCallDestination } from './DirectCallDestination'
import { SimulationPosition } from '../simulations/SimulationPosition'
import { SimulationRecordingStatus } from '../simulations/SimulationRecordingStatus'
import { ReactComponent as RecordingIcon } from '../simulations/recording-icon.svg'
import '../simulations/RecordingIcon.css'
import { rejects } from 'assert'

interface Props {
  simulationKey: string
  simulationExecutionStatus: SimulationExecutionStatus
  simulationRecordingStatus: SimulationRecordingStatus
  remoteConnection: RemoteConnection
  incomingDirectCall?: DirectCall
  latestTerminatedDirectCallRoomName?: string
  simulationPosition: SimulationPosition
  directCallsDestinations: DirectCallDestination[]
  connectAsAdmin: boolean
  canInitiateCalls: boolean
  onGoBack: () => void
  onAnyRoom: (anyRoom: boolean) => void
  onAcceptDirectCall: (directCall: DirectCall) => void
  onRejectDirectCall: (directCall: DirectCall) => void
}

interface PttKeyData {
  code: number
}

interface RoomRemoteMuteNotification {
  roomLabel: string
  muter: string
}

const RevertedToggleButton = styled(ToggleButton)<ButtonProps>(({ theme }) => ({
  '&': {
    color: '#ffffff',
    border: 'solid 2px #ffffff',
  },
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: theme.palette.success.dark,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.warning.dark,
  },
}))

const textEncoder = new TextEncoder()
const textDecoder = new TextDecoder()

const MUTE_ALL_CUSTOM_EVENT = 'MUTE_ALL'

export const RemoteConnectionPage = ({
  simulationKey,
  simulationExecutionStatus,
  simulationRecordingStatus,
  remoteConnection,
  simulationPosition,
  connectAsAdmin,
  canInitiateCalls,
  incomingDirectCall,
  directCallsDestinations,
  latestTerminatedDirectCallRoomName,
  onGoBack,
  onAnyRoom,
  onAcceptDirectCall,
  onRejectDirectCall,
}: Props) => {
  const { t } = useTranslation()

  const [savedInputDevice] = useLocalStorage('escape-modernization.input-device', '')
  const [savedPttKey] = useLocalStorage('escape-modernization.ptt-key', 'space')
  const [savedCustomPttKeyData, setSavedCustomPttKeyData] = useLocalStorage<PttKeyData | undefined>(
    'escape-modernization.custom-ptt-key-data',
    undefined,
  )

  const ringtoneAudioRef = useRef<HTMLAudioElement>(null)
  const busyCalleeAudioRef = useRef<HTMLAudioElement>(null)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [overlayPinned, setOverlayPinned] = useState(false)
  const [mutedRooms, setMutedRooms] = useState<string[]>([])
  const [lastRemoteMuteNotification, setLastRemoteMuteNotification] = useState<RoomRemoteMuteNotification>()
  const [volumes, setVolumes] = useState<number[]>(() => {
    return [100, 100, ...simulationPosition.intercoms.map(() => 100), ...simulationPosition.frequencies.map(() => 100)]
  })
  const [activeRemoteSpeakers, setActiveRemoteSpeakers] = useState<Participant[][]>(() => {
    return [[], ...simulationPosition.intercoms.map(() => []), ...simulationPosition.frequencies.map(() => [])]
  })
  const [audioEnabled, setAudioEnabled] = useState(false)
  const [pttOn, setPttOn] = useState(false)

  const [lastAcknowledgedSimulationStatus, setLastAcknowledgedSimulationStatus] = useState(simulationExecutionStatus)

  const [remoteConnectionPublicRooms, setRemoteConnectionPublicRooms] = useState<RemoteConnectionPublicRooms>()
  const [transmittingIntercomRoom, setTransmittingIntercomRoom] = useState<string>('')
  const [transmittingFrequencyRoom, setTransmittingFrequencyRoom] = useState<string>('')

  const [selectedDirectCallDestination, setSelectedDirectCallDestination] = useState<DirectCallDestination>()
  const [directCall, setDirectCall] = useState<DirectCall>()
  const [directCallRoom, setDirectCallRoom] = useState<Room>()
  const [directCallInterlocutorJoined, setDirectCallInterlocutorJoined] = useState<boolean>(false)
  const [directCallErrorSnackbarOpen, setDirectCallErrorSnackbarOpen] = useState(false)
  const [connectingToDirectCall, setConnectingToDirectCall] = useState(false)

  const [roomsReloadNeeded, setRoomsReloadNeeded] = useState(false)

  const [statusChangeSnackbarOpen, setStatusChangeSnackbarOpen] = useState(false)

  const [remoteWidth, setRemoteWidth] = useState(remoteConnection.guacamoleClient.getDisplay().getWidth())
  const [remoteHeight, setRemoteHeight] = useState(remoteConnection.guacamoleClient.getDisplay().getHeight())
  const { width, height } = useWindowSize()

  const guacamoleClientContainerRef = useRef<HTMLDivElement | null>(null)
  const initialPublicRoomsLoadingRef = useRef(false)
  const pttKeyActiveRef = useRef(-1)
  const pttKeyRef = useRef('space')
  const customPttKeyDataRef = useRef<PttKeyData | undefined>(undefined)

  const intercomRooms = useMemo(
    () =>
      !remoteConnectionPublicRooms
        ? []
        : [remoteConnectionPublicRooms.supervisionRoom, ...remoteConnectionPublicRooms.intercomRooms],
    [remoteConnectionPublicRooms],
  )
  const frequenciesRooms = useMemo(
    () => (!remoteConnectionPublicRooms ? [] : remoteConnectionPublicRooms.frequenciesRooms),
    [remoteConnectionPublicRooms],
  )

  // Initializeation of PTT key data
  useEffect(() => {
    pttKeyRef.current = savedPttKey || 'space'
  }, [savedPttKey])

  useEffect(() => {
    if (!savedCustomPttKeyData) return

    customPttKeyDataRef.current = savedCustomPttKeyData
  }, [savedCustomPttKeyData])

  // Cleanups
  useEffect(() => {
    if (!remoteConnectionPublicRooms) return

    return () => {
      remoteConnectionPublicRooms.supervisionRoom.livekitRoom.disconnect().then().catch(console.error)
      remoteConnectionPublicRooms.frequenciesRooms.forEach((it) =>
        it.livekitRoom.disconnect().then().catch(console.error),
      )
      remoteConnectionPublicRooms.intercomRooms.forEach((it) => it.livekitRoom.disconnect().then().catch(console.error))
    }
  }, [remoteConnectionPublicRooms])

  useEffect(() => {
    if (!directCallRoom) return

    return () => {
      directCallRoom.livekitRoom.disconnect().then().catch(console.error)
    }
  }, [directCallRoom])

  // Any room notification
  useEffect(() => {
    onAnyRoom(Boolean(directCallRoom) || Boolean(remoteConnectionPublicRooms))
  }, [directCallRoom, remoteConnectionPublicRooms])

  // Setting direct call
  useEffect(() => {
    if (incomingDirectCall && ringtoneAudioRef.current) {
      ringtoneAudioRef.current!.src = `${process.env.PUBLIC_URL}/ringtone.mp3` // eslint-disable-line @typescript-eslint/no-non-null-assertion
      ringtoneAudioRef.current!.load() // eslint-disable-line @typescript-eslint/no-non-null-assertion
      ringtoneAudioRef.current!.play().then().catch(console.error)
    }

    setConnectingToDirectCall(false)
    setDirectCall(incomingDirectCall)
  }, [incomingDirectCall])

  // Terminating remotely terminated direct call
  useEffect(() => {
    if (!directCall) return
    if (!directCall.roomName) return
    if (!latestTerminatedDirectCallRoomName) return
    if (directCall.roomName !== latestTerminatedDirectCallRoomName) return

    ringtoneAudioRef.current?.pause()

    busyCalleeAudioRef.current!.src = `${process.env.PUBLIC_URL}/busytone.mp3` // eslint-disable-line @typescript-eslint/no-non-null-assertion
    busyCalleeAudioRef.current!.load() // eslint-disable-line @typescript-eslint/no-non-null-assertion
    busyCalleeAudioRef.current!.play().then().catch(console.error)

    setDirectCall(undefined)
  }, [latestTerminatedDirectCallRoomName, directCallRoom, directCall])

  // If no more direct call ongoing, then clean up
  useEffect(() => {
    if (directCall) return

    setDirectCallInterlocutorJoined(false)
    setDirectCallRoom(undefined)
  }, [directCall])

  // If we join a direct call room as a guest and there is no participant then we leave
  useEffect(() => {
    if (!directCall) return
    if (!directCallRoom) return
    if (directCall.initiator) return
    if (directCallRoom.livekitRoom.participants.size !== 0) return

    setDirectCall(undefined)
  }, [directCallRoom, directCall])

  // If there is an active direct call room then we handle the livekit events
  useEffect(() => {
    if (!directCallRoom) return

    const handleRemoteDisconnection = () => {
      if (directCallRoom.livekitRoom.participants.size === 0) {
        setDirectCall(undefined)
      }
    }
    const handleRemoteConnection = () => {
      ringtoneAudioRef.current?.pause()

      setDirectCallInterlocutorJoined(true)
    }

    directCallRoom.livekitRoom.on(RoomEvent.ParticipantDisconnected, handleRemoteDisconnection)
    directCallRoom.livekitRoom.on(RoomEvent.TrackPublished, handleRemoteConnection)

    return () => {
      directCallRoom.livekitRoom.off(RoomEvent.ParticipantDisconnected, handleRemoteDisconnection)
      directCallRoom.livekitRoom.off(RoomEvent.TrackPublished, handleRemoteConnection)
    }
  }, [directCallRoom])

  // Setting microphone on public rooms
  useEffect(() => {
    if (!savedInputDevice) return

    Promise.all(
      [...intercomRooms, ...frequenciesRooms].map((it) =>
        it.livekitRoom.switchActiveDevice('audioinput', savedInputDevice),
      ),
    )
      .then(() => {})
      .catch((e) => {
        console.error('Could not enable audio', e)
      })
  }, [intercomRooms, frequenciesRooms, savedInputDevice])

  // Setting microphone on direct call rooms
  useEffect(() => {
    if (!savedInputDevice) return
    if (!directCallRoom) return

    directCallRoom.livekitRoom
      .switchActiveDevice('audioinput', savedInputDevice)
      .then(() => {})
      .catch((e) => {
        console.error('Could not enable audio', e)
      })
  }, [directCallRoom, savedInputDevice])

  // Connecting to audio rooms (supervision, frequencies and intercoms)
  // We use a ref to not connect twice to livekit in dev/strict mode at startup
  useEffect(() => {
    if (simulationExecutionStatus !== 'running') return
    if (initialPublicRoomsLoadingRef.current) return

    initialPublicRoomsLoadingRef.current = true

    getRemoteConnectionPublicRooms(simulationKey, simulationPosition)
      .then(setRemoteConnectionPublicRooms)
      .catch(console.error)
      .finally(() => {
        initialPublicRoomsLoadingRef.current = false
      })
  }, [simulationExecutionStatus, simulationPosition, simulationKey, initialPublicRoomsLoadingRef])

  // Reconnecting to recordable rooms once the recording starts
  useEffect(() => {
    setRoomsReloadNeeded(simulationRecordingStatus === 'recording')
  }, [simulationRecordingStatus])

  useEffect(() => {
    if (!roomsReloadNeeded) return
    if (!remoteConnectionPublicRooms) return

    getRemoteConnectionPublicRooms(simulationKey, simulationPosition)
      .then(setRemoteConnectionPublicRooms)
      .catch(console.error)
      .finally(() => {
        setRoomsReloadNeeded(false)
      })
  }, [roomsReloadNeeded, simulationPosition, simulationKey, remoteConnectionPublicRooms])

  // Displaying warning audio message if status not running
  useEffect(() => {
    if (simulationExecutionStatus === lastAcknowledgedSimulationStatus) return

    setStatusChangeSnackbarOpen(lastAcknowledgedSimulationStatus === 'running')
    setLastAcknowledgedSimulationStatus(simulationExecutionStatus)
  }, [simulationExecutionStatus, lastAcknowledgedSimulationStatus])

  // Disconnecting from audio rooms if status changes
  useEffect(() => {
    if (simulationExecutionStatus === 'running') return
    if (!remoteConnectionPublicRooms) return

    setRemoteConnectionPublicRooms(undefined)
  }, [simulationExecutionStatus, remoteConnectionPublicRooms])

  // Disconnecting from direct call if status changes
  useEffect(() => {
    if (simulationExecutionStatus === 'running') return
    if (!directCall) return

    setDirectCall(undefined)
  }, [simulationExecutionStatus, directCall])

  // Handling active remote speakers
  useEffect(() => {
    ;[...intercomRooms, ...frequenciesRooms].forEach((it, index) => {
      it.livekitRoom.on(RoomEvent.ActiveSpeakersChanged, (speakers: Participant[]) => {
        setActiveRemoteSpeakers((prevState) => {
          const newState = [...prevState]
          newState[index] = speakers.filter((it) => !it.isLocal)

          return newState
        })
      })
    })

    return () => {
      ;[...intercomRooms, ...frequenciesRooms].forEach((it) => {
        it.livekitRoom.removeAllListeners(RoomEvent.ActiveSpeakersChanged)
      })
    }
  }, [intercomRooms, frequenciesRooms])

  // Handling mute all notification
  useEffect(() => {
    intercomRooms.forEach((it, index) => {
      it.livekitRoom.on(RoomEvent.DataReceived, (payload, participant, kind, topic) => {
        const data = textDecoder.decode(payload)
        const event = JSON.parse(data)

        if (event.type === MUTE_ALL_CUSTOM_EVENT) {
          setLastRemoteMuteNotification({
            roomLabel: it.label,
            muter: participant?.name || '???',
          })
          setMutedRooms((prevState) => {
            if (prevState.indexOf(it.name) !== -1) return prevState

            return [it.name, ...prevState]
          })
        }
      })
    })

    return () => {
      intercomRooms.forEach((it) => {
        it.livekitRoom.removeAllListeners(RoomEvent.DataReceived)
      })
    }
  }, [intercomRooms])

  useEffect(() => {
    guacamoleClientContainerRef.current?.appendChild(remoteConnection.guacamoleClient.getDisplay().getElement())

    const keyboard: Keyboard = new window.Guacamole.Keyboard(window.document)
    keyboard.onkeydown = (keysym: number) => {
      console.info('Detected keysim keydown event', keysym)

      if (pttKeyActiveRef.current === -1) {
        if (pttKeyRef.current === 'ctrl' && (keysym === 65507 || keysym === 65508)) {
          // 65507 is left ctrl key and 65508 is right ctrl key
          pttKeyActiveRef.current = keysym
          setPttOn(true)
        } else if (pttKeyRef.current === 'alt' && (keysym === 65513 || keysym === 65027)) {
          // 65513 is alt key and 65027 is alt graph key
          pttKeyActiveRef.current = keysym
          setPttOn(true)
        } else if (pttKeyRef.current === 'space' && keysym === 32) {
          pttKeyActiveRef.current = keysym
          setPttOn(true)
        } else if (pttKeyRef.current === 'custom' && keysym === (customPttKeyDataRef.current?.code || 32)) {
          pttKeyActiveRef.current = keysym
          setPttOn(true)
        }
      }

      remoteConnection.guacamoleClient.sendKeyEvent(1, keysym)
    }

    keyboard.onkeyup = (keysym: number) => {
      console.info('Detected keysim keyup event', keysym)

      if (keysym === pttKeyActiveRef.current) {
        pttKeyActiveRef.current = -1
        setPttOn(false)
      }
      remoteConnection.guacamoleClient.sendKeyEvent(0, keysym)
    }

    return () => {
      keyboard.onkeydown = null
      keyboard.onkeyup = null
    }
  }, [guacamoleClientContainerRef, remoteConnection.guacamoleClient])

  useEffect(() => {
    remoteConnection.guacamoleClient.getDisplay().onresize = (width, height) => {
      setRemoteWidth(width)
      setRemoteHeight(height)
    }
    if (!simulationPosition.connection || simulationPosition.connection.remoteControl) {
      remoteConnection.guacamoleClient.getDisplay().getElement().style.cursor = 'none'
    }

    return () => {
      remoteConnection.guacamoleClient.getDisplay().onresize = null
    }
  }, [remoteConnection.guacamoleClient, simulationPosition.connection])

  useEffect(() => {
    if (!width || !height || !remoteWidth || !remoteHeight) return

    let scale = 1
    let offsetX = 0
    let offsetY = 0
    // Case where we must scale down (remote screen bigger)
    if (remoteHeight > height || remoteWidth > width) {
      const heightRatio = remoteHeight / height
      const widthRatio = remoteWidth / width
      const maxRatio = Math.max(heightRatio, widthRatio)

      scale = 1 / maxRatio

      if (height > width) {
        offsetY = Math.abs(height - remoteHeight * scale) / 2
      } else if (width > height) {
        offsetX = Math.abs(width - remoteWidth * scale) / 2
      }
    }
    // Case where we must scale up (remote screen smaller)
    else if (remoteHeight < height || remoteWidth < width) {
      const heightRatio = height / remoteHeight
      const widthRatio = width / remoteWidth
      const minRatio = Math.min(heightRatio, widthRatio)

      scale = minRatio

      if (height > width) {
        offsetY = Math.abs(height - remoteHeight * scale) / 2
      } else if (width > height) {
        offsetX = Math.abs(width - remoteWidth * scale) / 2
      }
    }

    remoteConnection.guacamoleClient.sendSize(width, height)
    remoteConnection.guacamoleClient.getDisplay().scale(scale)

    const mouse: Mouse = new window.Guacamole.Mouse(remoteConnection.guacamoleClient.getDisplay().getElement())
    const handleMouseMove = (mouseState: Mouse.State) => {
      if (mouseState.y > 10) {
        setOverlayOpen(false)
      }

      mouseState.fromClientPosition(
        remoteConnection.guacamoleClient.getDisplay().getElement(),
        mouseState.x * (1 / scale) + offsetX,
        mouseState.y * (1 / scale) + offsetY,
      )
      remoteConnection.guacamoleClient.sendMouseState(mouseState)
    }
    const handleMouse = (mouseState: Mouse.State) => {
      remoteConnection.guacamoleClient.sendMouseState(mouseState)
    }
    const handleMouseLeave = () => {
      setOverlayOpen(true)
    }

    mouse.onmousedown = handleMouse
    mouse.onmouseup = handleMouse
    mouse.onmousemove = handleMouseMove
    mouse.onmouseout = handleMouseLeave

    return () => {
      mouse.onmousedown = null
      mouse.onmouseup = null
      mouse.onmousemove = null
      mouse.onmouseout = null
    }
  }, [width, height, remoteWidth, remoteHeight, remoteConnection.guacamoleClient])

  // Checking audio state
  useEffect(() => {
    if (!remoteConnectionPublicRooms) {
      setAudioEnabled(false)

      return
    }

    const allRooms = [...intercomRooms, ...frequenciesRooms]
    const anyRoomWithoutAudio = allRooms.filter(
      (it) => (it.livekitRoom.options.expWebAudioMix as WebAudioSettings).audioContext.state !== 'running',
    )

    setAudioEnabled(anyRoomWithoutAudio.length === 0)
  }, [remoteConnectionPublicRooms, intercomRooms, frequenciesRooms])

  // Enabling/disabling microphones
  useEffect(() => {
    directCallRoom?.livekitRoom?.localParticipant?.setMicrophoneEnabled(!pttOn || !transmittingFrequencyRoom)

    intercomRooms.forEach((it) => {
      it.livekitRoom?.localParticipant
        .setMicrophoneEnabled(
          (!pttOn || !transmittingFrequencyRoom) &&
            !directCallRoom &&
            transmittingIntercomRoom === it.name &&
            mutedRooms.indexOf(it.name) === -1,
        )
        .then()
        .catch((e) => {
          console.error(e)
        })
    })

    frequenciesRooms.forEach((it) => {
      it.livekitRoom?.localParticipant
        .setMicrophoneEnabled(pttOn && transmittingFrequencyRoom === it.name && mutedRooms.indexOf(it.name) === -1)
        .then()
        .catch((e) => {
          console.error(e)
        })
    })
  }, [
    pttOn,
    intercomRooms,
    frequenciesRooms,
    transmittingFrequencyRoom,
    transmittingIntercomRoom,
    mutedRooms,
    directCallRoom,
  ])

  // Setting initial RX/TX Modes
  useEffect(() => {
    if (!remoteConnectionPublicRooms) return

    const firstTransmittingIntercom = simulationPosition.intercoms.find((it) => it.defaultConnectionType === 'TX')
    if (firstTransmittingIntercom) {
      const firstTransmittingIntercomRoom = remoteConnectionPublicRooms.intercomRooms.find(
        (it) => it.label === firstTransmittingIntercom.name,
      )
      setTransmittingIntercomRoom(firstTransmittingIntercomRoom?.name || '')
    }
    const firstTransmittingFrequency = simulationPosition.frequencies.find((it) => it.defaultConnectionType === 'TX')
    if (firstTransmittingFrequency) {
      const firstTransmittingFrequencyRoom = remoteConnectionPublicRooms.frequenciesRooms.find(
        (it) => it.label === firstTransmittingFrequency.name,
      )
      setTransmittingFrequencyRoom(firstTransmittingFrequencyRoom?.name || '')
    }
  }, [connectAsAdmin, remoteConnectionPublicRooms, simulationPosition])

  const handleDisconnection = () => {
    setDirectCallRoom(undefined)
    setRemoteConnectionPublicRooms(undefined)

    onGoBack()
  }

  const handleOverlayPinToggle = () => {
    setOverlayPinned(!overlayPinned)
  }

  const handleIntercomsTransmissionToggle = (newTransmission: boolean, frequencyRoom: string) => {
    // Specific case, when pressing Rx while already in Rx, we do nothing
    if (!newTransmission && frequencyRoom !== transmittingIntercomRoom) return

    setTransmittingIntercomRoom(newTransmission ? frequencyRoom : '')
  }

  const handleFrequenciesTransmissionToggle = (newTransmission: boolean, frequencyRoom: string) => {
    // Specific case, when pressing Rx while already in Rx, we do nothing
    if (!newTransmission && frequencyRoom !== transmittingFrequencyRoom) return

    setTransmittingFrequencyRoom(newTransmission ? frequencyRoom : '')
  }

  const handleEnableAudio = () => {
    if (!remoteConnectionPublicRooms) return

    const allRooms = [...intercomRooms, ...frequenciesRooms]
    const anyRoomWithoutAudio = allRooms.filter(
      (it) => (it.livekitRoom.options.expWebAudioMix as WebAudioSettings).audioContext.state !== 'running',
    )
    Promise.all(anyRoomWithoutAudio.map((it) => it.livekitRoom.startAudio()))
      .then(() => {
        setAudioEnabled(true)
      })
      .catch((e) => {
        console.error('Could not enable audio', e)
      })
  }

  const handleMuteToggle = (room: string) => {
    setMutedRooms((prevState) => {
      const newMutedRooms = prevState.filter((it) => it !== room)
      return newMutedRooms.length !== prevState.length ? newMutedRooms : [room, ...prevState]
    })
  }

  const handleMuteAllRemotes = (room: string) => {
    const livekitRoom = intercomRooms.find((it) => it.name === room)?.livekitRoom
    const event = {
      type: MUTE_ALL_CUSTOM_EVENT,
    }
    const data = JSON.stringify(event)

    livekitRoom?.localParticipant.publishData(textEncoder.encode(data), DataPacket_Kind.RELIABLE)
  }

  const handleVolumeAdjustment = (index: number, amount: number) => {
    setVolumes((prevState) => {
      const newStates = [...prevState]
      newStates[index] = amount

      return newStates
    })
  }

  const handleInitiateDirectCall = () => {
    if (!selectedDirectCallDestination) return

    busyCalleeAudioRef.current?.pause()

    ringtoneAudioRef.current!.src = `${process.env.PUBLIC_URL}/dialtone.mp3` // eslint-disable-line @typescript-eslint/no-non-null-assertion
    ringtoneAudioRef.current!.load() // eslint-disable-line @typescript-eslint/no-non-null-assertion
    ringtoneAudioRef.current!.play().then().catch(console.error)

    setDirectCall({
      caller: simulationPosition!.name,
      initiator: true,
      roomName: '',
      destination: selectedDirectCallDestination.name,
      recording: simulationRecordingStatus === 'recording',
    })

    initiateDirectCall(simulationKey, {
      caller: simulationPosition!.name,
      destinationType: selectedDirectCallDestination.type,
      destinationName: selectedDirectCallDestination.name,
    })
      .then((directCallResponse) => {
        setDirectCall({
          caller: simulationPosition!.name,
          initiator: true,
          roomName: directCallResponse!.roomName,
          destination: selectedDirectCallDestination.name,
          recording: simulationRecordingStatus === 'recording',
        })

        return connectToDirectCall(simulationKey, directCallResponse!.roomName)
      })
      .then(setDirectCallRoom)
      .catch(() => {
        setDirectCall(undefined)
        setDirectCallErrorSnackbarOpen(true)
      })
  }

  const handleAbortDirectCall = () => {
    ringtoneAudioRef.current?.pause()

    busyCalleeAudioRef.current!.src = `${process.env.PUBLIC_URL}/busytone.mp3` // eslint-disable-line @typescript-eslint/no-non-null-assertion
    busyCalleeAudioRef.current!.load() // eslint-disable-line @typescript-eslint/no-non-null-assertion
    busyCalleeAudioRef.current!.play().then().catch(console.error)

    handleTerminateDirectCall()
  }

  const handleTerminateDirectCall = () => {
    ringtoneAudioRef.current?.pause()

    if (directCall) {
      onRejectDirectCall(directCall)
    }

    setDirectCall(undefined)
    setSelectedDirectCallDestination(undefined)
  }

  const handleAcceptDirectCall = () => {
    if (!directCall) return

    ringtoneAudioRef.current?.pause()

    setConnectingToDirectCall(true)
    onAcceptDirectCall(directCall)
    connectToDirectCall(simulationKey, directCall.roomName)
      .then((room) => {
        return new Promise<void>((resolve, reject) => {
          const handleLocalTrackPublication = () => {
            setDirectCallRoom(room)

            ringtoneAudioRef.current?.pause()

            if (room.livekitRoom.participants.size !== 0) {
              setDirectCallInterlocutorJoined(true)
              resolve()
            } else {
              reject()
            }

            room.livekitRoom.localParticipant.off(RoomEvent.LocalTrackPublished, handleLocalTrackPublication)
          }

          room.livekitRoom.localParticipant.on(RoomEvent.LocalTrackPublished, handleLocalTrackPublication)
        })
      })
      .catch(() => {
        setDirectCall(undefined)
        setDirectCallErrorSnackbarOpen(true)
      })
      .finally(() => {
        setConnectingToDirectCall(false)
      })
  }

  useTimeout(handleAbortDirectCall, directCall && directCall.initiator && !directCallInterlocutorJoined ? 10000 : null)

  return (
    <>
      <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
        {(overlayOpen ||
          overlayPinned ||
          selectedDirectCallDestination ||
          (directCall && !directCall.initiator && !directCallRoom)) && (
          <AppBar position="fixed" sx={{ backgroundColor: 'rgba(1,1,1,0.34)' }}>
            <Toolbar>
              <Tooltip
                title={t(overlayPinned ? 'remoteConnection.unpinOverlayMessage' : 'remoteConnection.pinOverlayMessage')}
                sx={{ marginTop: 3, marginRight: 1, alignSelf: 'flex-start' }}
              >
                <IconButton color="inherit" onClick={handleOverlayPinToggle}>
                  <PinIcon color={overlayPinned ? 'success' : 'inherit'} />
                </IconButton>
              </Tooltip>
              <Typography
                variant="h6"
                component="div"
                sx={{ marginRight: 1, marginTop: 3, alignSelf: 'flex-start', width: 120, minWidth: 120 }}
              >
                {t('remoteConnection.connectionLabel', { name: simulationPosition.name })}
              </Typography>
              {!remoteConnectionPublicRooms && (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flex: '1 0 auto', marginTop: 3, alignSelf: 'flex-start', textAlign: 'center' }}
                >
                  {t('remoteConnection.noAudioMessage')}
                </Typography>
              )}
              {remoteConnectionPublicRooms && (
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Grid2 container spacing={5} margin={0} flex="1 1 auto">
                    {intercomRooms.map((it, index) => (
                      <Grid2 key={it.name} xs={6} md={4} xl={3}>
                        <RoomContext.Provider value={it.livekitRoom}>
                          <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <RoomConnectionIndicator />
                              {(!pttOn || !transmittingFrequencyRoom) &&
                                !directCallRoom &&
                                transmittingIntercomRoom === it.name &&
                                mutedRooms.indexOf(it.name) === -1 && (
                                  <IconButton color="inherit" onClick={() => handleMuteToggle(it.name)}>
                                    <MicOnIcon fontSize="small" color="success" />
                                  </IconButton>
                                )}
                              {(!pttOn || !transmittingFrequencyRoom) &&
                                !directCallRoom &&
                                transmittingIntercomRoom === it.name &&
                                mutedRooms.indexOf(it.name) !== -1 && (
                                  <IconButton color="inherit" onClick={() => handleMuteToggle(it.name)}>
                                    <MicOffIcon fontSize="small" color="error" />
                                  </IconButton>
                                )}
                              {(transmittingIntercomRoom !== it.name ||
                                (pttOn && transmittingFrequencyRoom) ||
                                directCallRoom) && <MicOffIcon sx={{ margin: 1 }} fontSize="small" />}
                              <RemoteSpeakersIcon
                                sx={{ marginX: 1 }}
                                fontSize="small"
                                color={activeRemoteSpeakers[index].length ? 'success' : 'inherit'}
                              />
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                  marginLeft: 1,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {it.label}
                              </Typography>
                              {simulationRecordingStatus === 'recording' && it.recordable && (
                                <Tooltip title={t('simulations.recordingTooltip')}>
                                  <RecordingIcon
                                    style={{
                                      width: '62px',
                                      height: '30px',
                                      marginLeft: '8px',
                                    }}
                                    className="blinking-simulation-record"
                                  />
                                </Tooltip>
                              )}
                            </Box>
                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                              {volumes[index + 1] > 0 && (
                                <IconButton color="inherit" onClick={() => handleVolumeAdjustment(index + 1, 0)}>
                                  <VolumeOnIcon />
                                </IconButton>
                              )}
                              {volumes[index + 1] === 0 && (
                                <IconButton color="inherit" onClick={() => handleVolumeAdjustment(index + 1, 100)}>
                                  <VolumeOffIcon />
                                </IconButton>
                              )}
                              <Slider
                                aria-label="Volume"
                                value={volumes[index + 1]}
                                sx={{ color: 'inherit' }}
                                size="small"
                                onChange={(e, value) => handleVolumeAdjustment(index + 1, value as any)}
                              />
                            </Stack>
                            <Box sx={{ display: 'flex' }}>
                              {it.muteAllSupported && (
                                <Button color="inherit" onClick={() => handleMuteAllRemotes(it.name)}>
                                  {t('room.muteAllLabel')}
                                </Button>
                              )}
                              <Box flex="1 1 auto"></Box>
                              <RevertedToggleButton
                                value="check"
                                selected={transmittingIntercomRoom === it.name && !directCallRoom}
                                size={'small'}
                                sx={{ marginX: 1, outline: 'none', height: 36 }}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                onClick={() => handleIntercomsTransmissionToggle(true, it.name)}
                              >
                                {t('room.transmissionLabel')}
                              </RevertedToggleButton>
                              <RevertedToggleButton
                                value="check"
                                selected={transmittingIntercomRoom !== it.name || Boolean(directCallRoom)}
                                size={'small'}
                                sx={{ outline: 'none', height: 36 }}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                onClick={() => handleIntercomsTransmissionToggle(false, it.name)}
                              >
                                {t('room.receptionLabel')}
                              </RevertedToggleButton>
                            </Box>
                          </Box>
                        </RoomContext.Provider>
                      </Grid2>
                    ))}

                    {frequenciesRooms.map((it, index) => (
                      <Grid2 key={it.name} xs={6} md={4} xl={3}>
                        <RoomContext.Provider value={it.livekitRoom}>
                          <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <RoomConnectionIndicator />

                              {mutedRooms.indexOf(it.name) !== -1 && (
                                <IconButton color="inherit" onClick={() => handleMuteToggle(it.name)}>
                                  <MicOffIcon fontSize="small" color="error" />
                                </IconButton>
                              )}
                              {pttOn && transmittingFrequencyRoom === it.name && mutedRooms.indexOf(it.name) === -1 && (
                                <MicOnIcon fontSize="small" sx={{ margin: 1 }} color="success" />
                              )}
                              {(!pttOn || transmittingFrequencyRoom !== it.name) &&
                                mutedRooms.indexOf(it.name) === -1 && (
                                  <MicOffIcon fontSize="small" sx={{ margin: 1 }} />
                                )}
                              <RemoteSpeakersIcon
                                sx={{ marginX: 1 }}
                                fontSize="small"
                                color={
                                  activeRemoteSpeakers[index + intercomRooms.length].length ? 'success' : 'inherit'
                                }
                              />
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                  marginLeft: 1,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {it.label}
                              </Typography>
                              {simulationRecordingStatus === 'recording' && it.recordable && (
                                <Tooltip title={t('simulations.recordingTooltip')}>
                                  <RecordingIcon
                                    style={{
                                      width: '62px',
                                      height: '30px',
                                      marginLeft: '8px',
                                    }}
                                    className="blinking-simulation-record"
                                  />
                                </Tooltip>
                              )}
                            </Box>
                            <Stack spacing={2} direction="row" sx={{ mb: 1, paddingRight: '4px' }} alignItems="center">
                              {volumes[index + intercomRooms.length + 1] > 0 && (
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleVolumeAdjustment(index + intercomRooms.length + 1, 0)}
                                >
                                  <VolumeOnIcon />
                                </IconButton>
                              )}
                              {volumes[index + intercomRooms.length + 1] === 0 && (
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleVolumeAdjustment(index + intercomRooms.length + 1, 100)}
                                >
                                  <VolumeOffIcon />
                                </IconButton>
                              )}
                              <Slider
                                aria-label="Volume"
                                value={volumes[index + intercomRooms.length + 1]}
                                sx={{ color: 'inherit' }}
                                size="small"
                                onChange={(e, value) =>
                                  handleVolumeAdjustment(index + intercomRooms.length + 1, value as any)
                                }
                              />
                            </Stack>
                            <Box sx={{ display: 'flex' }}>
                              <Box flex="1 1 auto"></Box>
                              <RevertedToggleButton
                                value="check"
                                selected={transmittingFrequencyRoom === it.name}
                                size={'small'}
                                sx={{ marginX: 1, outline: 'none', height: 36 }}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                onClick={() => handleFrequenciesTransmissionToggle(true, it.name)}
                              >
                                {t('room.transmissionLabel')}
                              </RevertedToggleButton>
                              <RevertedToggleButton
                                value="check"
                                selected={transmittingFrequencyRoom !== it.name}
                                size={'small'}
                                sx={{ outline: 'none', height: 36 }}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                onClick={() => handleFrequenciesTransmissionToggle(false, it.name)}
                              >
                                {t('room.receptionLabel')}
                              </RevertedToggleButton>
                            </Box>
                          </Box>
                        </RoomContext.Provider>
                      </Grid2>
                    ))}
                  </Grid2>
                </Box>
              )}
              <Tooltip title={t('remoteConnection.disconnect')} sx={{ marginTop: 3, alignSelf: 'flex-start' }}>
                <IconButton color="inherit" onClick={handleDisconnection}>
                  <QuitIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
            {remoteConnectionPublicRooms && !audioEnabled && (
              <Alert
                severity="warning"
                sx={{ width: 400, alignSelf: 'center', marginY: 2 }}
                action={
                  <Button color="success" size="small" onClick={handleEnableAudio}>
                    {t('room.enableAudio')}
                  </Button>
                }
              >
                {t('room.audioBlockedMessage')}
              </Alert>
            )}

            {(directCall ||
              (canInitiateCalls && simulationExecutionStatus === 'running' && directCallsDestinations.length > 0)) && (
              <>
                <Divider sx={{ borderColor: 'rgba(230, 230, 230, 0.70)', marginY: 1 }} />
                <RemoteConnectionDirectCallBar
                  destinations={directCallsDestinations}
                  selectedDestination={selectedDirectCallDestination}
                  ongoingCall={directCall}
                  onTerminateCall={handleTerminateDirectCall}
                  onAcceptCall={handleAcceptDirectCall}
                  onInitiateCall={handleInitiateDirectCall}
                  onSelectDestination={setSelectedDirectCallDestination}
                  callActive={directCallInterlocutorJoined}
                  connecting={connectingToDirectCall}
                />
              </>
            )}
          </AppBar>
        )}
        <Box
          ref={guacamoleClientContainerRef}
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#101010',
          }}
        ></Box>
      </Box>
      {intercomRooms.map((it, index) => (
        <RoomContext.Provider key={it.name} value={it.livekitRoom}>
          <StereoRoomAudioRenderer channel={directCallRoom ? 'LEFT' : 'DUAL'} volume={volumes[index + 1]} />
        </RoomContext.Provider>
      ))}
      {frequenciesRooms.map((it, index) => (
        <RoomContext.Provider key={it.name} value={it.livekitRoom}>
          <StereoRoomAudioRenderer
            channel={directCallRoom ? 'LEFT' : 'DUAL'}
            volume={volumes[index + intercomRooms.length + 1]}
          />
        </RoomContext.Provider>
      ))}
      {directCallRoom && (
        <RoomContext.Provider value={directCallRoom.livekitRoom}>
          <StereoRoomAudioRenderer channel={'RIGHT'} volume={volumes[0]} />
        </RoomContext.Provider>
      )}
      <Snackbar
        open={lastRemoteMuteNotification !== undefined}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setLastRemoteMuteNotification(undefined)
        }}
      >
        <Alert
          severity="info"
          sx={{ width: 400 }}
          onClose={() => {
            setLastRemoteMuteNotification(undefined)
          }}
        >
          {t('room.mutedInRoomMessage', {
            muter: lastRemoteMuteNotification?.muter,
            room: lastRemoteMuteNotification?.roomLabel,
          })}
        </Alert>
      </Snackbar>
      <Snackbar
        open={directCallErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setDirectCallErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="error"
          sx={{ width: 400 }}
          onClose={() => {
            setDirectCallErrorSnackbarOpen(false)
          }}
        >
          {t('common.unexpectedError')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={statusChangeSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setStatusChangeSnackbarOpen(false)
        }}
      >
        <Alert
          severity="warning"
          sx={{ width: 400 }}
          onClose={() => {
            setStatusChangeSnackbarOpen(false)
          }}
        >
          {t('remoteConnection.positionStatusMessage')}
        </Alert>
      </Snackbar>
      <audio ref={ringtoneAudioRef} loop />
      <audio ref={busyCalleeAudioRef} />
    </>
  )
}
