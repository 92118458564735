import React, { PropsWithChildren } from 'react'
import { MainContainer } from './layout-components'
import { AppBar } from './AppBar'
import { Box } from '@mui/material'
import { SideBar } from './SideBar'

export const AppStructure = ({ children, fullscreen = false }: PropsWithChildren & { fullscreen?: boolean }) => {
  return (
    <>
      <AppBar />
      <MainContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0 auto',
            justifyContent: 'center',
            pt: fullscreen ? 0 : 2,
            height: '100%',
          }}
        >
          <SideBar />
          {children}
        </Box>
      </MainContainer>
    </>
  )
}
