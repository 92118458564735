import { Simulation } from './Simulation'

export const defaultNewSimulation: Simulation = {
  title: '',
  description: '',
  allowedGroups: ['eurocontrol', 'muac'],
  status: {
    executionStatus: 'stopped',
    recordingStatus: 'notRecording',
  },
  adhocIntercoms: [
    {
      name: 'Inter_1',
      audioRecording: true,
    },
    {
      name: 'Inter_2',
      audioRecording: true,
    },
    {
      name: 'Inter_3',
      audioRecording: true,
    },
    {
      name: 'Inter_4',
      audioRecording: true,
    },
    {
      name: 'Inter_5',
      audioRecording: true,
    },
  ],
  sectors: [
    {
      name: 'Sect1',
      audioRooms: {
        mainFrequency: {
          name: '111.111',
          audioRecording: true,
        },
        otherFrequencies: [
          {
            name: '222.222',
            audioRecording: true,
          },
        ],
      },
      positions: [
        {
          name: 's1_EC',
          additionalNames: ['Alpha'],
          allowedRoles: ['atco'],
          callableSectors: ['Sect2'],
          callablePositions: [],
          adhocIntercoms: ['Inter_1', 'Inter_2'],
          connection: {
            protocol: 'VNC',
            host: 'My-server',
            port: 5900,
            password: '********',
            remoteControl: true,
          },
        },
        {
          name: 's1_PC',
          additionalNames: ['Bravo'],
          allowedRoles: ['atco'],
          callableSectors: ['Sect2'],
          callablePositions: [],
          adhocIntercoms: ['Inter_1', 'Inter_2'],
          connection: {
            protocol: 'VNC',
            host: 'My-server',
            port: 5900,
            password: '********',
            remoteControl: true,
          },
        },
        {
          name: 's1_Pilot',
          additionalNames: ['Charlie'],
          allowedRoles: ['pilot'],
          callableSectors: [],
          callablePositions: [],
          adhocIntercoms: ['Inter_3'],
          connection: {
            protocol: 'VNC',
            host: 'My-server',
            port: 5900,
            password: '********',
            remoteControl: true,
          },
        },
      ],
    },
    {
      name: 'Sect2',
      audioRooms: {
        mainFrequency: {
          name: '333.333',
          audioRecording: true,
        },
        otherFrequencies: [],
      },
      positions: [
        {
          name: 's2_EC',
          additionalNames: ['Delta'],
          allowedRoles: ['atco'],
          callableSectors: ['Sect2'],
          callablePositions: [],
          adhocIntercoms: ['Inter_4', 'Inter_5'],
          connection: {
            protocol: 'VNC',
            host: 'My-server',
            port: 5900,
            password: '********',
            remoteControl: true,
          },
        },
        {
          name: 's2_Pilot',
          additionalNames: ['Echo1'],
          allowedRoles: ['pilot'],
          callableSectors: [],
          callablePositions: [],
          adhocIntercoms: ['Inter_3'],
          connection: {
            protocol: 'VNC',
            host: 'My-server',
            port: 5900,
            password: '********',
            remoteControl: true,
          },
        },
      ],
    },
  ],
  otherPositions: [
    {
      name: 'Supervision',
      additionalNames: ['Foxtrot'],
      allowedRoles: ['supervision'],
      callableSectors: [],
      callablePositions: [],
      adhocIntercoms: [],
      connection: {
        protocol: 'VNC',
        host: 'My-server',
        port: 5900,
        password: '********',
        remoteControl: true,
      },
    },
  ],
}
