import React from 'react'
import { Autocomplete, Box, CircularProgress, IconButton, styled, TextField, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Call as CallIcon, CallEnd as CallEndIcon } from '@mui/icons-material'
import { DirectCall } from './DirectCall'
import { DirectCallDestination } from './DirectCallDestination'
import { ReactComponent as RecordingIcon } from '../simulations/recording-icon.svg'
import '../simulations/RecordingIcon.css'

interface Props {
  destinations: DirectCallDestination[]
  selectedDestination?: DirectCallDestination
  ongoingCall?: DirectCall
  onSelectDestination: (destination?: DirectCallDestination) => void
  onInitiateCall: () => void
  onTerminateCall: () => void
  onAcceptCall: () => void
  callActive: boolean
  connecting: boolean
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#ffffff',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '& label': {
    color: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: '#ffffff',
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
})

export const RemoteConnectionDirectCallBar = ({
  destinations,
  selectedDestination,
  ongoingCall,
  onSelectDestination,
  onInitiateCall,
  onTerminateCall,
  onAcceptCall,
  callActive,
  connecting,
}: Props) => {
  const { t } = useTranslation()

  if (connecting) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          height: 72,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ color: '#ffffff', marginRight: 2 }} />
        {t('call.connectingLabel')}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: 72,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!ongoingCall && (
        <Autocomplete
          sx={{ width: 250 }}
          value={selectedDestination || null}
          onChange={(event, newValue) => {
            onSelectDestination(newValue || undefined)
          }}
          renderOption={(props, option, state) => {
            return (
              <li {...props} style={{ paddingLeft: option.type === 'POSITION' ? 32 : 8 }}>
                {option.name || t('remoteConnection.unknownDirectCallDestinationLabel')}
              </li>
            )
          }}
          renderInput={(params) => <CssTextField {...params} label={t('call.directCall')} size="small" />}
          getOptionLabel={(option) => option.name || t('remoteConnection.unknownDirectCallDestinationLabel')}
          getOptionDisabled={(option) => !option.callable}
          options={destinations}
        />
      )}
      {!ongoingCall && !selectedDestination && <Box sx={{ marginX: 1, width: 40 }} />}
      {!ongoingCall && selectedDestination && (
        <Tooltip title={t('call.callTooltip')}>
          <IconButton sx={{ marginX: 1 }} onClick={onInitiateCall}>
            <CallIcon sx={{ color: '#ffffff' }} />
          </IconButton>
        </Tooltip>
      )}
      {ongoingCall && !callActive && ongoingCall.initiator && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {t('call.callingPositionLabel', { position: ongoingCall.destination })}
        </Box>
      )}
      {ongoingCall && !callActive && !ongoingCall.initiator && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {t('call.callFromPositionLabel', { position: ongoingCall.caller })}
        </Box>
      )}
      {ongoingCall && !callActive && !ongoingCall.initiator && (
        <Tooltip title={t('call.acceptTooltip')}>
          <IconButton sx={{ marginX: 1 }} onClick={onAcceptCall} color="success">
            <CallIcon />
          </IconButton>
        </Tooltip>
      )}
      {ongoingCall && callActive && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {t('call.connectedToPositionLabel', {
            position: ongoingCall.initiator ? ongoingCall.destination : ongoingCall.caller,
          })}
        </Box>
      )}
      {ongoingCall && (
        <Tooltip title={t('call.rejectTooltip')}>
          <IconButton sx={{ marginX: 1 }} onClick={onTerminateCall} color="error">
            <CallEndIcon />
          </IconButton>
        </Tooltip>
      )}
      {ongoingCall && callActive && ongoingCall.recording && (
        <Tooltip title={t('simulations.recordingTooltip')}>
          <RecordingIcon
            style={{
              width: '62px',
              height: '30px',
              marginLeft: '8px',
            }}
            className="blinking-simulation-record"
          />
        </Tooltip>
      )}
    </Box>
  )
}
