import resources from '../resources'
import { Client, Status, Tunnel } from 'guacamole-common-js'
import { loadRoomAndConnect } from '../rooms/services'
import { getGuacdWsAccessToken, getLivekitAccessToken } from '../utils/ws-access-token'
import { RemoteConnection } from './RemoteConnection'
import { RemoteConnectionPublicRooms } from './RemoteConnectionPublicRooms'
import { Room } from '../rooms/Room'
import { DirectCallCreationRequest } from './DirectCallCreationRequest'
import { deleteResource, post } from '../utils/rest'
import { DirectCallResponse } from './DirectCallResponse'
import { SimulationPosition } from '../simulations/SimulationPosition'

export async function openRemoteConnection(
  simulationKey: string,
  position: SimulationPosition,
  disconnectionHandler: () => void,
): Promise<RemoteConnection> {
  return new Promise(async (resolve, reject) => {
    try {
      const wsAccessToken = await getGuacdWsAccessToken(simulationKey, position.name)

      // Connect to guacamole
      const devicePixelRatio = window.devicePixelRatio || 1
      const guacamoleTunnel: Tunnel = new window.Guacamole.WebSocketTunnel(
        `${resources.guacamoleUrl}/${simulationKey}/${position.name}?dpi=${Math.floor(
          devicePixelRatio * 96,
        )}&width=${Math.floor(window.innerWidth * devicePixelRatio)}&height=${Math.floor(
          window.innerHeight * devicePixelRatio,
        )}&token=${wsAccessToken.token}`,
      )
      const guacamoleClient: Client = new window.Guacamole.Client(guacamoleTunnel)

      guacamoleTunnel.onerror = (status: Status) => {
        reject(status)
      }
      guacamoleTunnel.onstatechange = (state: Tunnel.State) => {
        if (state === 2) {
          disconnectionHandler()
        }
      }
      guacamoleClient.onerror = (status: Status) => {
        disconnectionHandler()

        reject(status)
      }

      guacamoleClient.onstatechange = (state: Client.State) => {
        if (state === 3) {
          resolve({
            name: position.name,
            guacamoleClient: guacamoleClient,
          })
        } else if (state === 5) {
          disconnectionHandler()
        }
      }

      guacamoleClient.connect()
    } catch (e) {
      reject(e)
    }
  })
}

export async function getRemoteConnectionPublicRooms(
  simulationKey: string,
  position: SimulationPosition,
): Promise<RemoteConnectionPublicRooms> {
  return new Promise<RemoteConnectionPublicRooms>((resolve, reject) => {
    Promise.all([
      getLivekitAccessToken(simulationKey, 'supervision').then((wsAccessToken) =>
        loadRoomAndConnect(
          {
            name: `simulation-${simulationKey}-supervision`,
            label: 'Supervision',
            accessToken: wsAccessToken.token,
            muteAllSupported: true,
          },
          'GENERAL',
          true,
        ),
      ),
      ...position.intercoms.map((it) =>
        getLivekitAccessToken(simulationKey, it.name).then((wsAccessToken) =>
          loadRoomAndConnect(
            {
              name: `simulation-${simulationKey}-${it.name}`,
              label: it.name,
              accessToken: wsAccessToken.token,
              muteAllSupported: true,
            },
            'INTERCOM',
            true,
            false,
            it.audioRecording,
          ),
        ),
      ),
      ...position.frequencies.map((it) =>
        getLivekitAccessToken(simulationKey, it.name).then((wsAccessToken) =>
          loadRoomAndConnect(
            {
              name: `simulation-${simulationKey}-${it.name}`,
              label: it.name,
              accessToken: wsAccessToken.token,
              muteAllSupported: false,
            },
            'FREQUENCY',
            false,
            false,
            it.audioRecording,
          ),
        ),
      ),
    ]).then((results) => {
      resolve({
        supervisionRoom: results[0],
        intercomRooms: results.filter((it) => it.type === 'INTERCOM'),
        frequenciesRooms: results.filter((it) => it.type === 'FREQUENCY'),
      })
    })
  })
}

export async function initiateDirectCall(
  simulationKey: string,
  request: DirectCallCreationRequest,
): Promise<DirectCallResponse | undefined> {
  const rawRequest = {
    caller: request.caller,
    destination: {
      destination: request.destinationType,
    },
  }

  if (request.destinationType === 'SECTOR') {
    ;(rawRequest as any).destination['sectorName'] = request.destinationName
  } else {
    ;(rawRequest as any).destination['positionName'] = request.destinationName
  }
  return post<DirectCallResponse>(`simulations/${simulationKey}/calls`, rawRequest)
}

export async function connectToDirectCall(simulationKey: string, roomName: string): Promise<Room> {
  return getLivekitAccessToken(simulationKey, roomName).then((wsAccessToken) =>
    loadRoomAndConnect(
      {
        name: `simulation-${simulationKey}-direct-call`,
        label: 'Direct call',
        accessToken: wsAccessToken.token,
        muteAllSupported: false,
      },
      'DIRECT_CALL',
      false,
      true,
    ),
  )
}

export function terminateDirectCall(simulationKey: string, roomName: string): Promise<void> {
  return deleteResource(`simulations/${simulationKey}/calls/${roomName}`).then()
}
