import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlineIcon,
  Security as SecurityIcon,
  WrongLocation as WrongLocationIcon,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { CenteredContent } from './layout-components'
import { AppStructure } from './AppStructure'

export const Error403Page = () => {
  const { t } = useTranslation()

  return (
    <AppStructure>
      <CenteredContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
          <SecurityIcon fontSize="large" sx={{ marginRight: 3 }} color="error" />
          <Typography variant="h6">{t('errors.error403')}</Typography>
        </Box>
        <Link to="/">{t('app.homeButton')}</Link>
      </CenteredContent>
    </AppStructure>
  )
}

export const Error404Page = () => {
  const { t } = useTranslation()

  return (
    <AppStructure>
      <CenteredContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
          <WrongLocationIcon fontSize="large" sx={{ marginRight: 3 }} color="error" />
          <Typography variant="h6">{t('errors.error404')}</Typography>
        </Box>
        <Link to="/">{t('app.homeButton')}</Link>
      </CenteredContent>
    </AppStructure>
  )
}

interface Error500Props {
  message?: string
}

export const Error500 = ({ message }: Error500Props) => {
  const { t } = useTranslation()

  return (
    <CenteredContent>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <ErrorOutlineIcon fontSize="large" sx={{ marginRight: 3 }} color="error" />
        <Typography variant="h6">{message || t('errors.error500')}</Typography>
      </Box>
    </CenteredContent>
  )
}

export const Error500Page = () => {
  const { t } = useTranslation()

  return (
    <AppStructure>
      <CenteredContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
          <ErrorOutlineIcon fontSize="large" sx={{ marginRight: 3 }} color="error" />
          <Typography variant="h6">{t('errors.error500')}</Typography>
        </Box>
        <Link to="/">{t('app.homeButton')}</Link>
      </CenteredContent>
    </AppStructure>
  )
}

interface ErrorInfoProps {
  message: string
  warning: boolean
}

export const ErrorInfo = ({ message, warning, children }: React.PropsWithChildren<ErrorInfoProps>) => {
  return (
    <CenteredContent>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {warning && <ErrorOutlineIcon fontSize="large" sx={{ marginRight: 3 }} color="warning" />}
        {!warning && <InfoOutlineIcon fontSize="large" sx={{ marginRight: 3 }} color="info" />}
        <Typography variant="h6">{message}</Typography>
      </Box>
      {children && <Box>{children}</Box>}
    </CenteredContent>
  )
}
