import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { UserContext } from './users/UserContext'

export const SecuredRoute = () => {
  const user = useContext(UserContext)

  if (!user) return null

  return <Outlet />
}
