import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslations from '../i18n/en/messages'
import frTranslations from '../i18n/fr/messages'

const resources = {
  en: { translation: { ...enTranslations } },
  fr: { translation: { ...frTranslations } },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
