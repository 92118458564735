import React, { FormEvent, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Autocomplete, Box, Button, Chip, InputAdornment, Snackbar, styled, TextField } from '@mui/material'
import { Simulation } from './Simulation'
import Grid2 from '@mui/material/Unstable_Grid2'
import { Search as SearchIcon } from '@mui/icons-material'
import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete'
import { exportSimulationPositions, getDenormalizedForm, importSimulationPositions, validate } from './services'
import { useDebounce } from 'usehooks-ts'
import { SimulationPosition } from './SimulationPosition'
import { SimulationPositionTile } from './SimulationPositionTile'
import { blinkAnimation, blinkDurationInMilliseconds } from './blink-data'

interface Props {
  simulation: Simulation
  referenceSimulation: Simulation
  existingSimulationsTitles: string[]
  canEditSimulations: boolean
  readonly: boolean
  disabled: boolean
  backDisabled: boolean
  submitDisabled: boolean
  joinBriefingDisabled: boolean
  deleted: boolean
  outdated: boolean
  onChange: (event: FormChangeEvent) => void
  onBack?: () => void
  onCreate?: () => void
  onUpdate?: () => void
  onDelete?: () => void
  onJoinBriefing?: () => void
  onLeaveBriefing?: () => void
  onConnectToPosition?: (name: string) => void
  blinkTitle: boolean
  blinkDescription: boolean
  blinkAllowedGroups: boolean
  blinkingPositions: string[]
}

interface SimulationEditData {
  title: string
  description: string
  allowedGroups: string[]
}

function toEditData(simulation: Simulation): SimulationEditData {
  const editData: SimulationEditData = {
    title: simulation.title,
    description: simulation.description || '',
    allowedGroups: [...simulation.allowedGroups],
  }

  return editData
}

function positionMatchesSearch(simulationPosition: SimulationPosition, search: string): boolean {
  if (!search) return true

  const lowercaseSearch = search.toLocaleLowerCase()
  if (simulationPosition.name.toLocaleLowerCase().indexOf(lowercaseSearch) !== -1) return true
  if (simulationPosition.additionalNames.find((it) => it.toLocaleLowerCase().indexOf(lowercaseSearch) !== -1))
    return true
  if (simulationPosition.sector && simulationPosition.sector.toLocaleLowerCase() === lowercaseSearch) return true

  return false
}

export interface FormChangeEvent {
  simulation: Simulation
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const SimulationForm = ({
  simulation,
  referenceSimulation,
  canEditSimulations,
  existingSimulationsTitles,
  readonly,
  disabled,
  backDisabled,
  submitDisabled,
  joinBriefingDisabled,
  deleted,
  outdated,
  onChange,
  onBack,
  onCreate,
  onUpdate,
  onDelete,
  onJoinBriefing,
  onLeaveBriefing,
  onConnectToPosition,
  blinkTitle,
  blinkDescription,
  blinkAllowedGroups,
  blinkingPositions,
}: Props) => {
  const { t } = useTranslation()

  const [titleVisited, setTitleVisited] = useState(false)
  const [importErrorSnackbarOpen, setImportErrorSnackbarOpen] = useState(false)
  const [importing, setImporting] = useState(false)
  const [positionsFilter, setPositionsFilter] = useState('')
  const debouncedPositionsFilter = useDebounce(positionsFilter, 700)

  const sortedUniqueSectors = useMemo(() => {
    const sectors = simulation.sectors.map((it) => it.name)
    sectors.sort()

    return sectors
  }, [simulation.sectors])

  const allPositions = useMemo(() => {
    const simulationPositions = [
      ...simulation.sectors.flatMap((it) =>
        it.positions.map((position) => getDenormalizedForm(position, it, simulation.adhocIntercoms)),
      ),
      ...simulation.otherPositions.map((it) => getDenormalizedForm(it, undefined, simulation.adhocIntercoms)),
    ]
    const referencePositions = [
      ...referenceSimulation.sectors.flatMap((it) => it.positions),
      ...referenceSimulation.otherPositions,
    ]
    simulationPositions.forEach((it) => {
      const referencePosition = referencePositions.find((position) => position.name === it.name)
      if (referencePosition) {
        it.connectedUsers = referencePosition.connectedUsers || []
      }
    })

    return simulationPositions
  }, [simulation.sectors, simulation.otherPositions, simulation.adhocIntercoms, referenceSimulation])

  const filteredPositions = useMemo(() => {
    return allPositions.filter((it) => positionMatchesSearch(it, debouncedPositionsFilter))
  }, [allPositions, debouncedPositionsFilter])

  const invalidPositionsFound = useMemo(() => {
    return allPositions.find((it) => validate(it)) !== undefined
  }, [allPositions])

  const importFileRef = useRef<HTMLInputElement | null>(null)

  const editData = toEditData(simulation)
  const positionsWithoutSector = filteredPositions.find((it) => !it.sector) !== undefined

  const titleEmpty = editData.title.trim().length === 0
  const titleTooShort = !titleEmpty && editData.title.trim().length < 5
  const titleAlreadyExists = existingSimulationsTitles.indexOf(editData.title.trim()) !== -1
  const noPositions = simulation.otherPositions.length === 0
  const invalidAllowedGroups = editData.allowedGroups.findIndex((it) => it.length < 2 || it.length > 20) !== -1
  const duplicatedAllowedGroups = editData.allowedGroups.length !== new Set(editData.allowedGroups).size

  const formValid =
    !titleEmpty &&
    !titleTooShort &&
    !titleAlreadyExists &&
    !invalidPositionsFound &&
    !noPositions &&
    !invalidAllowedGroups &&
    !duplicatedAllowedGroups

  const handlePositionSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionsFilter(event.target.value)
  }

  const handleFormInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      simulation: {
        ...simulation,
        title: editData.title,
        description: editData.description,
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleAllowedGroupsChangeChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<string, true, true, true>,
  ) => {
    const allowedGroups: string[] = value.map((it) => it.toLocaleLowerCase())
    allowedGroups.sort()

    onChange({
      simulation: {
        ...simulation,
        allowedGroups: allowedGroups,
      },
    })
  }

  const handlePreSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!formValid) return

    setTitleVisited(true)

    onCreate && onCreate()
    onUpdate && onUpdate()
  }

  const handleExportPositions = () => {
    const element = document.createElement('a')
    const file = new Blob([JSON.stringify(exportSimulationPositions(simulation), undefined, 2)], {
      type: 'application/json',
    })
    element.href = URL.createObjectURL(file)
    element.download = `simulation-export.json`
    element.click()
  }

  const handleImportPositions = () => {
    const files = importFileRef?.current?.files
    if (!files || files.length === 0) return

    setImporting(true)

    files[0]
      .text()
      .then((text) => {
        try {
          const data = JSON.parse(text)
          if (data.constructor !== Object) {
            setImportErrorSnackbarOpen(true)
          } else {
            const simulationImportResult = importSimulationPositions(data)
            onChange({
              simulation: {
                ...simulation,
                ...simulationImportResult,
              },
            })
          }
        } catch (e) {
          console.error(e)

          setImportErrorSnackbarOpen(true)
        } finally {
          setImporting(false)
        }
      })
      .catch(() => {
        setImportErrorSnackbarOpen(true)
      })
      .finally(() => {
        importFileRef!.current!.value = ''
      })
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}
      component="form"
      onSubmit={handlePreSubmit}
      noValidate
      autoComplete="off"
    >
      <Grid2 container>
        <Grid2 xs={4} md={2} sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
          <label style={{ fontWeight: 600 }} htmlFor="simulation-title">
            {t('simulations.form.titleTitle')}
          </label>
        </Grid2>
        <Grid2 xs={8} md={6}>
          <TextField
            id="simulation-title"
            name="title"
            label=""
            variant="standard"
            placeholder={deleted ? undefined : t('simulations.form.titlePlaceholder')!}
            inputProps={{
              maxLength: 20,
              readOnly: readonly,
              sx: {
                animation: blinkTitle ? `${blinkAnimation} ${blinkDurationInMilliseconds}ms ease-out` : undefined,
              },
            }}
            value={editData.title}
            onChange={handleFormInputChange}
            onBlur={() => setTitleVisited(true)}
            aria-readonly={readonly}
            disabled={disabled}
            error={!deleted && titleVisited && (titleEmpty || titleTooShort || titleAlreadyExists)}
            helperText={
              deleted || !titleVisited || (!titleEmpty && !titleTooShort && !titleAlreadyExists)
                ? ' '
                : titleEmpty
                ? t('simulations.form.titleEmptyErrorMessage')
                : titleAlreadyExists
                ? t('simulations.form.titleAlreadyExistsErrorMessage')
                : t('simulations.form.titleTooShortErrorMessage')
            }
            fullWidth
            required
            autoFocus
          />
        </Grid2>
        <Grid2 xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {onDelete && (
            <Button
              color="error"
              onClick={onDelete}
              disabled={disabled || importing || simulation.status.executionStatus !== 'stopped'}
              sx={{ marginRight: 2 }}
              variant="outlined"
            >
              {t('common.delete')}
            </Button>
          )}
          {onBack && (
            <Button
              variant={
                (canEditSimulations && !deleted && !outdated) || onJoinBriefing || onLeaveBriefing
                  ? 'outlined'
                  : 'contained'
              }
              onClick={onBack}
              disabled={backDisabled || importing}
            >
              {t('common.back')}
            </Button>
          )}
          {!onUpdate && onCreate && (
            <Button
              variant="contained"
              type="submit"
              disabled={!formValid || disabled || importing || submitDisabled}
              sx={{ marginLeft: 2 }}
            >
              {t('common.create')}
            </Button>
          )}
          {!onCreate && onUpdate && (
            <Button
              variant={deleted || outdated ? 'outlined' : 'contained'}
              type="submit"
              disabled={!formValid || disabled || importing || submitDisabled}
              sx={{ marginLeft: 2 }}
            >
              {t('common.update')}
            </Button>
          )}
          {onJoinBriefing && !onLeaveBriefing && (
            <Button
              variant="contained"
              disabled={disabled || deleted || joinBriefingDisabled}
              onClick={onJoinBriefing}
              sx={{ marginLeft: 2 }}
            >
              {t('simulations.joinBriefing')}
            </Button>
          )}
          {onLeaveBriefing && !onJoinBriefing && (
            <Button
              color="error"
              variant="contained"
              disabled={disabled || deleted}
              onClick={onLeaveBriefing}
              sx={{ marginLeft: 2 }}
            >
              {t('simulations.leaveBriefing')}
            </Button>
          )}
        </Grid2>
      </Grid2>
      <Grid2 container marginTop={2}>
        <Grid2 xs={4} md={2} sx={{ textTransform: 'uppercase' }}>
          <label style={{ fontWeight: 600 }} htmlFor="simulation-description">
            {t('simulations.form.descriptionTitle')}
          </label>
        </Grid2>
        <Grid2 xs={8} md={10}>
          <TextField
            id="simulation-description"
            name="description"
            label=""
            variant="outlined"
            placeholder={deleted ? undefined : t('simulations.form.descriptionPlaceholder')!}
            inputProps={{ maxLength: 5000, readOnly: readonly }}
            value={editData.description}
            onChange={handleFormInputChange}
            rows={5}
            sx={{
              animation: blinkDescription ? `${blinkAnimation} ${blinkDurationInMilliseconds}ms ease-out` : undefined,
            }}
            aria-readonly={readonly}
            disabled={disabled}
            multiline
            fullWidth
          />
        </Grid2>
      </Grid2>

      {canEditSimulations && (
        <Grid2 container marginTop={2}>
          <Grid2 xs={4} md={2} sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
            <label style={{ fontWeight: 600 }} htmlFor="simulation-groups">
              {t('simulations.form.visibleToGroupsTitle')}
            </label>
          </Grid2>
          <Grid2 xs={8} md={6}>
            <Autocomplete
              id="simulation-groups"
              options={[]}
              defaultValue={[]}
              value={editData.allowedGroups}
              fullWidth
              freeSolo
              multiple
              aria-readonly={readonly}
              disabled={disabled}
              onChange={handleAllowedGroupsChangeChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={option.length < 2 || option.length > 20 ? 'error' : 'default'}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={invalidAllowedGroups || duplicatedAllowedGroups}
                  sx={{
                    animation: blinkAllowedGroups
                      ? `${blinkAnimation} ${blinkDurationInMilliseconds}ms ease-out`
                      : undefined,
                  }}
                  variant="standard"
                  label=""
                />
              )}
            />
          </Grid2>
        </Grid2>
      )}

      <Grid2 container marginTop={2}>
        <Grid2 xs={4} md={2} sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
          <label style={{ fontWeight: 600 }} htmlFor="simulation-positions">
            {t('simulations.form.positionsTitle')}
          </label>
        </Grid2>
        <Grid2 xs={8} md={6}>
          <TextField
            id="simulation-positions"
            name="positions"
            label=""
            value={positionsFilter}
            onChange={handlePositionSearchInputChange}
            disabled={deleted || simulation.otherPositions.length === 0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            fullWidth
          />
        </Grid2>
        <Grid2 xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {canEditSimulations && (
            <>
              <Button
                onClick={handleExportPositions}
                disabled={importing || simulation.otherPositions.length === 0}
                sx={{ marginLeft: 4 }}
                variant="outlined"
              >
                {t('common.export')}
              </Button>
              <Button
                component="label"
                variant={simulation.otherPositions.length === 0 && !deleted && !outdated ? 'contained' : 'outlined'}
                disabled={disabled || importing}
                sx={{ marginLeft: 2 }}
              >
                {t('common.import')}
                <VisuallyHiddenInput type="file" ref={importFileRef} onChange={handleImportPositions} />
              </Button>
            </>
          )}
        </Grid2>
      </Grid2>

      {deleted && (
        <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
          <Alert severity="error">{t('simulations.remotelyDeletedMessage')}</Alert>
        </Box>
      )}
      {!deleted && !canEditSimulations && allPositions.length === 0 && (
        <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
          <Alert severity="info">{t('simulations.form.noPositionsAsNonAdminMessage')}</Alert>
        </Box>
      )}
      {!deleted && canEditSimulations && allPositions.length === 0 && (
        <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
          <Alert severity="warning">{t('simulations.form.noPositionsAsAdminMessage')}</Alert>
        </Box>
      )}
      {!deleted && allPositions.length !== 0 && filteredPositions.length === 0 && (
        <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
          <Alert severity="info">{t('simulations.form.noPositionsAfterSearchMessage')}</Alert>
        </Box>
      )}

      <Box
        sx={{
          marginTop: 1,
          animation:
            blinkingPositions.length > 0 ? `${blinkAnimation} ${blinkDurationInMilliseconds}ms ease-out` : undefined,
        }}
      >
        {sortedUniqueSectors.map((it) => (
          <Grid2 key={it} container marginTop={2}>
            <Grid2 xs={12} md={2} sx={{ paddingLeft: 4 }}>
              <span style={{ fontWeight: 600 }}>{it}</span>
            </Grid2>
            <Grid2 xs={12} md={10} container spacing={2}>
              {filteredPositions
                .filter((position) => position.sector === it)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((position) => (
                  <Grid2 key={position.name} xs={6} md={2}>
                    <SimulationPositionTile
                      simulationPosition={position}
                      simulationExecutionStatus={simulation.status.executionStatus}
                      simulationRecordingStatus={simulation.status.recordingStatus}
                      isAdmin={canEditSimulations}
                      novalidate={!canEditSimulations}
                      onClick={onConnectToPosition ? () => onConnectToPosition(position.name) : undefined}
                    />
                  </Grid2>
                ))}
            </Grid2>
          </Grid2>
        ))}

        {positionsWithoutSector && (
          <Grid2 container marginTop={2}>
            <Grid2 xs={12} md={2} sx={{ paddingLeft: 4 }}>
              <span style={{ fontWeight: 600 }}>{t('simulations.form.otherSectorsTitle')}</span>
            </Grid2>
            <Grid2 xs={12} md={10} container spacing={2}>
              {filteredPositions
                .filter((position) => !position.sector)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((position) => (
                  <Grid2 key={position.name} xs={6} md={2}>
                    <SimulationPositionTile
                      simulationPosition={position}
                      simulationExecutionStatus={simulation.status.executionStatus}
                      simulationRecordingStatus={simulation.status.recordingStatus}
                      isAdmin={canEditSimulations}
                      novalidate={!canEditSimulations}
                      onClick={onConnectToPosition ? () => onConnectToPosition(position.name) : undefined}
                    />
                  </Grid2>
                ))}
            </Grid2>
          </Grid2>
        )}
      </Box>

      <Snackbar
        open={importErrorSnackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setImportErrorSnackbarOpen(false)
        }}
      >
        <Alert
          severity="error"
          sx={{ width: 400 }}
          onClose={() => {
            setImportErrorSnackbarOpen(false)
          }}
        >
          {t('simulations.form.importErrorMessage')}
        </Alert>
      </Snackbar>
    </Box>
  )
}
