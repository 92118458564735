import React, { useContext } from 'react'
import { UserContext } from '../users/UserContext'
import { AppStructure } from '../AppStructure'
import { Error403Page } from '../error-pages'
import { SimulationPage } from './SimulationPage'
import { useNavigate } from 'react-router-dom'
import { defaultNewSimulation } from './default-new-simulation'

export const NewSimulationRoute = () => {
  const navigate = useNavigate()

  const user = useContext(UserContext)

  const isAdmin = user?.roles?.find((it) => it === 'admin') !== undefined

  if (!isAdmin) return <Error403Page />

  return (
    <AppStructure>
      <SimulationPage
        simulation={defaultNewSimulation}
        canEditSimulations={user?.roles?.find((it) => it === 'admin') !== undefined}
        onGoBack={() => navigate('/')}
        onAnyRoom={() => {}}
        simulationDeleted={false}
      />
    </AppStructure>
  )
}
