import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../users/UserContext'
import { useTranslation } from 'react-i18next'
import { AppStructure } from '../AppStructure'
import { getSimulations, toSimulationForListing } from './services'
import { AppLoader } from '../AppLoader'
import { SimulationForListing } from './SimulationForListing'
import { Error500 } from '../error-pages'
import { SimulationsPage } from './SimulationsPage'
import { useNavigate } from 'react-router-dom'
import reactUseWebSocket, { ReadyState } from 'react-use-websocket'
import resources from '../resources'
import { WsAccessToken } from '../utils/WsAccessToken'
import { getSimulationsWsAccessToken } from '../utils/ws-access-token'
import { Alert, Button, Snackbar } from '@mui/material'

export const SimulationsRoute = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [simulations, setSimulations] = useState<SimulationForListing[]>()
  const [loadingError, setLoadingError] = useState(false)
  const [wsAccessToken, setWsAccessToken] = useState<WsAccessToken>()

  const initialSimulationsLoadingRef = useRef(false)

  const { lastJsonMessage, readyState } = reactUseWebSocket(
    `${resources.wsBaseUrl || ''}/ws-simulations/updates?token=${wsAccessToken?.token}`,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 5000,
      heartbeat: {
        message: 'PONG',
        returnMessage: 'PING',
        timeout: 60000,
        interval: 30000,
      },
    },
    Boolean(wsAccessToken),
  )

  const user = useContext(UserContext)

  useEffect(() => {
    if (!lastJsonMessage) return

    setSimulations((lastJsonMessage as any).map(toSimulationForListing))
  }, [lastJsonMessage])

  useEffect(() => {
    if (initialSimulationsLoadingRef.current) return
    if (simulations) return
    if (loading) return

    initialSimulationsLoadingRef.current = true
    setLoading(true)

    getSimulations()
      .then((simulationsForListing) => {
        setSimulations(simulationsForListing)
      })
      .catch((e) => {
        setSimulations([])
        setLoadingError(true)
      })
      .finally(() => {
        setLoading(false)
        initialSimulationsLoadingRef.current = false
      })
  }, [loading, simulations])

  useEffect(() => {
    if (!simulations) return
    if (wsAccessToken) return

    getSimulationsWsAccessToken('simulations')
      .then(setWsAccessToken)
      .catch(() => {})
  }, [wsAccessToken, simulations])

  if (loading || !simulations)
    return (
      <AppStructure>
        <AppLoader />
      </AppStructure>
    )
  if (loadingError)
    return (
      <AppStructure>
        <Error500 message={t('simulations.listingError') || ''} />
      </AppStructure>
    )

  return (
    <AppStructure>
      <SimulationsPage
        simulations={simulations}
        canCreateSimulations={user?.roles?.find((it) => it === 'admin') !== undefined}
        onNewSimulation={() => {
          navigate('/simulations/new-simulation')
        }}
      />
      <Snackbar open={readyState === ReadyState.CLOSED} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          severity="error"
          sx={{ width: 400 }}
          action={
            <Button
              color="success"
              onClick={() => {
                window.location.reload()
              }}
            >
              {t('common.refresh')}
            </Button>
          }
        >
          {t('common.wsError')}
        </Alert>
      </Snackbar>
    </AppStructure>
  )
}
