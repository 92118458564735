import { keyframes } from '@mui/material'

export const blinkDurationInMilliseconds = 5000

export const blinkAnimation = keyframes`
    0% {
      background-color: #9ecda2;
    }
    100% {
      background-color: transparent;
    }`
