import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { UserContext } from '../users/UserContext'
import { useTranslation } from 'react-i18next'
import { AppStructure } from '../AppStructure'
import { getSimulation, toSimulation } from './services'
import { AppLoader } from '../AppLoader'
import { Error404Page, Error500 } from '../error-pages'
import { Simulation } from './Simulation'
import { SimulationPage } from './SimulationPage'
import { useNavigate, useParams } from 'react-router-dom'
import { WsAccessToken } from '../utils/WsAccessToken'
import { getSimulationsWsAccessToken } from '../utils/ws-access-token'
import reactUseWebSocket, { ReadyState } from 'react-use-websocket'
import resources from '../resources'
import { Alert, Button, Snackbar } from '@mui/material'
import { AnyRoomContext } from '../rooms/AnyRoomContext'

const emptyDeletedSimulation: Simulation = {
  title: '',
  description: '',
  status: {
    executionStatus: 'stopped',
    recordingStatus: 'notRecording',
  },
  allowedGroups: [],
  sectors: [],
  adhocIntercoms: [],
  otherPositions: [],
}

export const SimulationRoute = () => {
  const user = useContext(UserContext)

  const { t } = useTranslation()

  const { key } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [notFoundError, setNotFoundError] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [simulationDeleted, setSimulationDeleted] = useState(false)
  const [simulation, setSimulation] = useState<Simulation>()
  const [wsAccessToken, setWsAccessToken] = useState<WsAccessToken>()
  const [anyRoom, setAnyRoom] = useState(false)

  const initialSimulationLoadingRef = useRef(false)

  const isAdmin = useMemo(() => user?.roles?.find((it) => it === 'admin') !== undefined, [user])

  const { lastJsonMessage, readyState, getWebSocket } = reactUseWebSocket(
    `${resources.wsBaseUrl || ''}/ws-simulations/${key}/updates?token=${wsAccessToken?.token}`,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 5000,
      heartbeat: {
        message: 'PONG',
        returnMessage: 'PING',
        timeout: 60000,
        interval: 30000,
      },
    },
    Boolean(wsAccessToken) && !simulationDeleted,
  )

  const webSocket = getWebSocket()

  useEffect(() => {
    if (!webSocket) return

    const closeEventListener = (event: any) => {
      if (event.code === 3035) {
        setSimulationDeleted(true)
      }
    }
    webSocket.addEventListener('close', closeEventListener)

    return () => {
      webSocket.removeEventListener('close', closeEventListener)
    }
  }, [webSocket])

  useEffect(() => {
    if (initialSimulationLoadingRef.current) return
    if (loadingError) return
    if (notFoundError) return
    if (!key) return
    if (simulation) return
    if (loading) return

    initialSimulationLoadingRef.current = true
    setLoading(true)

    getSimulation(key)
      .then((simulation) => {
        setSimulation(simulation)
      })
      .catch((e) => {
        if (e === 404) {
          setNotFoundError(true)
        } else {
          setLoadingError(true)
        }
      })
      .finally(() => {
        setLoading(false)
        initialSimulationLoadingRef.current = false
      })
  }, [loading, loadingError, simulation, key, notFoundError])

  useEffect(() => {
    if (!simulation) return

    getSimulationsWsAccessToken(`simulation-${key}`)
      .then(setWsAccessToken)
      .catch(() => {})
  }, [key, simulation])

  if (loadingError)
    return (
      <AppStructure>
        <Error500 message={t('simulations.loadingError') || ''} />
      </AppStructure>
    )
  if (notFoundError) return <Error404Page />
  if (loading || !simulation)
    return (
      <AppStructure>
        <AppLoader />
      </AppStructure>
    )

  return (
    <AnyRoomContext.Provider value={anyRoom}>
      <AppStructure>
        <SimulationPage
          simulation={simulation}
          canEditSimulations={isAdmin}
          onGoBack={() => navigate('/')}
          onOpenPosition={(name) =>
            window.open(`${process.env.REACT_APP_ROUTER_BASENAME}/simulations/${key}/${name}`, '_blank')
          }
          onAnyRoom={setAnyRoom}
          simulationKey={key}
          remotelyUpdatedSimulation={
            simulationDeleted
              ? emptyDeletedSimulation
              : lastJsonMessage
              ? toSimulation((lastJsonMessage as any).simulation_description)
              : undefined
          }
          lastRemoteUpdaterOtherThanCurrentUser={
            !simulationDeleted && lastJsonMessage
              ? (lastJsonMessage as any).updated_by !== user?.login
                ? (lastJsonMessage as any).updated_by
                : undefined
              : undefined
          }
          simulationDeleted={simulationDeleted}
        />
        <Snackbar
          open={readyState === ReadyState.CLOSED && !simulationDeleted}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity="error"
            sx={{ width: 400 }}
            action={
              <Button
                color="success"
                onClick={() => {
                  window.location.reload()
                }}
              >
                {t('common.refresh')}
              </Button>
            }
          >
            {t('common.wsError')}
          </Alert>
        </Snackbar>
      </AppStructure>
    </AnyRoomContext.Provider>
  )
}
