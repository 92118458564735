import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useEnsureParticipant, useRoomContext } from '@livekit/components-react'
import { LocalTrackPublication, Participant, Track, TrackPublication, WebAudioSettings } from 'livekit-client'
import { StereoChannel } from './StereoChannel'
import { useLocalStorage } from 'usehooks-ts'

export type AudioTrackProps<T extends HTMLMediaElement = HTMLMediaElement> = HTMLAttributes<T> & {
  channel: StereoChannel
  source: Track.Source
  name?: string
  participant?: Participant
  publication: TrackPublication
  onSubscriptionStatusChanged?: (subscribed: boolean) => void
  volume?: number
}

const usePublicationAudioMediaStream = (trackPublication: TrackPublication) => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)

  useEffect(() => {
    trackPublication.on('subscribed', (track) => {
      if (track.kind !== 'audio') return
      setMediaStream(track.mediaStream || null)
    })

    if (!trackPublication.track || trackPublication.track.mediaStream?.getAudioTracks().length === 0) return

    if (trackPublication instanceof LocalTrackPublication) {
      setMediaStream(new MediaStream([trackPublication.track.mediaStreamTrack]))
    } else if (trackPublication.track?.mediaStream) {
      setMediaStream(trackPublication.track.mediaStream)
    }
  }, [trackPublication])

  return mediaStream
}

export const StereoAudioTrack = ({ channel, onSubscriptionStatusChanged, volume, ...props }: AudioTrackProps) => {
  const { source, name, publication } = props
  const participant = useEnsureParticipant(props.participant)
  const mediaStream = usePublicationAudioMediaStream(publication)
  const [panUpdateTick, setPanUpdateTick] = useState(0)

  const [savedOutputDevice] = useLocalStorage('escape-modernization.output-device', '')

  const audioRef = useRef<HTMLAudioElement>(null)
  const sourceNodeRef = useRef<MediaStreamAudioSourceNode | null>(null)
  const pannerRef = useRef<StereoPannerNode | null>(null)
  const gainNodeRef = useRef<GainNode | null>(null)

  const room = useRoomContext()

  const audioContext = (room.options.expWebAudioMix as WebAudioSettings).audioContext

  const cleanupWebAudio = useCallback(() => {
    pannerRef?.current?.disconnect()
    sourceNodeRef?.current?.disconnect()
    gainNodeRef?.current?.disconnect()

    pannerRef.current = null
    sourceNodeRef.current = null
    gainNodeRef.current = null
  }, [])

  useEffect(() => {
    cleanupWebAudio()

    if (!audioRef.current || !mediaStream || mediaStream.getAudioTracks().length === 0) return

    if (savedOutputDevice && (audioContext as any).setSinkId) {
      ;(audioContext as any).setSinkId(savedOutputDevice === 'default' ? '' : savedOutputDevice)
    }
    sourceNodeRef.current = audioContext.createMediaStreamSource(mediaStream)
    pannerRef.current = audioContext.createStereoPanner()
    gainNodeRef.current = audioContext.createGain()

    sourceNodeRef.current.connect(pannerRef.current).connect(gainNodeRef.current).connect(audioContext.destination)
    audioRef.current.srcObject = mediaStream

    setPanUpdateTick((prevState) => prevState + 1)
  }, [pannerRef, publication.track, cleanupWebAudio, audioContext, publication, mediaStream, savedOutputDevice])

  useEffect(() => {
    if (!gainNodeRef.current) return

    const gainValue = (volume === 0 ? 0 : volume || 100) / 100
    gainNodeRef.current!.gain.setValueAtTime(gainValue, 0)
  }, [volume, gainNodeRef, audioContext])

  useEffect(() => {
    if (!pannerRef.current) return

    let panValue = 0
    if (channel === 'LEFT') {
      panValue = -1
    } else if (channel === 'RIGHT') {
      panValue = 1
    }
    pannerRef.current!.pan.setValueAtTime(panValue, audioContext.currentTime)
  }, [pannerRef, channel, panUpdateTick, audioContext])

  return <audio ref={audioRef} />
}
