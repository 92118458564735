import React, { useContext, useState } from 'react'
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AccountCircle as AccountIcon,
  ErrorOutline as DisconnectedIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material'
import { deleteSession as logoutFromSession } from './utils/user-session'
import { WsConnectionContext } from './utils/WsConnectionContext'
import { UserContext } from './users/UserContext'

const displayableEnv =process.env.REACT_APP_DISPLAYABLE_ENV

export const AppBar = () => {
  const { t } = useTranslation()

  const connected = useContext(WsConnectionContext)
  const user = useContext(UserContext)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>()

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(undefined)
  }

  const logout = () => {
    setAnchorEl(undefined)
    logoutFromSession()
  }

  return (
    <>
      <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Link to="/" style={{ display: 'flex' }}>
            <img
              alt="eurocontrol"
              src={`${process.env.PUBLIC_URL}/eurocontrol-logo.svg`}
              width={60}
              height={60}
              className="logo"
            />
          </Link>
          <Typography variant="h6" component="div" sx={{ marginX: 2 }}>
            {t('app.title')}
            {displayableEnv && ` [${displayableEnv}]`}
          </Typography>
          <Box sx={{ flexGrow: 1, marginLeft: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}></Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {!connected && (
              <Tooltip title={t('app.disconnectedTooltip')}>
                <DisconnectedIcon sx={{ marginX: 1 }} color="error" />
              </Tooltip>
            )}
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openMenu}
              color="inherit"
            >
              <AccountIcon />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
              {user && <ListSubheader>{user.login}</ListSubheader>}
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>{t('app.menu.logout')}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Toolbar />
    </>
  )
}
