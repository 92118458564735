import { styled } from '@mui/material'

export const CenteredContent = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const MainContainer = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: '1 1 auto',
  overflow: 'auto',
  height: 'calc(100% - 64px)',
})
